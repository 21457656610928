@import 'fonts';
@import 'icons';
@import './DesignSystem/index';
@import './Components/alert-no-content';
@import './Components/input-with-icon';
@import './Components/card-with-icon';
@import './Components/progressbar';
@import './Components/progressbar-notification';
@import './Components/input_quantity';
@import './Components/badge-button';
@import './Components/iframe';
@import './Components/editor-quill';
@import './Components/index';
@import './Components/dropdown-more-actions';
@import './Components/input-inline-edit';
@import './Components/_drag-drop-upload';
@import './Components/_tooltip-image';
@import './Components/_sort-button';
@import './Components/_input-icon';
@import './Components/_hubspot-conversation';
@import './Animations/_fade';
@import './Pages/index';
@import 'datepicker';
@import 'react-select';
@import './DesignSystemV2/aligns.scss';
@import './DesignSystemV2/texts.scss';
@import './DesignSystemV2/font-icon.scss';
@import './DesignSystemV2/utils.scss';

body {
  background: $color-ghost;
  font-size: 14px;
  font-weight: 400;
  color: $color-dark;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.icon-border-rounded {
  width: 54px;
  height: 54px;
  border-color: $color-grey-light;
  i {
    color: $color-grey;
    font-size: 26px;
  }
}

.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  z-index: 3;
}

.checkbox-spinner {
  width: 18px;
  height: 18px;
  border-width: 0.15em;
}

.hidden-characters {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.modal {
  .form-group {
    margin-bottom: 16px;
  }
  .modal-dialog {
    max-width: 700px;
  }
  .used-coupon {
    padding-top: 30px;
  }
  .btn-add {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
  }
  .process-file {
    text-align: center;
    .img-process {
      display: flex;
      max-width: 300px;
      margin: 20px auto;
    }
    .description,
    .title {
      text-align: center;
    }
  }
}

.importLabel {
  cursor: pointer;
  padding: 10px 20px;
  color: #3a3f51;
  background-color: #ffffff;
  border-color: #d0dbe9;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;

  &:hover {
    background-color: $color-ghost;
    border-color: #d0dbe9;
  }
}
.highlithMode {
  background: $color-ghost !important;
}
.pointer-events {
  pointer-events: none;
}
