@mixin text-colors() {
  &.gray-8 {
    color: $gray-8;
  }

  &.gray-16 {
    color: $gray-16;
  }

  &.gray-24 {
    color: $gray-24;
  }

  &.gray-40 {
    color: $gray-40;
  }

  &.gray-48 {
    color: $gray-48;
  }

  &.gray-64 {
    color: $gray-64;
  }

  &.gray-space {
    color: $gray-space;
  }

  &.danger,
  &.red-block {
    color: $red-block;
  }

  &.primary {
    color: $primary-pure;
  }

  &.yellowBee {
    color: $yellow-bee;
  }
}

@mixin font-weight() {
  &.extra-bold {
    font-weight: 800;
  }
  &.semi-bold {
    font-weight: 600;
  }
  &.regular {
    font-weight: 400;
  }
}

@mixin hide-text() {
  &.ellipsis-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    line-clamp: 1 !important;
    -webkit-box-orient: vertical;
  }

  &.ellipsis-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    line-clamp: 2 !important;
    -webkit-box-orient: vertical;
  }
}
