@import '../../../../../../../assets/scss/DesignSystemV2/variables';

.initial-step-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .input-wrapper {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
  }

  hr {
    margin: 0;
    background-color: $gray-16;
  }

  > section {
    padding: 1rem;
    border-radius: 0.5rem;
    gap: 0.25rem;
    display: flex;
    flex-direction: column;
    border: 1px solid $gray-8;
    background-color: $gray-4;
    transition: 0.5s;

    &.active {
      background-color: #fff;
    }

    > header {
      display: flex;
      justify-content: space-between;
    }
    > ul {
      padding: 0;
      margin: 0.25rem 0 0 0;
      list-style: none;
      background: #fff;
      border: 1px solid $gray-8;
      border-radius: 0.5rem;
      padding: 0.5rem;
      transition: 0.5s;
      transition-delay: 0.25s opacity;
      opacity: 0;
      height: 0;
      overflow: hidden;

      &.showTriggers {
        opacity: 1;
        height: 20.75rem;
      }

      > li {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding: 0.75rem 0.5rem;

        &:nth-child(even) {
          background-color: $gray-4;
        }

        .input-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 0.5rem;

          > p {
            min-width: 5rem;
          }
        }

        > p {
          flex: 1;
        }
      }
    }
  }
  .cta-button-style {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    > ul {
      list-style: none;
      padding: 1rem;
      border-radius: 1rem;
      background-color: #fff;
      border: 1px solid $gray-8;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      > li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0;
      }
    }
  }

  .preview-wrapper {
    position: relative;
    > img {
      width: 100%;
    }
    > button {
      position: absolute;
      background: transparent;
      border: 0;
      box-shadow: 0px -12px 22px 12px rgba(14, 18, 27, 0.1);

      &.top-left,
      &.top-center,
      &.top-right {
        top: 3.25rem;
      }

      &.top-left,
      &.center-left,
      &.bottom-left,
      &.top-right,
      &.center-right,
      &.bottom-right {
        padding: 1rem 0.5rem;
        writing-mode: vertical-rl;
        border-radius: 0 0.5rem 0.5rem 0;
      }

      &.top-left,
      &.center-left,
      &.bottom-left {
        writing-mode: vertical-rl;
        border-radius: 0 0.5rem 0.5rem 0;
        left: 0;
      }

      &.top-right,
      &.center-right,
      &.bottom-right {
        right: 0;
        transform: scale(-1);
      }

      &.center-left,
      &.center-center {
        top: 50%;
        transform: translateY(-20%);
      }
      &.center-right {
        transform: scale(-1) translateY(30%);
        top: 50%;
      }

      &.bottom-left,
      &.bottom-right,
      &.bottom-center {
        bottom: 0;
      }
      &.center-center {
        top: 50%;
        border-radius: 0.5rem;
      }
      &.top-center {
        border-radius: 0 0 0.5rem 0.5rem;
      }
      &.bottom-center {
        border-radius: 0.5rem 0.5rem 0 0;
      }
      &.top-center,
      &.center-center,
      &.bottom-center {
        padding: 0.5rem 1rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
