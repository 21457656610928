@import '../../../assets/scss/DesignSystemV2/_variables.scss';

.avatar-wrapper {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  line-height: 1;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #eef7ff;
  color: $primary-pure;
  font-weight: 800;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.xl {
    width: 3.75rem;
    height: 3.75rem;
  }
}
