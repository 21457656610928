.spinnerBox {
  width: min-content;
  height: min-content;
  text-align: center;
  background: transparent;
  border-radius: 0.25rem;
  padding: 1rem;
  margin: 0 auto;

  position: absolute;
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
}
