.dropdown-action,
.dropdown {
  .dropdown-menu {
    background: $color-light;
    border: solid 1px $color-highlights;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    height: auto;
    border-radius: 5px;
    padding: 16px 16px;
    width: 100%;
    min-width: 220px;

    li {
      border-bottom: solid 1px $color-highlights;
      display: flex;
      justify-content: flex-start;
      text-align: left;
      &:hover {
        background: $color-primary-light-2;
      }
      a,
      button {
        font-size: 13px;
        text-decoration: none;
        color: $color-dark;
        width: 100%;
        display: block;
        padding-top: 12px;
        padding-bottom: 12px;
        transition: all 0.5s;
        width: 100%;
        text-align: left;
        padding-left: 8px;
      }
      .active-dropdown-item {
        background: $color-primary-light-2;
      }
      i {
        min-width: 16px;
        color: $color-grey;
      }
      .btn {
        border: none !important;
        padding-left: 8px !important;
        font-size: 13px;
        i {
          margin-right: 8px !important;
        }
        &:focus {
          box-shadow: none;
        }
        &:hover {
          background: unset !important;
        }
      }
      &:last-child {
        border-bottom: none;
      }
      &.btn-delete {
        border: 1px solid $color-danger !important;
        border-radius: 3px;
        padding-left: 0;
        .btn {
          width: 100%;
          color: $color-danger;
          background: $color-light;
          text-align: left;
          padding-left: 15px;
          &:focus {
            box-shadow: none;
          }
          &:hover {
            background: $color-danger;
            color: $color-light;
          }
          &:disabled {
            padding-left: 10px;
          }
        }
        &:hover {
          background: $color-danger;
          color: $color-light;
        }
      }
    }

    &.dropdown-menu-right {
      li {
        border-bottom-width: 0px;
        a,
        button {
          padding-left: 0px;
          padding-top: 0px;
          padding-bottom: 0px;
        }
      }
    }
  }
}
