@import '../../../../../../../assets/scss/DesignSystemV2/variables';

.button-alignment {
  display: flex;
  justify-content: flex-end;
  > button {
    margin-left: 0.5rem;
  }
}

.popover-main {
  background-color: transparent;
}

.popover-header {
  padding-bottom: 0;
  span {
    font-size: 0.75rem;
    font-weight: 800;
  }
}

.popover-grid-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 0.75rem;
  p {
    margin-bottom: 0;
    font-size: 0.75rem;
    font-weight: 600;
  }
  > div:first-child {
    border-right: 1px solid rgba(243, 244, 246, 0.2);
  }
}

.qr-code-image {
  margin-right: 0.5rem;
}

.name-column {
  display: flex;
  align-items: center;
}

.invites-table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    font-size: 0.75rem;
    padding: 1rem;
    border: none;
  }

  thead {
    th {
      background-color: $gray-4;
      color: $gray-56;
      text-align: left;
      border-bottom: 1px solid $gray-8;

      &.column-created {
        width: 15%;
      }
      &.column-invite {
        width: 40%;
      }
      &.column-email {
        width: 23%;
      }
      &.column-code {
        width: 12%;
      }
      &.column-button {
        width: 10%;
      }
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: $gray-4;
    }

    tr:nth-child(odd) {
      background-color: $white;
    }
  }

  .align-right {
    text-align: right;
  }

  .align-center {
    text-align: center;
  }
}

.tab-wrapper {
  width: 100%;
  border-bottom: 1px solid $gray-16;
  display: flex;
  gap: 0;
  margin-bottom: 1rem;

  button {
    margin-bottom: -1px;
    border-bottom: 2px solid transparent;
    transition: 0.5s;
    border-radius: 0 !important;
    padding: 0.75rem 1.25rem !important;

    &:hover,
    &.active {
      border-bottom-color: $primary-pure;
      main {
        color: $gray-space;
      }
    }

    &:not(:hover):not(.active) {
      color: $gray-40 !important;
    }
  }
}
