.nav-pills {
  .nav-item {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;

    .nav-link {
      padding: 5px 10px 3px;
      margin-right: 8px;
    }

    .active,
    .blue {
      background-color: $color-primary-light-2;
      color: $color-primary;
    }

    .success {
      background-color: $color-success-light-2;
      color: $color-success;
    }

    .danger {
      background-color: $color-danger-light-2;
      color: $color-danger;
    }

    .warning {
      background-color: $color-warning-light-2;
      color: $color-warning;
    }

    .info {
      background-color: $color-info-light-2;
      color: $color-info;
    }

    .pink {
      background-color: $color-pink-light-2;
      color: $color-pink;
    }

    .colombia {
      background-color: $color-colombia-light-2;
      color: $color-colombia;
    }

    .salmon {
      background-color: $color-salmon-light-2;
      color: $color-salmon;
    }

    .grey {
      background-color: $color-grey-light;
      color: $color-grey;
    }
  }
}

.badges {
  .badge-pill {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 5px 10px 3px;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 3px;
    text-decoration: none;
  }

  .badge-pill:hover {
    text-decoration: none;
  }

  .active,
  .blue {
    background-color: $color-primary-light-2;
    color: $color-primary;
  }

  .success {
    background-color: $color-success-light-2;
    color: $color-success;
  }

  .danger {
    background-color: $color-danger-light-2;
    color: $color-danger;
  }

  .warning {
    background-color: $color-warning-light-2;
    color: $color-warning;
  }

  .info {
    background-color: $color-info-light-2;
    color: $color-info;
  }

  .pink {
    background-color: $color-pink-light-2;
    color: $color-pink;
  }

  .colombia {
    background-color: $color-colombia-light-2;
    color: $color-colombia;
  }

  .salmon {
    background-color: $color-salmon-light-2;
    color: $color-salmon;
  }

  .grey {
    background-color: $color-grey-light;
    color: $color-grey;
  }
}

.tags {
  .tag-pill {
    font-size: 14px;
    font-weight: 400;
    text-transform: normal;
    padding: 5px 10px 5px;
    margin-right: 5px;
    border-radius: 3px;
    text-decoration: none;

    @media (max-width: 576px) {
      font-size: 12px;
    }

    &.sm {
      font-size: 12px;
    }
  }

  .tag-pill:hover {
    text-decoration: none;
  }

  .primary {
    background-color: $color-primary;
    color: $color-light;
  }

  .secondary {
    background-color: $color-ghost;
    color: $color-grey-dark;
  }

  .light {
    background-color: $color-light;
    color: $color-dark;
  }
}
