@import '../../../assets/scss/DesignSystemV2/variables';
.alert {
  display: flex;
  border-radius: 0.5rem;
  padding: 0.65rem 0.85rem;
  gap: 0.5rem;
  justify-content: space-between;
  .content-wrapper {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }
  &.primary {
    background: $primary-pure;
    color: #ffffff;

    button {
      color: #fff;
    }
  }
  &.primary-light {
    border: 1px solid $primary-pure;
    background-color: $primary-8;

    button {
      color: $primary-pure;
    }

    .content-wrapper span:first-child {
      color: $primary-pure;
    }
  }
  &.yellow {
    background: $yellow-bee;
    color: #ffffff;

    button {
      color: #fff;
    }
  }
  &.yellow-light {
    border: 1px solid $yellow-bee;
    background-color: $yellow-bee-8;

    button {
      color: $yellow-bee;
    }

    .content-wrapper span:first-child {
      color: $yellow-bee;
    }
  }
  &.green {
    background: $green-status;
    color: #ffffff;

    button {
      color: #fff;
    }
  }
  &.green-light {
    border: 1px solid $green-status;
    background-color: $green-status-8;

    button {
      color: $green-status;
    }

    .content-wrapper span:first-child {
      color: $green-status;
    }
  }
  &.danger {
    background: $red-block;
    color: #ffffff;

    button {
      color: #fff;
    }
  }
  &.danger-light {
    border: 1px solid $red-block;
    background-color: $red-block-8;

    button {
      color: $red-block;
    }

    .content-wrapper span:first-child {
      color: $red-block;
    }
  }
  &.noir {
    background: $primary-noir;
    color: #ffffff;

    button {
      color: #fff;
    }
  }
  &.noir-light {
    border: 1px solid $primary-noir;
    background-color: $gray-8;

    button {
      color: $primary-noir;
    }

    .content-wrapper span:first-child {
      color: $primary-noir;
    }
  }

  button {
    border: 0;
    background: transparent;
    display: grid;
    place-content: center;
  }
}
