@import '../../../../../../assets/scss/DesignSystemV2/_variables';
.form-wrapper {
  display: grid;
  place-content: center;
  place-items: center;
  gap: 1rem;
  grid-template-columns: 1fr;
  padding: 4rem;
  height: 100%;

  > button {
    margin-top: 1rem;
  }
}
