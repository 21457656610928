@import '../../../assets/scss/DesignSystemV2/variables';
.section {
  background-color: #fff;
  border-radius: 1rem;
  overflow: hidden;

  > header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    z-index: 1;
    border-bottom: 1px solid $gray-8;
    position: relative;
    background-color: #fff;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;

    &.enabled {
      cursor: pointer;
    }

    h4 {
      color: $gray-space;
      font-weight: 800;
      margin: 0;
      display: flex;
      align-items: center;
    }
    > button {
      margin-left: auto;
    }

    .circle-status {
      width: 2.25rem;
      height: 2.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      line-height: 0;
      font-weight: 800;
      border-radius: 50%;
      &.enabled {
        background-color: $primary-8;
        color: $primary-pure;
        cursor: pointer;
      }
      &.disabled {
        background-color: $gray-8;
        color: $gray-40;
      }
      &.green {
        background-color: $green-status-8;
        color: $green-status;
      }
      &.red {
        background-color: $red-block-8;
        color: $red-block;
      }
      &.yellow {
        background-color: $yellow-bee-8;
        color: $yellow-bee;
      }
    }
  }
  > main {
    background-color: $blue-cloud-8;
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
  > div {
    height: 0;
    min-height: 0rem;
    transition: 0.25s;
    opacity: 0;
    padding: 0rem 1rem;
    background-color: $blue-cloud-8;
    &:not(.expanded) {
      pointer-events: none;
    }
    &.expanded {
      padding: 1.5rem 1rem;
      opacity: 1;
      min-height: 5.5rem;
      height: fit-content;
      margin: 0;
    }
  }

  > footer {
    margin-top: calc(-8.25rem - 1px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid $gray-8;
    transition: 0.25s margin;
    z-index: -1;
    position: relative;
    display: none;

    &.expanded {
      margin-top: 0;
      z-index: 1;
      display: flex;
    }
  }
}
