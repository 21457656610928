@import "../../../../../../../assets/scss/DesignSystemV2/variables";

.html_container{
    display: flex; 
    flex-direction: column;
    gap: 2rem;
    padding: 0.8rem;
}

.flex-space-between {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .flex-gap{
    display: flex;
    gap: 0.4rem;
  }

  .circle{
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    background-color: $white-transparent;
  }

  .rectangle {
    width: 7rem;
    height: 2.5rem;
    border-radius: 0.6rem;
    background-color: $white-transparent;
  }

  .flex-column-space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .long-rectangle {
    width: 14rem;
    height: 1.2rem;
    border-radius: 0.6rem;
    background-color: $white-transparent;
  }

  .large-rectangle {
    width: 14rem;
    height: 7rem;
    border-radius: 0.6rem;
    background-color: $white-transparent;
  }

  .flex-center-gap-small {
    display: flex;
    justify-content: center;
    gap: 0.2rem;
  }

  .full-width-container {
    background-color: $white-transparent;
    width: 100%;
    height: 55rem;
    padding: 0.6rem;
    border-radius: 0.6rem;
  }

  .custom-panel {
    background-color: $white-transparent;
    width: 100%;
    padding: 0.6rem;
    height: 24rem;
    border-radius: 0.6rem 0.6rem 0 0;
  }