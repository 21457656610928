@import '../../../assets/scss/DesignSystemV2/variables';

.interaction-bar-wrapper {
  height: 0.75rem;
  background-color: $gray-8;
  z-index: 1;
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;

  .interaction-bar {
    height: 100%;
    border-radius: 0.5rem;
    min-height: 0.5rem;

    &.freebee {
      background-image: linear-gradient(90deg, $gray-16, $gray-32);
    }
    &.essentials {
      background-image: linear-gradient(90deg, $green-ocean, #34b7b7);
    }
    &.standard {
      background-image: linear-gradient(90deg, $blue-cloud-hover, $primary-pure);
    }
    &.premium {
      background-image: linear-gradient(90deg, $yellow-bee, #ffae0b);
    }
    &.enterprise {
      background-image: linear-gradient(90deg, $purple-16, $purple-24);
    }

    &.low-level {
      background: $red-block;
    }
  }
}
