@import './../../../assets/scss/DesignSystemV2/variables';

.html_header_container{
display: flex;
justify-content: space-between;
align-items: center;
padding: 0rem 1rem;
border-radius: 0.5rem;
}

.html_url_wrapper{
width: 25rem;
height: 2.0625rem;
border-radius: 0.5rem;
display: flex;
justify-content: space-between;
padding: 0.1875rem 0.75rem 0rem 0.75rem;
background-color: $gray-4;
}

.padding-top-15{
padding-top: 0.9375rem;
}

.padding-top-2{
 padding-top: 0.125rem;
}

.dots-wrapper{
display: flex;
gap: 0.5rem;
}

.icon-wrapper{
display: flex;
align-items: center;
}

.icon{
font-size: 0.9rem;
color: $gray-40
}