.wrapper-verify-code {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  gap: 0.5rem;

  input {
    text-align: center;
    text-transform: uppercase;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
