.hubspot-conversations-widget-container {
  overflow: hidden;
  z-index: 2147483647;
  position: fixed;
  bottom: 24px;
  right: 24px;
  height: 500px;
  width: 376px;
  display: none;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
}

#hubspot-conversations-inline-parent {
  width: 100%;
  height: 100%;
}

#hubspot-conversations-inline-iframe {
  width: 100%;
  height: 100%;
  border: none;
}
