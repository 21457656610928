@import '../../../../../assets/scss/DesignSystemV2/_variables.scss';

.wrapper-advice-message {
  max-height: 0;
  overflow: hidden;
  transition: 0.5s;
  max-width: 14rem;
  font-size: 0.75rem;
  display: grid;

  &.expanded {
    max-height: 10rem;

    &.lg {
      margin-bottom: 0.75rem;
    }

    &.md {
      margin-bottom: 0.5rem;
    }

    &.sm {
      margin-bottom: 0.5rem;
    }
  }

  &.dark {
    background: $gray-space !important;
    > span {
      color: #fff;
      padding: 0.75rem 0.5rem 0.5rem;
    }
  }

  &.gray-8 {
    background-color: $gray-8;

    > span {
      color: $gray-space;
      padding: 0.75rem 0.5rem 0.5rem;
    }
  }

  footer {
    flex: 1;
    padding: 0.75rem 0.5rem 0.5rem;
    display: flex;
    justify-content: space-between;
  }
}
