@import '../../assets/scss/DesignSystemV2/_variables.scss';

.profile-wrapper {
  margin: 0;
  padding: 0;
  list-style: none;
  border-left: 1px solid $gray-8;
  border-right: 1px solid $gray-8;

  li {
    padding: 0.15rem 1rem;
    border-bottom: 1px solid $neutral-highlights;
    display: flex;

    > a {
      flex-grow: 1;
      display: block;
      text-decoration: none;
      color: inherit;

      div {
        justify-content: left !important;
      }
    }

    .description {
      font-size: 0.75rem;
      color: $gray-64;
      padding-left: 2rem;
      display: inline-block;
      padding-bottom: 0.75rem;
    }

    &:last-child {
      border: 0;
    }
  }
}
