$white: #fff;
$white-space: #eaecf0;
$white-smoke: #f5f5f5;
$white-smoke-2: #f7f7f7;
$gray-17: #d3d3d3;
$black-transparent: #00000035;
$white-transparent: #ffffff15;
$content-primary: #0e121b;
$background-secondary: #f5f7fa;
$action-active: #007bef;
$border-primary: #f2f5f8;

$gray-space: #454c5f;
$gray-4: #f9f9fb;
$gray-8: #f3f4f6;
$gray-16: #dcdee5;
$gray-24: #c4c8d4;
$gray-32: #acb2c3;
$gray-40: #949cb2;
$gray-48: #7d86a1;
$gray-56: #67718e;
$gray-64: #565d76;
$gray-72: #444c5f;
$gray-80: #333947;
$gray-92: #151515;

$primary-noir: #3a3f51;

$brand-light: #e9f5ff;

$primary-pure: #2396fb;
$primary-hover: #0582ef;
$primary-4: #f6fbff;
$primary-8: #eef7ff;
$primary-16: #dceefe;
$primary-24: #c3e3fe;

$blue-cloud: #a8dffa;
$blue-cloud-8: #f8fcff;
$blue-cloud-16: #f1fafe;
$blue-cloud-hover: #64c6f7;

$night-sky: #314b72;
$dark-sky: #0f2443;
$dark-soft: #22262f;

$neutral-highlights: #f2f6fe;
$red-block: #f43454;
$red-block-hover: #df0c2f;
$red-block-8: #feeff2;

$yellow-bee: #ffc933;
$yellow-bee-hover: #ffae0b;
$yellow-bee-4: #ffc9330a;
$yellow-bee-8: #fff8e6;

$green-ocean: #60d2d2;
$green-ocean-4: #b5d670;
$green-status: #18dc81;
$green-status-hover: #00c267;
$green-status-8: #edfdf7;

$purple-4: #f6f2fa;
$purple-8: #8e57c2;
$purple-16: #b86dff;
$purple-24: #814ab5;

$shadow-4: 0px 8px 24px rgba(0, 0, 0, 0.04);
$shadow-5: 0px 10px 28px rgba(0, 0, 0, 0.05);
$shadow-6: 0px 12px 32px rgba(0, 0, 0, 0.06);
$shadow-7: 0px 14px 36px rgba(0, 0, 0, 0.07);
$shadow-8: 0px 16px 40px rgba(0, 0, 0, 0.08);
$shadow-9: 4px 0px 8px -2px rgba(0, 0, 0, 0.04);
$shadow-10: -4px 0px 8px -2px rgba(0, 0, 0, 0.04);

$xs: 0px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$fullHD: 1920px;
