@import '../../../assets/scss/DesignSystemV2/_variables.scss';
@import '../../../assets/scss/DesignSystemV2/animation.scss';

.topbar-wrapper {
  height: 4rem;
  width: 100%;
  flex: 0.5;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  box-shadow: $shadow-4;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 899;

  .left-part {
    max-width: 25.5rem;
    flex: 0.5;
    height: auto;
    display: flex;
    gap: 1.5rem;
    padding: 0.75rem 1rem;

    .logo-wrapper {
      display: flex;
      align-items: center;
      height: 100%;
      flex: 1;
      max-width: 12rem;

      .hamburguer-button {
        background-color: transparent;
        display: grid;
        place-items: center;
        border: 0;
        position: relative;
        color: $gray-space;

        .arrow-wrapper {
          width: 1rem;
          height: 1rem;
          background-color: #fff;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          right: 0;

          span {
            transform-origin: center;
            transition: 0.5s;
          }
        }
      }
      .logo {
        background: url('https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/beeliked-logo-no-padding.png')
          center no-repeat;
        width: 100%;
        max-width: 8.5rem;
        height: 100%;
        max-height: 2.25rem;
        cursor: pointer;
      }
    }
    .cta-button {
      padding: 0.5rem 1rem;
      border-radius: 1.25rem;
      border: 0;
      color: #fff;
      text-decoration: none;
      line-height: 180%;
      background: linear-gradient(
        to right,
        #6ec9f7,
        #bfaffc,
        #f98bc2,
        #f98bc2,
        #ff9f61,
        #ffc933,
        #ffc933,
        #b5d670,
        #6ec9f7
      );
      font-weight: 600;
      font-size: 0.85rem;
      transition: 0.5s;
      background-size: 300% 100%;
      box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
      animation: background-animation-3 15s infinite linear, pulse 2s infinite ease;
    }
  }

  .right-part {
    flex: 0.85 1;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button {
      padding: 0.75rem;

      > div {
        gap: 0.25rem;
      }
    }

    .more-actions-wrapper {
      width: 3rem;
    }

    .count-notify {
      background: $red-block;
      color: #fff;
      border-radius: 1rem;
      font-size: 0.6rem;
      top: -0.75rem;
      right: -0.25rem;

      overflow: hidden;
      position: absolute;
      min-width: 1.5rem;
      min-height: 1.25rem;

      span {
        position: absolute;
        left: 50%;
        top: 50%;
        transition: 0.5s;
      }
      span:first-child {
        transform: translate(-50%, -150%);

        &.animate {
          transform: translate(-50%, -50%);
        }
      }
      span:last-child {
        transform: translate(-50%, -50%);

        &.animate {
          transform: translate(-50%, 50%);
        }
      }
    }

    .interactions-wrapper {
      border-left: 1px solid $neutral-highlights;
      border-right: 1px solid $neutral-highlights;
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      max-width: fit-content;
      transition: 0.5s;
      overflow: hidden;

      > a {
        color: unset;
        text-decoration: unset;

        div {
          border-radius: 0;
        }
      }
    }
  }
}

.content-wrapper {
  display: flex;
  position: relative;
  overflow-x: clip;
  min-height: calc(100vh - 4rem);

  @media (max-width: $lg) {
    padding-bottom: 4rem;
  }

  .sidebar-wrapper {
    position: sticky;
    background-color: #fff;
    top: 4rem;
    width: 15.5rem;
    height: 100%;
    min-height: calc(100vh - 4rem);
    transition: 0.5s;
    z-index: 899;
    min-width: 0;
    &:not(.collapsed) {
      max-width: 13rem;
      min-width: 13rem;
    }

    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      flex: 1;

      > li {
        transition: 0.5s background, 0.25s border;
        border-right-color: $primary-24;
        display: flex;
        cursor: pointer;

        &:hover,
        &.active {
          background-color: $primary-8;
          border-right: 2px solid $primary-24;
        }

        &.blank {
          height: 1.5rem;
          pointer-events: none;
        }

        &.hr {
          pointer-events: none;
          border-bottom: 1px solid $neutral-highlights;
          margin-bottom: 0.75rem;
          height: 0.75rem;
        }

        > a,
        > button {
          position: relative;
          transition: 0.5s;
          width: 100%;
          padding: 0.65rem 1.5rem !important;
          justify-content: flex-start;

          > div {
            overflow: hidden;
          }
        }
      }
    }

    &.collapsed {
      width: 4.5rem;

      > ul > li {
        button > div {
          width: 1.5rem;
        }
      }
    }
  }

  .children-wrapper {
    background: $primary-8;
    width: 100%;
    padding: 1.5rem;
  }
}

.modal-footer-wrapper {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
