@import '../../assets/scss/DesignSystemV2/_variables.scss';
@import '../../assets/scss/DesignSystemV2/animation.scss';

.sidebar-wrapper {
  position: sticky;
  background-color: #fff;
  top: 4rem;
  width: 15.5rem;
  height: calc(100vh - 4rem);
  transition: 0.5s;
  z-index: 899;
  min-width: 0;
  &:not(.collapsed) {
    max-width: 13rem;
    min-width: 13rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1;

    li {
      transition: 0.5s background, 0.25s border;
      border-right-color: $primary-24;
      display: flex;
      cursor: pointer;

      &:hover,
      &.active {
        background-color: $primary-8;
        border-right: 2px solid $primary-24;
      }

      &.blank {
        height: 1.5rem;
        pointer-events: none;
      }

      &.hr {
        pointer-events: none;
        border-bottom: 1px solid $neutral-highlights;
        margin-bottom: 0.75rem;
        height: 0.75rem;
      }

      a,
      button {
        position: relative;
        transition: 0.5s;
        width: 100%;
        padding: 0.65rem 1.5rem !important;
        justify-content: flex-start;

        div {
          overflow: hidden;
        }
      }
    }
  }
  .actions-wrapper {
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1;
    position: absolute;
    bottom: 1rem;
    width: 100%;
  }

  &.collapsed {
    width: 4.5rem;

    ul > li {
      button > div {
        width: 1.5rem;
      }
    }
  }
}
