.content-error {
  display: grid;
  place-items: center;
  height: 100%;
  > div {
    display: grid;
    place-items: center;

    > p {
      text-align: center;
    }
  }
}
