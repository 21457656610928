@import '../../../../../../assets/scss/DesignSystemV2/variables';

.container-section {
  overflow: unset;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;

  @media (max-width: $xl) {
    grid-template-columns: 1fr;
  }

  .hidden {
    display: none;
  }

  > div {
    border-radius: 0.5rem;
    border: 1px solid $gray-8;
    background: $white;
    padding: 1rem;
    position: relative;
  }

  &.one-column {
    grid-template-columns: 1fr;
  }
}
