@import '../../../../assets/scss/DesignSystemV2/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0 1rem 0;
  gap: 1.25rem;
  border-radius: 1rem;
  background: $white;
  justify-content: center;
  overflow: hidden;

  .content-container {
    padding: 0 1rem;

    > footer {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }
  }
}
