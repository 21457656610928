@import '../../assets/scss/DesignSystemV2/variables';
@import '../../assets/scss/DesignSystemV2/Mixins/scrollbar';

.table {
  overflow-x: auto;
  overflow-y: hidden;
  background-color: $blue-cloud-8;

  @include scrollbar();

  &.shadow-first-column > header > div:first-child,
  &.shadow-first-column > main > .column:first-child {
    box-shadow: $shadow-9;
  }

  &.shadow-last-column > header > div:last-child,
  &.shadow-last-column > main > .column:last-child {
    box-shadow: $shadow-10;
  }

  > header > div,
  > main .column {
    width: 11.5rem;
  }

  > header,
  > main {
    width: 100%;
    min-width: fit-content;

    .column-checkbox {
      width: unset;
    }

    .alignment-column {
      width: 11.5rem;
      flex: 1;
      > div {
        width: 100%;
      }
    }
    .fill-column {
      width: 6rem;
      flex: 1;
      > div {
        width: 100%;
      }
    }
  }
  .bulk-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background-color: $blue-cloud-8;
    height: 3.25rem;

    > p {
      flex: 1;
      position: absolute;
    }

    > button {
      position: absolute;
      right: 3.5rem;
    }
  }
  > header {
    background: $gray-4;
    display: flex;
    min-height: 3rem;
    align-items: center;
    border-bottom: 1px solid $gray-8;

    .wrapper-manage-filters {
      list-style: none;
      padding: 0.75rem;
      height: 100%;
      max-height: 18rem;
      overflow: auto;
    }

    &.first-column-fixed {
      > div {
        &:first-child {
          padding-left: 1rem;
          justify-content: flex-start;
          align-items: center;
          min-width: fit-content !important;
          position: sticky;
          left: 0px;
          z-index: 3;
          background: inherit;
          height: 3rem;
        }

        &:nth-child(2) {
          padding-left: 1rem;
          justify-content: flex-start;
        }
      }
    }

    &.last-column-fixed {
      > div:last-child > div {
        min-width: 11.5rem;
      }

      > div {
        &:last-child {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 1rem;
          position: absolute;
          right: 2.5rem;
          background: inherit;
          height: 3rem;
          z-index: 10;

          > div {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }

    &.filters-fixed {
      > div:last-child > div {
        min-width: 11.5rem;
      }

      > div {
        &:last-child {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 1rem;
          position: absolute;
          right: 2.5rem;
          background: inherit;
          height: 3rem;
          z-index: 0;

          > div {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }

    > div {
      display: flex;
      justify-content: center;
      font-weight: 600;
      color: $gray-56;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      &:nth-child(2) {
        justify-content: flex-start;
      }
    }
  }
  > main {
    display: flex;

    .column {
      display: flex;
      flex-direction: column;

      &.capitalize {
        > div {
          text-transform: capitalize;
        }
      }

      &.lowercase {
        > div {
          text-transform: lowercase;
        }
      }

      &.uppercase {
        > div {
          text-transform: uppercase;
        }
      }

      &.center {
        > div {
          justify-content: center;
        }
      }
      &.left {
        > div {
          justify-content: flex-start;
        }
      }
      &.right {
        > div {
          justify-content: flex-end;
        }
      }

      &:first-child > div {
        justify-content: flex-start;
      }

      > div {
        height: 4rem;
        padding: 1.25rem 0.5rem;
        display: flex;
        align-items: center;

        &:nth-child(even) {
          background-color: $gray-4;
        }
        &:nth-child(odd) {
          background-color: $white;
        }
      }
    }

    &.first-column-fixed {
      .column {
        display: flex;
        flex-direction: column;

        &:first-child {
          min-width: fit-content !important;
          position: sticky;
          left: 0px;
          z-index: 3;

          > div {
            padding-left: 1rem;
            justify-content: flex-start;
          }
        }
        &:nth-child(2) {
          > div {
            padding-left: 1rem;
            justify-content: flex-start;
          }
        }
      }
    }

    &.last-column-fixed {
      .column {
        display: flex;
        flex-direction: column;

        &:last-child {
          position: absolute;
          right: 2.5rem;
          background: inherit;
          min-width: fit-content;

          > div {
            padding-right: 1rem;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }

    .wrapper-cta {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }

  &.skeleton-table {
    header,
    main {
      min-width: unset;
      margin-right: unset;
      div {
        width: 20%;
        div {
          width: 100%;
        }
      }
    }
  }

  &.lg {
    main .column > div {
      height: 6.5rem;
    }
  }
  .wrapper-modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.empty-page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;

  h2 {
    margin-top: 3.125rem;
  }

  img {
    width: 15.625rem;
    margin-bottom: 1.5rem;
  }
}

.wrapper-entries-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 1rem;
    div {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      min-width: fit-content;
      width: 20rem;
      max-width: 100%;
    }
  }
  main {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: flex;
        align-items: center;
        padding: 0.75rem 0.5rem;
        min-height: 3.25rem;
        gap: 0.5rem;

        > p,
        > div {
          height: 100%;

          &:first-child {
            min-width: 9rem;
            padding-right: 1rem;
            margin-right: 1rem;
          }
        }

        &:nth-child(odd) {
          background: #fff;
          border-top: 1px solid $blue-cloud-16;
          border-bottom: 1px solid $blue-cloud-16;
        }
      }
    }
  }
}
