@import '../../assets/scss/DesignSystemV2/_variables.scss';

.media {
  overflow: hidden;
  border-radius: 1rem;
  position: relative;

  .thumbnail {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 1;
    justify-content: center;
    transition: opacity 0.25s, 0.5s visibility;

    button {
      width: 3rem;
      height: 3rem;
      border: 0;
      border-radius: 50%;
      position: absolute;
      z-index: 2;

      margin: 0;
      padding: 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: #fff;
        font-size: 4.5rem;
        background-color: $yellow-bee;
      }

      &::before {
        content: '';
        position: absolute;
        width: 3.75rem;
        height: 3.75rem;
        background-color: $yellow-bee;
        z-index: -1;
        border-radius: 50%;
      }
    }
  }

  .thumbnail {
    cursor: pointer;
  }

  .thumbnail,
  img,
  video {
    width: 100%;
    border-radius: 0.5rem;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
