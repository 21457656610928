@import '../../../assets/scss/DesignSystemV2/_variables.scss';

.modal {
  position: fixed;
  width: 100%;
  border: 1px solid black;
  background-color: #fff;
  z-index: 99999;
  animation: fade-in 0.5s ease;
  border-radius: 1rem;
  box-shadow: $shadow-5;

  &.small {
    max-width: 22.5rem !important;
  }
  &.medium {
    max-width: 35.625rem !important;
  }
  &.lg {
    max-width: 50rem;
  }
  &.xl {
    max-width: 65rem;
  }

  &.xxl {
    max-width: 75rem;
  }

  &.fullscreen {
    max-width: calc(100% - 6rem);
    height: calc(100% - 6rem);
  }

  > header {
    padding: 1rem;
    border-radius: 1rem 1rem 0 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-8;

    title {
      font-size: 1rem;
      font-weight: 800;
      display: block;
    }
  }

  > main {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    label {
      font-size: 0.85rem;
      font-weight: 500;
      color: #000;
    }

    .main-title {
      font-size: 0.85rem;
      font-weight: 300;
    }
  }

  > footer {
    display: flex;
    border-top: 1px solid $gray-8;
    padding: 1rem;
    background-color: #f6fbff;
    border-radius: 0 0 1rem 1rem;
  }
}
