@import '../../../assets/scss/DesignSystem/colors';

.spinner-inline {
  font-size: 12px;
  width: min-content;
  height: min-content;
  text-align: center;
  background: $color-light;
  border-radius: 5px;
  padding: 8px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  object {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}
