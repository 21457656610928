.wrapper-progress-bar {
  justify-content: flex-end;
  position: relative;

  .info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 15px;
    align-items: center;

    @media (min-width: 768px) {
      min-width: 280px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      min-width: 170px;
    }
    h2 {
      font-size: 13px;
    }
  }
  .percentage {
    min-width: 70px;
    height: 20px;
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    flex: 1;
  }
  .progress-bar {
    position: absolute;
    width: 100%;
    height: 8px;
    background-color: $color-highlights;
    bottom: 0;
    left: 0;
    display: flex;
    border-radius: 5px;
    .progress {
      width: 50%;
      height: 8px;
      background-size: 1rem 1rem;
      background-color: $color-primary;

      &.progress-failed {
        background-color: transparent !important;
      }
    }
    .progress-loading {
      animation: 2s linear infinite progress-bar-stripes, 2s ease-in-out infinite loadingWidth;
      background-image: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
      );
    }
  }
}
@keyframes loadingWidth {
  0% {
    width: 0%;
  }
  70% {
    width: 100%;
    margin-left: 0;
  }
  90% {
    margin-left: 100%;
    width: 100%;
    opacity: 1;
  }
  95% {
    margin-left: 100%;
    width: 0%;
    opacity: 0;
  }
  100% {
    margin-left: 0%;
    width: 0%;
    opacity: 0;
  }
}
