@import '../../../../../assets/scss/DesignSystemV2/variables';

.section {
  border: 1px solid $blue-cloud-8;
  display: flex;
  justify-content: space-between;
  border: 1px solid $gray-8;
  border-radius: 0.5rem;
  transition: 0.5s;
  cursor: pointer;

  &.no-cursor {
    cursor: auto;
  }
  &.active,
  &:hover {
    background: $blue-cloud-8;
    border-color: $blue-cloud;
  }
  &.grid {
    flex-direction: column;
    height: 10.5rem;
    padding: 1rem;
    position: relative;

    .overlay {
      pointer-events: none;
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $gray-8;
      height: 100%;
      width: 100%;

      > div {
        margin-top: -16px;
      }
    }
  }
  &.list {
    padding: 0.5rem 1rem;
    flex-direction: row;
    // @TODO: it will be used in the future with favorite account
    // > header {
    //   flex-direction: row-reverse;
    // }
    > footer {
      gap: 0.25rem;
    }
  }

  > header {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    > div:nth-child(1) {
      max-width: 70%;
    }
    > button {
      font-size: 1rem;
      border-radius: 1rem;
      border: 1px solid $gray-8;
      transition: 0.5s;
      width: 2rem;
      height: 2rem;
      > span {
        font-size: inherit;
        color: $gray-24;
        height: 0.85rem;
      }
      &:hover,
      &.active {
        border-color: $primary-24;
        background: $primary-8 !important;
      }
      &.active > span {
        color: $primary-pure;
      }
    }
  }
  > footer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-width: 18rem;

    > div {
      display: flex;
      gap: 0.5rem;

      > aside {
        width: 2.25rem;
        height: 2.25rem;
        display: grid;
        place-items: center;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        &.noplan,
        &.freebee {
          background-color: $gray-8;
          > span {
            color: $gray-48;
          }
        }
        &.essentials {
          background-color: $green-status-8;
          > span {
            color: $green-ocean;
          }
        }
        &.standard {
          background-color: $primary-8;
          > span {
            color: $primary-pure;
          }
        }
        &.premium {
          background-color: $yellow-bee-8;
          > span {
            color: $yellow-bee;
          }
        }
        &.enterprise {
          background-color: $purple-4;
          > span {
            color: $purple-8;
          }
        }
      }
      > main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        p {
          text-transform: capitalize;

          &:nth-child(1) {
            color: $gray-space;
          }
          &:nth-child(2) {
            color: $gray-40;
          }
        }
      }
    }
  }
}
