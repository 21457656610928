@import '../../../../assets/scss/DesignSystemV2/variables';

.filters-container {
  border-bottom: 1px solid $gray-8;
  padding: 0 1rem 1.25rem 1rem;

  .filters-container-header {
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      gap: 1rem;
    }
  }

  .date-wrapper {
    input {
      margin-top: 0.125rem;
      height: 42px;
      border: 1px solid #dcdee5;
    }
  }

  .filters-container-fields {
    margin-top: 1rem;
    border-radius: 0.5rem;
    border: 1px solid $blue-cloud;
    background: $blue-cloud-8;
    padding: 1.25rem 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    > div:first-child {
      grid-gap: 1rem;
      gap: 1rem;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    }
  }

  .wrapper-manage-filters {
    list-style: none;
    padding: 0.75rem;
    max-height: 15rem;
    overflow-y: auto;
  }

  footer {
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      gap: 1rem;
    }
  }
}

@media (max-width: $sm) {
  .filters-container {
    footer {
      flex-direction: column;
      gap: 1rem;

      > div {
        justify-content: space-between;
      }
    }
  }
}
