@import '../../assets/scss/DesignSystemV2/_variables.scss';

.media {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  overflow: hidden;
  border-radius: 1rem;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 1rem;
    z-index: 1;

    img {
      width: 100%;
      height: auto;
      border-radius: 1rem;
    }

    button {
      width: 3rem;
      height: 3rem;
      border: 0;
      border-radius: 50%;
      position: absolute;
      z-index: 2;
      margin: 0;
      padding: 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: $white;
        font-size: 4.5rem;
        background-color: $yellow-bee;
      }

      &::before {
        content: '';
        position: absolute;
        width: 3.75rem;
        height: 3.75rem;
        background-color: $yellow-bee;
        z-index: -1;
        border-radius: 50%;
      }
    }
  }
}
