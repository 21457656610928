.input-icon {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;

  input {
    height: 100%;
  }

  .side-icon-input {
    position: absolute;
    right: 1rem;
    cursor: pointer;
  }
}
