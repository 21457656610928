@import '../../../../../../assets/scss/DesignSystemV2/_variables';
.form-wrapper {
  display: grid;
  place-content: center;
  place-items: center;
  gap: 1rem;
  grid-template-columns: 1fr;
  padding: 4rem;
  height: 100%;

  .wrapper-paragraph {
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
    gap: 0.5rem;
    width: 100%;

    > div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      justify-content: center;
    }
  }
  form {
    border-top: 1px solid $gray-8;
    margin-top: 1rem;
    padding-top: 1rem;
    width: 100%;

    .wrapper-buttons {
      display: grid;
      gap: 0.5rem;
      margin-bottom: 1.625rem;

      .wrapper-verify-code {
        margin-top: 1rem;
        text-align: center;
      }

      button[type='submit'] {
        margin-top: 1.5rem;
      }
    }
  }
  .resend-text-wrapper {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    a {
      text-decoration: none;
      font-size: 0.85rem;
      font-weight: 600;
    }
  }
}
