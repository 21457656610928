@import './../../../assets/scss/DesignSystemV2/variables';

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    height: 2.8125rem;
    width: 100%;
    border-radius: 0 0 1.5rem 1.5rem;
    }

.footer-container{
font-weight: bold;
font-size: 0.75rem;
    }

.flex-center-gap {
    display: flex;
    align-items: center;
    gap: 0.125rem;
  }