@import '../../../../../assets/scss/DesignSystemV2/variables';

.container-section {
  overflow: unset;

  label {
    font-weight: 600;
    margin-bottom: 0.25rem;
  }

  > div {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    > p {
      margin-top: 0.25rem;
      color: $gray-56;
    }
  }
}

.loading {
  position: relative;
  > div {
    padding: 2rem 0;
    position: relative;
  }
}
