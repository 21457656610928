@import '../../../../../assets/scss/DesignSystemV2/variables';
.wrapper-entries-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 1rem;
    div {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      min-width: fit-content;
      width: 20rem;
      max-width: 100%;
    }
  }
  main {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: flex;
        align-items: center;
        padding: 0.75rem 0.5rem;
        text-transform: capitalize;
        min-height: 3.25rem;
        gap: 0.5rem;

        > p,
        > div {
          height: 100%;

          &:first-child {
            min-width: 9rem;
            padding-right: 1rem;
            margin-right: 1rem;
          }
        }

        &:nth-child(odd) {
          background: #fff;
          border-top: 1px solid $blue-cloud-16;
          border-bottom: 1px solid $blue-cloud-16;
        }
      }
    }
  }
}
