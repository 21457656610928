@import '../../../../../assets/scss/DesignSystemV2/_variables.scss';
@import '../../../../../assets/scss/DesignSystemV2/Mixins/scrollbar';

.dropdown-wrapper {
  position: relative;

  &.fullwidth {
    width: 100%;
  }

  .select-overlay {
    position: fixed;
    inset: 0;
    z-index: 1;
  }

  .wrapper-select,
  .wrapper-input-with-pill {
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .wrapper-select {
    position: relative;
    z-index: 0;

    .select-dropdown {
      display: block;
      width: 100%;
      padding: 0.5rem 0.75rem;
      -moz-padding-start: calc(0.75rem - 3px);
      font-size: 1rem;
      font-weight: 400;
      color: $gray-40;
      border: 1px solid $gray-16;
      border-radius: 0.5rem;
      background-color: $white;
      cursor: pointer;

      &::placeholder {
        color: $gray-40;
      }

      &:disabled,
      &[disabled] {
        cursor: auto !important;
        background-color: $gray-8;
        border-color: $gray-8;

        &::placeholder {
          color: $gray-16;
        }
      }
    }

    > div {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }

    > span {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.25rem;
      pointer-events: none;
      color: $gray-40;
    }
  }

  .wrapper-input-with-pill {
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
    border: 1px solid $gray-16;
    background-color: #fff;
    display: flex;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    > p {
      color: $gray-40;
    }
    > * {
      cursor: pointer;
    }

    > span {
      position: absolute;
      right: 0.75rem;
      top: 50%;
      font-size: 1.25rem;
      pointer-events: none;
      color: $gray-40;
    }
  }

  .dropdown-pill-select,
  .dropdown-select {
    z-index: 999;
    position: absolute;
    width: 100%;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      max-height: 15rem;
      overflow-y: auto;
      @include scrollbar();
    }
  }
  .dropdown-pill-select {
    padding: 0.5rem;
    background-color: #fff;
    border: 1px solid $gray-8;
    border-radius: 0.5rem;
    margin-top: 0.25rem;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    ul {
      button {
        background: transparent;
        border: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0.75rem;
        color: $gray-40;
        transition: 0.25s;
        border-radius: 0.25rem;
        outline: 1px solid transparent;
        min-height: 2.5rem;

        > div {
          span {
            display: none;
          }
        }

        &:hover {
          color: $primary-pure;
          background-color: $primary-8 !important;
          outline-color: $primary-16 !important;

          > div {
            span:nth-child(2) {
              display: block;
            }
          }
        }

        &.active {
          color: $primary-pure;
          background-color: $primary-4;

          &:hover {
            > div {
              span:nth-child(1) {
                display: none;
              }
            }
          }
          > div {
            span:nth-child(1) {
              display: block;
            }
          }
        }
      }
    }
  }
  .dropdown-select {
    @include scrollbar();

    ul {
      margin-top: 0.5rem;
      padding: 1rem;
      border: 1px solid $gray-16;
      border-radius: 0.5rem;
      display: flex;
      flex-direction: column;
      background: #fff;
      width: 100%;
      position: absolute;
      z-index: 900;
      max-height: 15rem;
      overflow-y: auto;

      &.focus {
        z-index: 2;
        position: absolute;
      }

      li {
        color: $gray-40;
        padding: 0.5rem;
        transition: 0.2s;
        cursor: pointer;
        display: flex;
        align-items: center;

        input {
          width: 1rem;
          height: 1rem;
          cursor: pointer;
          margin-right: 0.5rem;
        }

        &.selected,
        &:hover {
          background: $primary-8;
        }
      }
    }
  }

  &.sm {
    label {
      font-size: 12px;
      padding-bottom: 0.25rem;
      line-height: 1.08;
    }

    .wrapper-select .select-dropdown {
      padding: 0.5rem;
      font-size: 0.75rem;
      border-radius: 0.375rem;
    }
  }
}
