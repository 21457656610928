.wrapper-radiobox {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &.md {
    > input[type='radio'] {
      height: 1rem;
      width: 1rem;
    }
  }
}
