.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 4.75rem;
  .heading-wrapper {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
  }
  > section {
    display: grid;
    gap: 1.5rem;
  }
}
