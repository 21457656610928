@import '../../../../assets/scss/DesignSystemV2/variables';
.header-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .wrapper-buttons {
    display: flex;
    gap: 1rem;

    input {
      width: 12rem;
    }
  }

  > header {
    display: flex;
    justify-content: center;
    align-items: center;

    .title-wrapper {
      display: flex;
      gap: 0.5rem;
    }
    > h1 {
      margin-right: 0.5rem;
    }
    > div:last-child {
      display: none;
    }
  }
  > div:last-child {
    display: flex;
    justify-content: space-between;
    > button:first-child {
      margin-right: 1rem;
    }
  }

  .send-email-wrapper {
    > main {
      padding: 3rem 1.5rem;
    }
  }
}
@media (max-width: $md) {
  .header-wrapper {
    gap: 1rem;
    flex-direction: column;

    header {
      justify-content: space-between;
      > div:last-child {
        display: flex;
      }
    }

    .wrapper-buttons {
      > div:first-child {
        display: none;
      }
      > button {
        width: 100%;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
