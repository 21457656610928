@import '../../../../../assets/css/variables';

.news-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem;
  height: fit-content;
  place-content: flex-start;

  .tutorial-wrapper {
    height: 17.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-area: tutorial;

    .video-wrapper {
      width: 100%;
      height: 15.25rem;
      position: relative;

      .thumbnail {
        background-color: #3a3f51;
        position: absolute;
        left: 0;
        display: grid;
        place-items: center;
        z-index: 1;

        button {
          width: 1.25rem;
          height: 1.25rem;
          border: 0;
          border-radius: 50%;

          margin: 0;
          padding: 0;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: #fff;
            font-size: 3rem;
            background-color: $yellow-bee;
          }

          &::after {
            content: '';
            position: absolute;
            width: 3.75rem;
            height: 3.75rem;
            background-color: $yellow-bee;
            z-index: -1;
            border-radius: 50%;
          }
        }
      }
      .thumbnail,
      video {
        width: inherit;
        height: inherit;
        border-radius: 0.5rem;
      }
    }
  }
}
