@import '../../../../../assets/scss/DesignSystemV2/_variables.scss';
@import '../../../../../assets/scss/DesignSystemV2/Mixins/scrollbar';

.dropdown-wrapper {
  position: relative;

  &.fullwidth {
    flex: 1;
  }

  > label {
    margin-bottom: 0.25rem;
  }
  .select-overlay {
    position: fixed;
    inset: 0;
    z-index: 1;
  }

  .wrapper-select {
    position: relative;
    z-index: 0;

    .select-dropdown {
      display: block;
      width: 100%;
      padding: 0.5rem 0.75rem;
      -moz-padding-start: calc(0.75rem - 3px);
      font-size: 1rem;
      font-weight: 400;
      color: $gray-40;
      border: 1px solid $gray-8;
      border-radius: 0.5rem;
      background-color: #fff;
      cursor: pointer;

      &::placeholder {
        color: $gray-40;
      }

      &:disabled,
      &[disabled] {
        cursor: auto !important;
        background-color: $gray-8;
        border-color: $gray-8;

        &::placeholder {
          color: $gray-16;
        }
      }
    }

    > div {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }

    > span {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.25rem;
      pointer-events: none;
      color: $gray-40;
    }
  }

  .dropdown-select {
    margin: 0;
    padding: 1rem;
    border: 1px solid $gray-8;
    border-radius: 0.5rem;
    background: $white;
    width: 100%;
    position: absolute;
    z-index: 900;
    margin-top: 0.5rem;
    @include scrollbar();

    &.focus {
      z-index: 2;
      position: absolute;
    }

    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin-top: 0.5rem;
      margin-bottom: 0;
      padding-left: 0;
      overflow-y: auto;
      max-height: 15rem;
      @include scrollbar();

      li {
        color: $gray-40;
        padding: 0.5rem;
        transition: 0.2s;
        cursor: pointer;
        display: flex;
        align-items: center;

        &.selected,
        &:hover {
          background: $primary-8;
        }

        > div {
          margin-left: 0.5rem;
        }
      }
    }
  }

  &.sm {
    label {
      font-size: 12px;
      padding-bottom: 0.25rem;
      line-height: 1.08;
    }

    .wrapper-select .select-dropdown {
      padding: 0.5rem;
      font-size: 0.75rem;
      border-radius: 0.375rem;
    }

    .helper-text {
      font-size: 0.75rem !important;
    }
  }

  &.red-block {
    .wrapper-select {
      input {
        border-color: $red-block !important;
      }
    }
    .helper-text {
      color: $red-block;
    }
  }

  .helper-text {
    font-size: 0.85rem;
    color: $gray-56;
  }
}
