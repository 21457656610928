.background {
  border-radius: 0.5rem;
  flex: 1;

  .wrapper {
    display: flex;
    justify-content: center;
    padding-top: 5rem;

    .container {
      width: 22.5rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      border-radius: 0.5rem;
      padding: 2.5rem 3.5rem;

      h3 {
        text-align: center;
        font-weight: 800;
        font-size: 1.5rem;
        margin-top: 2rem;
        margin-bottom: 2.5rem;
      }

      p {
        text-align: center;
        font-weight: 600;
        font-size: 0.875rem;
        margin-bottom: 0;
      }

      .qr-code {
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        border: 2px solid white;
      }

      .expire-time-container {
        display: flex;
        justify-content: center;

        .expire-time {
          display: flex;
          align-items: center;

          .timer {
            margin-left: 0.2rem;
            display: flex;
            align-items: center;
            width: 2rem;
            height: 2rem;
            border: 1px solid rgba(255, 255, 255, 0.2);
            background-color: rgba(255, 255, 255, 0.3);
            justify-content: center;
            border-radius: 1rem;
            color: white;
            font-weight: 600;
            font-size: 0.75rem;
          }
        }
      }

      .button-container {
        display: flex;
        justify-content: center;
        button {
          padding: 0.5rem 0.75rem;
          border: 1px solid rgba(255, 255, 255, 0.2);
          background-color: rgba(255, 255, 255, 0.3);
          border-radius: 0.5rem;
          font-weight: 600;
          font-size: 0.875rem;
        }
      }
    }
  }
}
