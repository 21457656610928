.editor-quill {
  &.editor-absolute {
    z-index: 1099;
    position: absolute;
    width: 100%;
  }
  .ql-snow .ql-picker-options .ql-picker-item {
    &:before {
      font-size: 14px !important;
    }
  }

  .ql-toolbar.ql-snow {
    border-bottom: 0;
    border-color: #d0dbe9;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    background-color: #ffffff;
  }

  .ql-container.ql-snow {
    border-color: #d0dbe9;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    background-color: #ffffff;
  }

  .quill .ql-editor {
    p {
      padding-top: 0 !important;
      font-size: 14px !important;
      color: #212529;
    }
  }

  &.is-invalid {
    .ql-toolbar.ql-snow {
      border-color: red;
    }

    .ql-container.ql-snow {
      border-left-color: red;
      border-right-color: red;
      border-bottom-color: red;
    }
  }

  .two-lines {
    .ql-editor {
      height: 4rem;
    }
  }

  .one-line {
    .ql-editor {
      height: 2.3rem;
      overflow-y: hidden;
      line-height: 1;
    }
  }

  &.hide {
    .ql-toolbar {
      display: none;
    }

    .ql-snow {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
  }
}

.editor-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1060;
}
