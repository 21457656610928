@import "../../../../../../assets/scss/DesignSystemV2/variables";

  .embed_preview_body {
    padding: 1.5rem;
    background: $blue-cloud-8;
    hr {
      background-color: 1px solid $primary-24;
    }
    .body_section_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
      .badge_number {
        background: $primary-8;
        border: 1px solid $primary-24;
        border-radius: 50%;
        padding: 0.5rem;
        color: $primary-pure;
        font-size: 0.875rem;
        font-weight: 800;
        line-height: 1.05rem;
      }
    }
    .plugin_copy_text {
      color: $gray-56;
    }
    .plugin_code_text {
      background: $white;
      border: 1px solid $gray-8;
      padding: 0.5rem 0.75rem;
      color: $gray-40;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
    .copy_button {
      border: none;
      background-color: $white;
      height: 2rem;
      display: flex;
      align-items: center;
      gap: 0.3rem;
      font-size: 0.75rem;
      border-radius: 0.375rem;
      padding: 0.4rem 0.8rem;
      border: 1px solid $primary-24;
    }
    .copy_button_section {
      margin-top: 1rem;
    }
    .copy_hmac_key_button{
      border: none;
      background-color: $white;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.3rem;
      font-size: 0.75rem;
      border-radius: 0.375rem;
      padding: 0.4rem 0.8rem;
      border: 1px solid $primary-24;
    }
  }

  .copy_icon{
    padding-top: 0.188rem;
    color: $primary-pure;
    font-size: 1rem;
  }

