.input-with-icon {
  input {
    &.is-invalid {
      background: none !important;
    }
  }
  .icon-close {
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
  }
  .hidden-close {
    display: none;
  }
}
