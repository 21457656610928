.input-quantity {
  display: flex;

  input[type='text'] {
    text-align: center;
    border-radius: 0.25rem 0 0 0.25rem;

    &::placeholder {
      color: $color-grey-light;
    }

    &.xs {
      width: 50px;
    }
  }

  .wrapper-buttons {
    border: 1px solid $color-grey-light;
    border-left-width: 0;
    border-radius: 0 0.25rem 0.25rem 0;
    display: flex;
    flex-direction: column;
    padding: 0 0.3rem;

    .btn {
      margin-left: 0 !important;
      padding: 0;
      width: 20px;
      background-color: $color-highlights;
      border: 0;
      border-radius: 0;
      height: 17px;

      &:first-child {
        height: 16px;
      }

      i {
        font-size: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
