@import '../../../../../assets/scss/DesignSystemV2/variables';

.container {
  overflow: unset;

  .spinner-container {
    position: relative;
  }
}

.section-name-promotion {
  border-radius: 0.5rem;
  border: 1px solid $gray-8;
  background-color: $white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 1.5rem 1rem 1rem 1rem;

  > div > label {
    padding-bottom: 0.25rem;
    font-weight: 600;
    font-size: 0.85rem;
  }
}

.form-column {
  border-radius: 0.5rem;
  border: 1px solid $gray-8;
  background-color: $white;
  padding: 1rem;
}

.form-share {
  margin-top: 1rem;
  border-radius: 0.5rem;
  border: 1px solid $gray-8;
  background-color: $white;
  padding: 1rem;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
  margin-top: 2rem;

  .form-column {
    .drag-drop-field > div > div {
      margin-top: 0.25rem;
    }

    .description-field {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 2rem;

      > div:nth-child(1) {
        flex-grow: 1;
        margin-right: 1rem;
      }

      > div:nth-child(2) {
        width: 100px;
      }
    }

    .widget-styles-title {
      margin-top: 2rem;
    }

    .background-line {
      display: flex;
      flex-direction: row;

      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid $gray-8;
      align-items: center;
      padding: 0.5rem 0;
      > div {
        width: 6.25rem;
      }
    }
  }

  .preview-column {
    display: flex;
    flex-direction: column;
    p {
      margin-bottom: 1rem;
    }
  }
}

.wrapper-upload {
  p:nth-of-type(2) {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .upload {
    border: 1px dashed $gray-8;
    border-radius: 0.5rem;
    padding: 2rem 1rem;
    text-align: center;
    p {
      margin-top: 0.625rem;
      margin-bottom: 0;
      display: block;
      &.file-name {
        color: $primary-pure;
      }
      a {
        text-decoration: none;
      }
    }

    .center-button {
      display: flex;
      justify-content: center;
      .button-upload {
        cursor: pointer;
        border-radius: 0.5rem;
        border: 1px solid $primary-24;
        display: flex;
        padding: 0.5rem 0.75rem;
        justify-content: center;
        align-items: center;
        font-size: 0.875rem;

        &:hover {
          color: $primary-hover;
          border: 1px solid $primary-24;
          background: $primary-8;
        }

        span {
          margin-right: 0.5rem;
          color: $primary-pure;
        }
      }
    }
  }
}
