@import '../../../assets/scss/DesignSystem/colors';
.zapier {
  .box-app {
    position: relative;
    cursor: pointer;
    background: $color-light;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }

  .arrow-divider-card {
    font-size: 24px;
  }
}
.align-logos-integrations {
  background-color: $color-grey-light;
  padding: 2rem;
}
.logos-integrations {
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: white;
  border-radius: 5px;
}
.edit-button-my-zaps {
  position: absolute;
  height: auto;
  min-height: 123px;
  right: 0;
  bottom: 0px;
  left: 0;
  background-color: $color-grey-light;
  border-radius: 0 0 0.25rem 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.3s !important;
  opacity: 0;

  .btn {
    margin: 5px;
  }

  &:hover {
    display: flex;
    opacity: 1;
  }
}

.align-info {
  width: 100%;
  min-height: 71px;
}

.status-integration {
  width: 15px;
  height: 15px;
  background-color: $color-grey-light;
  border-radius: 50%;
  margin-top: 3px;
}

.crop-text-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  line-clamp: 1 !important;
  -webkit-box-orient: vertical;
}
.crop-text-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  line-clamp: 2 !important;
  -webkit-box-orient: vertical;
}

.status-on {
  background-color: $color-success-dark;
}
.status-off {
  background-color: $color-grey-dark;
}
.status-draft {
  background-color: $color-warning;
}

.hover-popular-apps {
  transition: 0.2s;

  &:hover {
    background-color: $color-grey-light;
  }
}

.align-tags {
  display: flex;
}
