.drag-drop-upload {
  background: $color-light;
  border: 1px dashed $color-grey-light;
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 0px;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
  transition: 0.2s;

  .input-hide {
    visibility: hidden;
    display: none;
  }
}
