@import '../../../../assets/scss/DesignSystemV2/_variables.scss';
.icon-button {
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  &.lg {
    font-size: 1.5rem;
  }
  &.md {
    font-size: 1rem;
  }
  &.sm {
    font-size: 0.75rem;
  }

  strong {
    font-size: 0.65rem;
    font-weight: 600;
  }

  > span {
    color: $gray-space;
  }

  &:hover {
    background: transparent !important;
  }
}
