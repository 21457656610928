@import '../../../../assets/scss/DesignSystemV2/variables';
@import '../text-mixins.scss';

.p1 {
  font-size: 1rem;
  color: $gray-space;
  line-height: 150%;
}

.p2 {
  font-size: 0.85rem;
  color: $gray-space;
  line-height: 150%;
}

.p3 {
  font-size: 0.75rem;
  color: $gray-space;
  line-height: 150%;
}

.p1,
.p2,
.p3 {
  margin: 0;
  padding: 0;
  color: inherit;

  @include text-colors;

  @include font-weight;

  @include hide-text;
}

.mb2 {
  margin-bottom: 0.5rem;
}
