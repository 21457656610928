.testimonial-wrapper {
  background: url('https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/testimonials-initial.png') center center /
    cover no-repeat;
  display: grid;
  height: 100%;
  padding: 4rem;
  align-items: center;
  place-content: flex-end;
  position: absolute;
  width: 100%;

  aside {
    display: grid;
    gap: 1rem;
    color: #fff;

    > div {
      align-items: center;
      display: flex;
      justify-content: space-between;
      height: 3.75rem;
      background-color: #fff;
      margin-top: 1.25rem;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
      padding: 0.5rem 0.75rem;
      overflow: hidden;
    }
  }
}
