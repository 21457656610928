.sort-button {
  background: transparent;
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;

  .fa-sort-down {
    height: 6px;
    line-height: 0;
  }
  .fa-sort-up {
    height: 8px;
    line-height: 1;
  }

  span {
    margin-right: 0.25rem;
    color: #506480;
  }
}
