@import '../../../../../../assets/scss/DesignSystemV2/variables';

.container-section {
  overflow: unset;

  > div > p {
    margin-bottom: 1rem;
  }
  .grouped-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;

    .card {
      border: 1px solid $gray-8;
      background-color: $white;
      border-radius: 0.5rem;
      position: relative;
      cursor: pointer;
      padding: 1rem;

      &:hover {
        border: 1px solid $blue-cloud;
        background: $blue-cloud-16;
      }

      &.card-selected {
        border: 1px solid $blue-cloud;
        background: $blue-cloud-16;
        box-shadow: 0px 0px 0px 2px $primary-16;
      }

      .input-radio-button {
        display: flex;
        align-items: center;
        padding: 0.25rem;

        div {
          display: flex;
          align-items: center;
          margin-left: 0.5rem;

          label {
            font-weight: 800;
          }

          .icon {
            margin-left: 0.25rem;
            color: $gray-40;
            font-size: 1rem;
          }
        }
      }

      p {
        margin-left: 1.6rem;
      }

      input[type='radio']::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        content: '';
      }
    }
  }

  .divider {
    border-top: 1px solid $gray-8;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
  }
}
