@import '../../../../../../../assets/scss/DesignSystemV2/variables';

$scale-factor: 5;

.background {
  border-radius: 0.5rem;
  width: 4rem;
  margin-right: 0.5rem;
  .wrapper {
    display: flex;
    justify-content: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;

    .container {
      width: 4rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      border-radius: 0.5rem;
      padding: calc(2.5rem / $scale-factor) calc(3.5rem / $scale-factor);

      h3 {
        text-align: center;
        font-weight: 800;
        font-size: calc(1.5rem / $scale-factor);
        margin-top: calc(2rem / $scale-factor);
        margin-bottom: calc(2.5rem / $scale-factor);
      }

      p {
        text-align: center;
        font-weight: 600;
        font-size: calc(0.875rem / $scale-factor);
        margin-bottom: 0;
      }

      .qr-code {
        margin-top: calc(0.75rem / $scale-factor);
        margin-bottom: calc(0.75rem / $scale-factor);
        border: 1px solid $white;
      }

      .expire-time-container {
        display: flex;
        justify-content: center;
        margin-bottom: calc(2rem / $scale-factor);

        .expire-time {
          display: flex;
          align-items: center;

          .timer {
            margin-left: calc(0.2rem / $scale-factor);
            display: flex;
            align-items: center;
            width: calc(2rem / $scale-factor);
            height: calc(2rem / $scale-factor);
            border: 1 solid rgba(255, 255, 255, 0.2);
            background-color: rgba(255, 255, 255, 0.3);
            justify-content: center;
            border-radius: calc(1rem / $scale-factor);
            color: $white;
            font-size: calc(0.75rem / $scale-factor);
          }
        }
      }

      .button-container {
        display: flex;
        justify-content: center;
        button {
          padding: calc(0.5rem / $scale-factor) calc(0.75rem / $scale-factor);
          border: 1px solid rgba(255, 255, 255, 0.2);
          background-color: rgba(255, 255, 255, 0.3);
          border-radius: calc(0.5rem / $scale-factor);
          font-size: calc(0.875rem / $scale-factor);
        }
      }
    }
  }
}
