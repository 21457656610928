.topBar {
  height: 4rem;
  width: 100%;
  flex: 0.5 1;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.04);
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 899;

  .logo {
    background: url(https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/beeliked-logo-no-padding.png) center
      no-repeat;
    width: 100%;
    max-width: 8.5rem;
    height: 100%;
    max-height: 2.25rem;
    cursor: pointer;
  }
}

.container {
  padding: 1.5rem;
}
