@import '../../../../../../assets/scss/DesignSystemV2/variables';

.wrapper {
  .widget-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1.5rem;

    .widget-name {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    th,
    td {
      font-size: 0.75rem;
      padding: 1rem;
      border: none;
    }

    thead {
      th {
        background-color: $gray-4;
        color: $gray-56;
        text-align: left;
        border-bottom: 1px solid $gray-8;

        &.th-team-users {
          width: 10%;
        }

        &.th-promotion {
          width: 30%;
        }

        &.th-button-actions {
          width: 10%;
        }
      }
    }

    tbody {
      tr:nth-child(even) {
        background-color: $gray-4;
      }

      tr:nth-child(odd) {
        background-color: white;
      }

      .align-buttons {
        display: flex;
        justify-content: between;
        gap: 0.5rem;
      }
    }

    .align-right {
      text-align: right;
    }

    .align-center {
      text-align: center;
    }
  }
}

.thumbnail{
  max-width: 3.75rem;
  max-height: 3.75rem;
  margin-right: 1rem;
}

.text-wrapper{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}