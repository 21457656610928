.pagination {
  display: flex;
  justify-content: center;

  .page-item {
    .page-link {
      border: none;
      font-size: 12px;
      font-weight: 500;
      margin: 5px;
      color: $color-primary;
      border-radius: 3px;
      z-index: 0 !important;
      padding: 5px 15px;
    }

    &.active .page-link {
      background: $color-primary;
      color: $color-light;
    }

    &.disabled .page-link {
      color: $color-grey-light;
    }
  }
}
