@import "./../../../../../../../assets/scss/DesignSystemV2/variables";

.icon_html_page{
background-color: rgb(249, 249, 251);
height: 9.375rem;
border-radius: 0rem 0rem 0.5rem 0.5rem;
position: relative;
}

.icon_outline {
display: flex;
justify-content: center;
align-items: center;
height: 6.375rem;
width: 6.375rem;
position: absolute;
right: 1.25rem;
top: 1.25rem;
border-radius: 100%;
}

.close_button {
position: absolute;
border-radius: 100%;
display: flex;
justify-content: center;
align-items: center;
padding-bottom: 0.125rem;
top: 0.125rem;
right: 0;
height: 1.5rem;
width: 1.5rem;
background-color: $white;
}

.icon_container {
display: flex;
justify-content: center;
align-items: center;
border-radius: 100%;
height: 6.25rem;
width: 6.25rem;
}

.icon-image{
max-height: 3.75rem;
max-width: 3.75rem;
}

.gift_count {
padding: 0.125rem 0.5rem;
position: absolute;
top: 5.125rem;
border-radius: 0.25rem;
color: $white;
font-size: 0.75rem;
font-weight: 600;
display: flex;
align-items: center;
justify-content: center;
gap: 0.25rem;
}

.gift-icon{
font-size: 1rem;
}

.close-icon{
padding-top: 0.188rem;
font-size: 1rem;
}