.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 4.75rem;

  > section {
    display: grid;
    gap: 1.5rem;
  }

  .loading {
    position: relative;
    > div {
      padding: 2rem 0;
      position: relative;
    }
  }
}
