.input-inline-edit {
  .input-group {
    z-index: 990;
    input {
      border-radius: 3px;
    }

    .is-invalid {
      background-image: none !important;
    }

    .btns {
      position: absolute;
      right: 0;
      height: 34px;
    }
  }

  .append-icon-from-input {
    &.spinner-border {
      right: 10px;
    }
    position: absolute !important;
    z-index: 30;
    right: 30px;
    top: 9px;
    &.pointer {
      cursor: pointer;
    }
  }
  .error-border {
    border-color: $color-danger !important;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(229, 103, 118, 0.6);
    }
  }
  .error-message {
    color: $color-danger;
    font-style: italic;
    font-size: 14px;
    padding-top: 0.25rem;
    display: flex;
  }

  .button-copy-inline {
    background: transparent;
    padding: 8px;
    padding-top: 10px;
    text-decoration: none;
    border: none;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 30;
    transition: 0.3s;

    &.hover-button {
      margin-top: -7px;
    }
    &:hover {
      background-color: $color-grey;
      color: $color-light;
    }
  }
  .button-copy {
    margin-left: 0.5rem;
    background: transparent;
  }

  .copy-inline-button {
    display: flex;
    align-items: center;
    margin-top: -0.25rem;
    cursor: pointer;
    button {
      margin-left: 0.5rem;
      opacity: 0;
      transition: 0.3s;
    }
    &:hover {
      button {
        opacity: 1;
      }
    }
  }
}

.input-inline-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 980;
}
