@import '../../../../assets/scss/DesignSystemV2/variables';
@import '../text-mixins.scss';
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  padding: 0;
  color: inherit;

  @include text-colors;

  @include font-weight;

  @include hide-text;
}

.h1 {
  font-size: 2.5rem;
  line-height: 120%;
}

.h2 {
  font-size: 2rem;
  line-height: 120%;
}

.h3 {
  font-size: 1.5rem;
  line-height: 120%;
}

.h4 {
  font-size: 1.25rem;
  line-height: 120%;
}

.h5 {
  font-size: 1rem;
  line-height: 120%;
}

.h6 {
  font-size: 0.85rem;
  line-height: 120%;
}
