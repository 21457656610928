@import '../../../../../../assets/scss/DesignSystemV2/variables';

.container-section {
  .embed-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    gap: 1rem;
    margin-top: 1rem;

    .card {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 0.5rem;
      border: 1px solid $gray-8;
      background: $white;
      padding: 0.5rem;

      &.disabled {
        cursor: default;
        filter: grayscale(1);
      }

      p {
        font-weight: 600;
      }
    }
  }
}
