@media (max-width: 991px) {
  .order-6 {
    order: 6 !important;
  }
  .order-7 {
    order: 7 !important;
  }
  .order-8 {
    order: 8 !important;
  }
  .order-9 {
    order: 9 !important;
  }
  .order-10 {
    order: 10 !important;
  }
  .order-11 {
    order: 11 !important;
  }
  .order-12 {
    order: 12 !important;
  }
}

@media (min-width: 992px) {
  .order-lg-6 {
    order: 6 !important;
  }
  .order-lg-7 {
    order: 7 !important;
  }
  .order-lg-8 {
    order: 8 !important;
  }
  .order-lg-9 {
    order: 9 !important;
  }
  .order-lg-10 {
    order: 10 !important;
  }
  .order-lg-11 {
    order: 11 !important;
  }
  .order-lg-12 {
    order: 12 !important;
  }
}
