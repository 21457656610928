@import '../../../../../../assets/scss/DesignSystemV2/variables';

.container-section {
  > div > div {
    label {
      font-weight: 600;
      margin-bottom: 0.25rem;
      color: $gray-space;
      line-height: 1.5;
    }
  }
}
