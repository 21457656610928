.filters {
  .top {
    display: flex;
    float: right;
    margin-top: 15px;

    .btn {
      padding: 6px 20px 6px 20px;
    }

    .form-group {
      margin-right: 5px;
    }
  }

  .filter-list {
    display: flex;
    width: 100%;
    height: auto;
    min-height: 50px;
    background-color: $color-ghost;
    border-top: 1px solid $color-grey-light;
    border-bottom: 1px solid $color-grey-light;
    margin-bottom: 15px;
    padding: 15px;
  }
}
