@import '../../../../assets/scss/DesignSystemV2/variables';

.container-section {
  gap: 0 !important;

  .video-wrapper {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      text-align: center;
    }

    .video-content {
      width: 100%;

      .display-video {
        display: flex;
        justify-content: center;
        max-width: 45rem;
        margin: 0 auto;
      }
    }

    .create-new-widget {
      display: flex;
      justify-content: center;
    }

    .update-plan-content {
      margin-top: 2.5rem;
      > p,
      h1 {
        text-align: center;
      }
      > h1 {
        margin-bottom: 0.5rem;
      }
      > div {
        margin-top: 2rem;
        display: flex;
        justify-content: center;
      }
    }

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
