@import '../../assets/scss/DesignSystemV2/_variables.scss';

.notify-wrapper {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  margin-right: 1.5rem;

  @media (min-width: $sm) {
    position: relative;
  }

  &.selected {
    background: $primary-8;
    border-radius: 50%;
  }

  .empty-notifications {
    display: grid;
    padding: 1.5rem;
    gap: 0.5rem;
    place-items: center;
    > span {
      color: $gray-72;
      font-size: 0.85rem;
    }
  }

  > button {
    border: 0;
    background: transparent;
    display: grid;
    position: relative;

    > span {
      color: $gray-space;
    }
    &.notification-bell {
      animation: shake 1.5s 1 ease;
      transform-origin: top;
    }
  }

  .notifications {
    list-style: none;
    padding: 0;
    margin: 0;
    .each-notification {
      display: flex;
      gap: 0.5rem;
      padding: 1rem;
      border: 1px solid $gray-8;
      background: #fff;

      .status-wrapper {
        display: none;
      }

      span {
        font-size: 0.75rem;
        color: $gray-64;
        font-weight: 400;
      }

      &.unread {
        background-color: $primary-8;
        .status-wrapper {
          display: flex;
          align-items: flex-start;
          .status-circle {
            border-radius: 50%;
            background-color: $primary-pure;
            width: 1rem;
            height: 1rem;
          }
        }
      }
      main {
        h3 {
          font-size: 0.85rem;
          font-weight: 600;
          color: $gray-space;
        }
        p {
          font-size: 0.75rem;
          font-weight: 400;
          color: $gray-space;
          margin-bottom: 0.5rem;
        }
        span {
          font-size: 0.75rem;
          color: $gray-32;
        }
      }
      .button-wrapper {
        span {
          font-size: 1rem;
        }
      }
    }
  }
}
