.sign {
  width: 100%;
  max-width: 450px;
  margin: 95px auto 0 auto;
  border-radius: 10px;
  h1 {
    margin-left: 24px;
  }
  .no-account {
    margin-top: 36px;
    text-align: center;

    a {
      text-decoration: none;
    }
  }
}
