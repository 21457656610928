@import '../../../../../../assets/scss/DesignSystemV2/variables';
.testimonials-wrapper {
  height: 100%;
  display: flex;
  position: absolute;
  transition: 0.5s;
  width: 100%;

  img:nth-child(2) {
    transition: 0.5s;
    transition-delay: 0.25s;
  }
  aside {
    > p {
      transition: 0.5s;
      transition-delay: 0.25s;
    }
    > div {
      transition: 0.5s;
      transition-delay: 0.5s;
    }
  }

  .wrapper-buttons {
    display: flex;
    gap: 0.5rem;
    width: fit-content;
    right: 3rem;
    bottom: 4.25rem;
    position: absolute;
    z-index: 1;

    > button {
      background-color: rgba(255, 255, 255, 0.36);
      border-radius: 50%;
      height: 1.5rem;
      width: 1.5rem;
      transition: 0.5s;

      &:disabled {
        pointer-events: none;
      }
      background: rgba(255, 255, 255, 0.15) !important;

      &:hover {
        background: rgba(255, 255, 255, 0.15) !important;
      }

      > span {
        color: #fff;
      }
    }
  }
}

.fade-in {
  animation: fade-in ease;
  opacity: 1;

  > img:nth-child(2) {
    opacity: 1;
    height: 100%;
  }

  > aside {
    > div:first-child {
      opacity: 1;
      transform: translateY(0);
    }

    > div:last-child {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
.fade-out {
  opacity: 0;
  animation: fade-out ease;

  > img:nth-child(2) {
    opacity: 0;
    height: 0;
  }

  aside {
    > div:first-child {
      opacity: 0;
      transform: translateY(2rem);
    }
    > div:last-child {
      opacity: 0;
      transform: translateY(-2rem);
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
