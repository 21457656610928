@import '../../../assets/scss/DesignSystemV2/variables';
.icon-wrapper {
  line-height: 0;
  border-radius: 50%;
  &.lg {
    padding: 1.25rem;
    max-width: 5.25rem;

    span {
      font-size: 2.75rem;
    }
  }
  &.md {
    max-width: 4.25rem;
    padding: 1rem;

    span {
      font-size: 2.25rem;
    }
  }
  &.sm {
    max-width: 3rem;
    padding: 0.75rem;

    span {
      font-size: 1.5rem;
    }
  }

  &.primary {
    background: $primary-8;
    color: $primary-pure;
    animation: pulsePrimary 1.5s 1s infinite, scaleUp 0.75s;
  }
  &.green {
    background: $green-status-8;
    color: $green-status;
    animation: pulseGreen 1.5s 1s infinite, scaleUp 0.75s;
  }
  &.gray {
    background: $gray-8;
    color: $gray-40;
    animation: pulseGray 1.5s 1s infinite, scaleUp 0.75s;
  }
}

@keyframes pulsePrimary {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(35, 150, 251, 1);
    box-shadow: 0 0 0 0 rgba(35, 150, 251, 1);
  }

  50% {
    -moz-box-shadow: 0 0 0 10px rgba(35, 150, 251, 0);
    box-shadow: 0 0 0 10px rgba(35, 150, 251, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(35, 150, 251, 0);
    box-shadow: 0 0 0 0 rgba(35, 150, 251, 0);
  }
}
@keyframes pulseGreen {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(24, 220, 129, 1);
    box-shadow: 0 0 0 0 rgba(24, 220, 129, 1);
  }

  50% {
    -moz-box-shadow: 0 0 0 10px rgba(24, 220, 129, 0);
    box-shadow: 0 0 0 10px rgba(24, 220, 129, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(24, 220, 129, 0);
    box-shadow: 0 0 0 0 rgba(24, 220, 129, 0);
  }
}
@keyframes pulseGray {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(243, 244, 246, 1);
    box-shadow: 0 0 0 0 rgba(243, 244, 246, 1);
  }

  50% {
    -moz-box-shadow: 0 0 0 10px rgba(243, 244, 246, 0);
    box-shadow: 0 0 0 10px rgba(243, 244, 246, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(243, 244, 246, 0);
    box-shadow: 0 0 0 0 rgba(243, 244, 246, 0);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
