@import '../../../../assets/scss/DesignSystemV2/_variables.scss';

.form-wrapper {
  position: relative;

  &.right {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    header {
      padding-bottom: 0;
    }
  }

  &.fullwidth {
    width: 100%;
  }

  &.sm {
    header {
      label {
        color: $gray-80 !important;
        font-size: 0.75rem !important;
      }
    }
    .input-wrapper {
      input {
        padding: 0.5rem !important;
        font-size: 0.75rem !important;

        &[type='color'] {
          padding: 0 !important;
          height: 2.3rem;
        }
      }
      span {
        font-size: 1rem !important;
      }
    }
    .helper-text {
      font-size: 0.75rem !important;
    }
  }
  &.danger {
    .input-wrapper {
      input {
        border-color: $red-block;
      }
      > span {
        color: $red-block;
      }
    }
    .helper-text {
      color: $red-block;
    }
  }
  &.green {
    .input-wrapper {
      input {
        border-color: $green-status;
      }
      > span {
        color: $green-status;
      }
    }
    .helper-text {
      color: $green-status;
    }
  }
  &:has(div input[disabled]) {
    label {
      color: $gray-32;
    }
    input {
      background-color: $gray-8;
      border-color: $gray-8;
    }
    input,
    input::placeholder {
      border: 0;
      color: $gray-16;
      font-size: 0.85rem;
      font-weight: 400;
      line-height: 150%;
    }
    button {
      pointer-events: none;
    }
    span {
      color: $gray-16;
    }
    .helper-text {
      color: $gray-32;
    }
  }
  header {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    label {
      font-weight: 600;
      font-size: 0.85rem;
    }
    padding-bottom: 0.25rem;
  }

  .input-wrapper {
    position: relative;
    .input {
      display: block;
      width: 100%;
      padding: 0.5rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      color: $gray-40;
      border: 1px solid $gray-8;
      background-color: #fff;
      cursor: pointer;
      border-radius: 0.5rem;
      transition: 0.25s color;
      outline-color: $blue-cloud;
      transition: 0.5s;

      &::placeholder {
        font-size: 0.85rem;
        font-weight: 400;
        line-height: 150%;
        color: $gray-40;
      }
      &:disabled {
        pointer-events: none;
        background-color: $gray-8;
      }

      &:hover {
        background: $gray-8;
      }

      &:focus-visible {
        color: $gray-space;
        outline-color: $blue-cloud;
      }

      &:is([type='color']) {
        height: 2.625rem;
      }
    }

    input[type='color'] {
      overflow: hidden;
      padding: 0;
      -webkit-appearance: none;
    }

    input[type='color']::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    input[type='color']::-webkit-color-swatch {
      border: none;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    input[type='date']::-webkit-inner-spin-button,
    input[type='date']::-webkit-calendar-picker-indicator {
      opacity: 0;
      -webkit-appearance: none;
    }

    span {
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 0 0.5rem 0.5rem 0;
      border-left: 0;
      height: 100%;
      width: calc(2.5rem - 4px);
      display: grid;
      place-items: center;
      background-color: inherit;
      color: $gray-40;
      cursor: pointer;

      &.disabled {
        pointer-events: none;
      }
    }
  }

  .buttons-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    border-left: 1px solid $gray-8;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    button {
      padding: 0;
      height: 50%;
      > div {
        height: 100%;
      }
    }
    main {
      position: absolute !important;
      right: 0;
      top: 0;
    }
  }

  .helper-text {
    font-size: 0.85rem;
    color: $gray-56;
  }
}
