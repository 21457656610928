/////////// COLORS ///////////

//// PRIMARY PALLETTE
// PRIMARY - AZURE
$color-primary: #3e96f6;
$color-primary-dark: #2883e7;
$color-primary-light: #9ecafa;
$color-primary-light-2: #cee4fc;

// DARK - NOIR
$color-dark: #3a3f51;

// LIGHT - WHITE
$color-light: #ffffff;

//// SECONDARY PALLETTE
// SUCCESS - GEKO
$color-success: #8fc778;
$color-success-dark: #7ab163;
$color-success-light: #c6e2bb;
$color-success-light-2: #e3f1dd;

// DANGER - CORAL
$color-danger: #e56776;
$color-danger-dark: #f3707f;
$color-danger-light: #f1b2ba;
$color-danger-light-2: #f8d9dc;

// WARNING - TOPAZ
$color-warning: #facb38;
$color-warning-dark: #e3bc42;
$color-warning-light: #fce49b;
$color-warning-light-2: #fdf2cd;

// INFO - RIO
$color-info: #54aebb;
$color-info-dark: #4295a1;
$color-info-light: #a9d6dc;
$color-info-light-2: #d4eaee;

// PINK
$color-pink: #ea6dc0;
$color-pink-dark: #d464af;
$color-pink-light: #f4b5df;
$color-pink-light-2: #f9daef;

// COLOMBIA
$color-colombia: #a6e3fc;
$color-colombia-dark: #8ed2ee;
$color-colombia-light: #d2f0fc;
$color-colombia-light-2: #e8f7fe;

// SALMON
$color-salmon: #e7a283;
$color-salmon-dark: #d3957a;
$color-salmon-light: #f2d0c0;
$color-salmon-light-2: #f9e7e0;

//// NEUTRAL PALLETTE
$color-grey: #8a9bb4;
$color-grey-dark: #506480;
$color-grey-light: #d0dbe9;
$color-grey-light-2: #f8fafe;

$color-highlights: #f2f6fe;

$color-ghost: #f5f9fc;

$color-black: #21242a;
