.dropdown-more-actions {
  .dropdown-menu {
    background: $color-light;
    border: solid 1px $color-highlights;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    height: auto;
    border-radius: 5px;
    padding: 16px 16px;
    width: 100%;
    min-width: 220px;
    max-width: 350px;
    li {
      border-bottom: solid 1px $color-highlights;
      padding-left: 8px;
      &:hover {
        background: $color-primary-light-2;
      }
      a {
        font-size: 13px;
        text-decoration: none;
        color: $color-dark;
        width: 100%;
        display: block;
        padding-top: 12px;
        padding-bottom: 12px;
        transition: all 0.5s;
      }
      a,
      button {
        display: flex;
        align-items: center;
        span {
          color: #8a9bb4;
          margin-right: 8px;
          min-width: 16px;
          font-size: 1rem;
        }
      }
      i {
        min-width: 16px;
      }
      .btn {
        width: 100%;
        text-align: left;
        border: none;
        font-size: 13px;
        i {
          margin-right: 8px;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .btn-more-action {
        color: $color-dark;
        background-color: transparent;
        border-color: transparent !important;
        padding-left: 0 !important;
      }
      &:last-child {
        border-bottom: none;
      }
      &.btn-delete-promotion {
        border: 1px solid $color-danger;
        border-radius: 3px;
        padding-left: 0;
        .btn {
          width: 100%;
          color: $color-danger;
          background-color: $color-light;
          text-align: left;
          padding-left: 15px !important;
          &:focus {
            box-shadow: none;
          }
          &:hover {
            background-color: $color-danger;
            color: $color-light;
          }
          &:disabled {
            padding-left: 10px;
          }
        }
      }
      &:last-child + .btn-delete {
        .btn {
          &:hover {
            background: none !important;
          }
        }
      }
    }
  }
}
