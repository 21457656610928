.tabs {
  margin-top: 24px;
  .nav-tabs {
    border-bottom: none;
    width: 100%;

    .nav-link {
      font-size: 16px;
      font-weight: 400;
      padding: 10px 10px;
      border-radius: 0px;
      background-color: #fafafa;
      color: $color-grey;
      text-align: center;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        background-color: $color-light;
        color: $color-dark;
        border: none;
        border-top: solid 5px $color-primary;
      }

      &.active a {
        color: $color-light;
      }

      &.active a:hover {
        color: $color-grey;
        text-decoration: none;
        border: none;
      }
    }
  }
  .tab-content {
    padding-top: 40px;
    border-radius: 0;
  }
}
