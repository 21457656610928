.form-check-input {
  clear: left;
}

.form-switch {
  cursor: pointer;

  &:focus {
    box-shadow: unset;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
}

.form-switch.form-switch-sm {
  margin-bottom: 0.5rem;
}

.form-switch.form-switch-sm.form-check-input {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.form-switch.form-switch-md {
  margin-bottom: 1rem;
}

.form-switch.form-switch-md.form-check-input {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.form-switch.form-switch-lg {
  margin-bottom: 1.5rem;
}

.form-switch.form-switch-lg.form-check-input {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.form-switch.form-switch-xl {
  margin-bottom: 2rem;
}

.form-switch.form-switch-xl.form-check-input {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}
