@import '../../../assets/scss/DesignSystemV2/variables';
.tags {
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  width: fit-content;
  height: fit-content;
  justify-content: space-between;
  gap: 0.25rem;
  transition: 0.25s;
  border: 1px solid transparent;
  cursor: default;

  &.sm {
    padding: 0.125rem 0.5rem;
  }
  &.md {
    padding: 0.25rem 0.5rem;
  }

  button {
    padding: 0;
    background: transparent;
    border: 0;
    display: grid;
    place-items: center;
    cursor: pointer;
    color: inherit;

    span {
      font-size: 1rem;
      font-weight: 600;
      opacity: 0.65;
    }
  }

  &.primary {
    background: $primary-8;
    color: $primary-hover;

    &:hover:not(.disabled-hover) {
      border-color: $primary-hover;
    }

    button span {
      color: $primary-hover;
    }
  }
  &.primary-dark {
    background: $primary-pure;
    color: #fff;

    &:hover:not(.disabled-hover) {
      background-color: $primary-hover;
    }

    button span {
      color: #fff;
    }
  }
  &.gray {
    background: $gray-8;
    color: $gray-64;

    &:hover:not(.disabled-hover) {
      border-color: $gray-64;
    }

    button span {
      color: $gray-64;
    }
  }
  &.gray-dark {
    background: $gray-16;
    color: $gray-64;

    &:hover:not(.disabled-hover) {
      background-color: $gray-24;
    }

    button span {
      color: $gray-64;
    }
  }
  &.disabled {
    pointer-events: none !important;
    background: $white-space;
    color: $gray-32;

    button span {
      color: $gray-32;
    }
  }
}
