.tooltip > .arrow {
  background-color: transparent;
}

.tooltip > .tooltip-inner {
  border-radius: 5px;
  text-align: left;
  color: $color-light;
  background-color: $color-dark;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15), 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.bs-tooltip-top,
.bs-tooltip-right,
.bs-tooltip-left,
.bs-tooltip-bottom {
  background-color: transparent;
  opacity: 1 !important;
}

.tooltip.bs-tooltip-top .arrow:before,
.tooltip.bs-tooltip-bottom .arrow:before {
  border-top-color: $color-dark;
  border-right-color: transparent;
  border-bottom-color: $color-dark;
  border-left-color: transparent;
}

.tooltip.bs-tooltip-right .arrow:before,
.tooltip.bs-tooltip-left .arrow:before {
  border-top-color: transparent;
  border-right-color: $color-dark;
  border-bottom-color: transparent;
  border-left-color: $color-dark;
}

.popover {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 5px;
  background-color: $color-dark;
  color: $color-light;
  padding: 20px 24px 5px 24px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.popover-header {
  color: $color-light;
  background-color: $color-primary;
  border: 1px solid $color-primary;
}

.popover-body {
  color: $color-light;
  border: none;
  border-radius: 5px;
  padding: 0 0 15px 0;
  .message {
    display: block;
    margin-bottom: 10px;
  }
  .btn {
    min-width: 65px;
    text-align: center;
  }
  figure img {
    margin: 0;
    width: 100%;
  }
}

.popover-btns {
  width: 100%;
  text-align: right;
}

.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after,
.popover.bs-popover-top .arrow:before,
.popover.bs-popover-top .arrow::after {
  bottom: 1px;
  border-top-color: $color-dark;
  background: transparent;
}

.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after,
.popover.bs-popover-bottom .arrow:before,
.popover.bs-popover-bottom .arrow::after {
  top: 1px;
  border-bottom-color: $color-dark;
  background: transparent;
}

.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after,
.popover.bs-popover-right .arrow:before,
.popover.bs-popover-right .arrow::after {
  left: 1px;
  border-right-color: $color-dark;
  background: transparent;
}

.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::after,
.popover.bs-popover-left .arrow:before,
.popover.bs-popover-left .arrow:after {
  right: 1px;
  border-left-color: $color-dark;
  background: transparent;
}

.popover-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1060;
}
