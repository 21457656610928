@import '../../../../../assets/scss/DesignSystemV2/_variables.scss';

.coupons-wrapper {
  display: grid;
  gap: 1rem;
  padding: 1rem;
  height: 100%;
  grid-template-columns: 1fr;
  place-content: flex-start;

  .coupon-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    div:first-child {
      width: 100%;
      display: flex;
      justify-content: space-between;
      span:first-child {
        font-size: 0.85rem;
        color: $gray-space;
        font-weight: 600;
      }
      span:last-child {
        font-size: 0.85rem;
        color: $gray-64;
        font-weight: 400;
      }
    }

    div:last-child {
      width: 100%;
      height: 0.25rem;
      background: #ccc;
      .progress-bar {
        background: $primary-24;

        &.low-quantity {
          background: $red-block;
        }
      }
    }
  }

  .img-wrapper {
    height: 11.75rem;
    overflow: hidden;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .thumbnail {
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      z-index: 1;
      justify-content: center;
      transition: opacity 0.25s, 0.5s visibility;

      &.desactive {
        opacity: 0;
        visibility: hidden;
      }

      button {
        width: 3rem;
        height: 3rem;
        border: 0;
        border-radius: 50%;
        position: absolute;
        z-index: 2;

        margin: 0;
        padding: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          color: #fff;
          font-size: 4.5rem;
          background-color: $yellow-bee;
        }

        &::before {
          content: '';
          position: absolute;
          width: 3.75rem;
          height: 3.75rem;
          background-color: $yellow-bee;
          z-index: -1;
          border-radius: 50%;
        }
      }
    }
    .thumbnail,
    img,
    video {
      width: 100%;
      border-radius: 0.5rem;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .main-content {
    max-width: fit-content;

    h3 {
      font-size: 0.85rem;
      font-weight: 600;
      color: $gray-space;
    }
    span {
      font-size: 0.75rem;
      font-weight: 400;
      color: $gray-72;
    }
  }
  .buttons-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(11.25rem, 1fr));
    place-items: center;
  }
}
