/////////// FORMS //////////

.form-group,
.input-group {
  width: 100%;
  z-index: 0;
  label {
    font-size: 14px;
    color: $color-grey;
    line-height: 10px;
  }

  // @TODO: check why it was commented
  input,
  select,
  .btn {
    background-color: $color-light;
    border: solid 1px $color-grey-light;
    font-size: 14px;

    &.text-security {
      -webkit-text-security: disc;
    }
  }

  input::placeholder,
  select::placeholder {
    font-size: 14px;
    color: $color-grey-light;
  }

  .form-text {
    font-style: italic;
    color: $color-grey;
    display: block;
  }

  .input-group-text {
    background-color: $color-ghost;
    border: solid 1px $color-grey-light;
    border-left: none;
    color: $color-dark;
    border-radius: 3px;
  }

  .icon-tooltip {
    float: right;
    color: $color-grey;
  }

  ~ .success {
    input:focus {
      border-color: $color-success;
      box-shadow: inset 0 1px 1px rgba(79, 152, 101, 0.1), 0 0 3px rgba(79, 152, 101, 0.25);
    }

    i {
      color: $color-success;
    }

    small {
      color: $color-success;
    }
  }

  ~ .danger {
    input:focus {
      border-color: $color-danger;
      box-shadow: inset 0 1px 1px rgba(255, 0, 0, 0.075), 0 0 3px rgba(255, 0, 0, 0.6);
    }

    i {
      color: $color-danger;
    }

    small {
      color: $color-danger;
    }
  }

  .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  }

  .bootstrap-select {
    .dropdown-toggle {
      background-color: $color-ghost;
      border: none;
      color: $color-dark;
      border-radius: 3px;
      padding-left: 10px;
    }

    .dropdown-menu li {
      position: relative;
      margin-bottom: 10px;
    }
  }
}
.form-check {
  &.danger input {
    background: $color-danger-light-2;
    border: solid 1px $color-danger;

    &:checked {
      background: $color-danger;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
  }

  &.danger input[type='radio'] {
    background: $color-danger-light-2;
    border: solid 1px $color-danger;

    &:checked {
      background: $color-danger;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
    }
  }
}
