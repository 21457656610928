@import '../../../../assets/scss/DesignSystemV2/variables';
.wrapper-filter {
  padding: 1.25rem 1rem;
  border-bottom: 1px solid $gray-8;
  display: grid;
  gap: 1rem;
  > header {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: center;

    .wrapper-manage-filters {
      list-style: none;
      padding: 0.75rem;
    }

    .wrapper-search-input {
      display: flex;
      width: 100%;

      > div:last-child {
        flex: 1;
      }
      > button {
        background: $gray-4;
        border: 1px solid;
        border-color: $gray-8;
        padding: 0.6rem 0.75rem !important;

        span {
          color: $gray-40;
          font-weight: 400;
        }

        &:hover {
          background: $gray-8;
        }
      }
      input {
        border-color: $gray-8;
      }
    }
  }

  > main {
    padding: 1rem;
    background: $blue-cloud-8;
    border: 1px solid $blue-cloud;
    border-radius: 0.5rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;

    header {
      gap: 1rem;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    }
  }
}
.wrapper-result {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  > header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      gap: 0.5rem;
    }
  }
  > footer {
    display: flex;
    justify-content: center;
  }

  .custom-icon {
    height: 1rem;
    width: 1rem;
    margin: 0;
  }
}

@media (min-width: $sm) {
  .wrapper-search-input {
    button {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    input {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-left: 0 !important;
    }
  }
}

@media (max-width: $md) {
  .wrapper-filter {
    header {
      flex-direction: column !important;
      align-items: flex-end;
    }
  }
  .wrapper-result {
    > header {
      > div:last-child {
        button div main {
          display: none;
        }
      }
    }
  }
}

@media (max-width: $sm) {
  .wrapper-search-input {
    button {
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
    input {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-color: transparent !important;
    }
  }

  .wrapper-filter {
    header {
      > div {
        &:first-child {
          flex-direction: column-reverse;
        }
        > * {
          width: 100%;
        }
      }
    }
  }
}

.empty-page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;

  h2 {
    margin-top: 3.125rem;
  }

  img {
    width: 15.625rem;
    margin-bottom: 1.5rem;
  }
}

.modal-expiry-result {
  gap: 1rem;
  display: grid;
}

.modal-export {
  display: grid;
  gap: 1rem;
  place-items: center;

  div {
    p {
      margin-bottom: 0;
      text-align: center;
    }
  }
}

.send-email-modal {
  position: absolute;
  top: 15%;
}

.modal-container {
  position: absolute;
  > main {
    background-color: $blue-cloud-8;
    border-radius: 1rem;
  }
  .loading > div {
    position: relative;
  }
}
