.advanced-options {
  margin-top: 0.5rem;

  > button > div {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .container-card {
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #f3f4f6;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(10, 13, 20, 0.04);
    padding: 1rem;
    display: none;

    > div:first-child {
      display: flex;
      justify-content: space-between;
    }

    &.show {
      display: block;
    }

    .switch-group {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      p {
        margin-left: 0.5rem;
      }
    }

    .days-to-expire-input {
      margin-top: 1rem;
      display: none;
      input {
        width: 9.25rem;
      }

      &.show {
        display: block;
      }
    }
  }
}
