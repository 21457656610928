img.thumb,
.placeholder {
  width: 84px;
  background-color: $color-highlights;
  border: 1px solid $color-grey-light;
  padding: 0 10px;
  border-radius: 3px;
}

.placeholder {
  height: 84px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  color: $color-grey-dark;

  i {
    font-size: 25px;
  }
}
