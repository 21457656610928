@import '../../../../../../../assets/scss/DesignSystemV2/variables';
@import '../../../../../../../assets/scss/DesignSystemV2/Mixins/scrollbar';

.wrapper-upload {
  p:nth-of-type(2) {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .upload {
    border: 1px dashed $gray-8;
    border-radius: 0.5rem;
    padding: 6rem 1rem;
    text-align: center;

    &.loading {
      padding-top: 4.82rem;
      padding-bottom: 4.82rem;
    }

    p {
      margin-top: 0.625rem;
      display: block;
      &.file-name {
        color: $primary-pure;
      }
      a {
        text-decoration: none;
      }
    }

    .center-button {
      display: flex;
      justify-content: center;
      .button-upload {
        cursor: pointer;
        border-radius: 0.5rem;
        border: 1px solid $primary-24;
        display: flex;
        padding: 0.5rem 0.75rem;
        justify-content: center;
        align-items: center;
        font-size: 0.875rem;

        &:hover {
          color: $primary-hover;
          border: 1px solid $primary-24;
          background: $primary-8;
        }

        span {
          margin-right: 0.5rem;
          color: $primary-pure;
        }
      }
    }

    .upload-loading > div {
      position: relative;
      padding: 0;
    }
  }

  .header-content {
    display: flex;
    justify-content: space-between;
  }
}

.content-table {
  overflow-y: hidden;
  overflow-x: auto;
  @include scrollbar();

  .spinner > div {
    position: relative;
  }

  .table {
    width: 100%;
    margin: 0;

    thead {
      tr {
        background: $gray-8;
        th {
          padding-top: 1rem;
          padding-bottom: 1rem;
          padding-left: 1rem;
          color: #67718e;
          font-size: 0.75rem;
          font-weight: 600;
        }
      }
    }

    tbody {
      border-top: unset;
      background-color: $white;
      tr {
        td {
          padding-left: 1rem;
          border-bottom: unset;
          vertical-align: top;
          &:last-child {
            padding-right: 1rem;
          }
          .form-group-label {
            padding-top: 1rem;
            font-weight: 600;
          }
          .form-group {
            padding-bottom: 1rem;
            min-width: 12.5rem;

            > .uid-field {
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.edit-fields-container {
  display: grid;
  padding: 1.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid $gray-8;
  background: rgba(249, 249, 251, 0.8);

  .content-file-name {
    margin-bottom: 1rem;
    width: 30%;
  }
}

.paragraph-content {
  margin-top: 1rem;
  margin-bottom: 1rem;
  p:first-child {
    margin-bottom: 1rem;
  }
}
