@import '../../../../assets/css/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  nav {
    width: 100%;
    border-bottom: 1px solid $gray-16;
    display: flex;
    gap: 1rem;

    a {
      margin-bottom: -1px;
      border-bottom: 2px solid transparent;
      transition: 0.5s;
      border-radius: 0 !important;
      &.active {
        border-bottom-color: $primary-pure;
        span:nth-child(2) {
          color: $gray-space;
        }
      }
      &:not(.active) {
        color: $gray-40;
      }
    }
  }

  > section {
    background-color: #fff;
    padding: 1rem;
    display: grid;
    gap: 1.5rem;
    border-radius: 1rem;
    > header {
      display: flex;
      gap: 0.5rem;
      justify-content: space-between;
      > div:first-child {
        max-width: 20rem;
      }
      > div:last-child {
        display: flex;
        gap: 0.5rem;
      }

      div > .sort-select {
        ul {
          min-width: 100%;
          width: auto;
          li {
            text-wrap: nowrap;
          }
        }
      }

      button.active {
        background-color: $primary-8;
      }
    }

    > main {
      display: grid;
      gap: 1rem;

      &.grid {
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
      }

      @media (min-width: $xxl) {
        &.grid {
          grid-template-columns: repeat(3, 1fr);
        }
      }
      @media (min-width: $fullHD) {
        &.grid {
          grid-template-columns: repeat(4, 1fr);
        }
      }

      &.no-grid {
        grid-template-columns: unset !important;
      }
    }

    > footer {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
