@import './../../../assets/scss/DesignSystemV2/variables';

.hero_section{
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
    justify-content: center;
    align-items: flex-start;
    gap: 0rem;
    }

.heading_text{
    font-size: 1.5rem;
    font-weight: 700;
}

.bold{
    font-weight: bold;
}

.hide_hero_section{
    display: none;
}

.divider-plugin-header{
    margin: -1rem 1.25rem 0rem 1.25rem;
    background-color: $gray-17;
    height: 1px;
    }