@import '../../../../../../assets/scss/DesignSystemV2/_variables';
.form-wrapper {
  display: grid;
  place-content: center;
  place-items: center;
  gap: 1rem;
  grid-template-columns: 1fr;
  padding: 4rem;
  height: 100%;
  width: 100%;

  .wrapper-paragraph {
    display: grid;
    place-content: center;
    place-items: center;
    gap: 0.25rem;
  }
  form {
    width: 100%;
  }
  .wrapper-verify-code {
    display: flex;
    margin-top: 1rem;
    gap: 0.5rem;

    input {
      text-align: center;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  .error-message {
    margin-bottom: 1.5rem;
    color: $red-block;
    display: block;
  }
  .wrapper-buttons {
    display: grid;
    gap: 0.5rem;
    margin-bottom: 1.625rem;
  }
  .resend-text-wrapper {
    display: flex;
    gap: 0.25rem;

    a {
      text-decoration: none;
      font-size: 0.85rem;
      font-weight: 600;
    }
  }
}
