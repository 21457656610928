@import './_variables.scss';
.hidden {
  display: none !important;
}
.show {
  display: block;
}
.flex {
  display: flex;
}
@media (min-width: $xs) and (max-width: $sm) {
  .hidden-only-xs {
    display: none !important;
  }
  .show-only-xs {
    display: block !important;
  }
  .flex-only-xs {
    display: flex !important;
  }
  .hidden-down-sm {
    display: none !important;
  }
  .hidden-down-md {
    display: none !important;
  }
  .hidden-down-lg {
    display: none !important;
  }
  .hidden-down-xl {
    display: none !important;
  }
}
@media (min-width: $sm) and (max-width: $md) {
  .hidden-only-sm {
    display: none !important;
  }
  .hidden-down-sm {
    display: none !important;
  }
  .hidden-down-md {
    display: none !important;
  }
  .hidden-down-lg {
    display: none !important;
  }
  .hidden-down-xl {
    display: none !important;
  }
  .hidden-up-sm {
    display: none !important;
  }

  .show-only-sm {
    display: block !important;
  }
  .flex-only-sm {
    display: flex !important;
  }
  .show-up-sm {
    display: block !important;
  }
  .flex-up-sm {
    display: flex !important;
  }
}
@media (min-width: $md) and (max-width: $lg) {
  .hidden-only-md {
    display: none !important;
  }
  .show-only-md {
    display: block !important;
  }
  .flex-only-md {
    display: flex !important;
  }
  .hidden-down-md {
    display: none !important;
  }
  .hidden-down-lg {
    display: none !important;
  }
  .hidden-down-xl {
    display: none !important;
  }
  .hidden-up-sm {
    display: none !important;
  }
  .hidden-up-md {
    display: none !important;
  }

  .show-only-md {
    display: block !important;
  }
  .flex-only-md {
    display: flex !important;
  }
  .show-up-sm {
    display: block !important;
  }
  .flex-up-sm {
    display: flex !important;
  }
  .show-up-md {
    display: block !important;
  }
  .flex-up-md {
    display: flex !important;
  }
}
@media (min-width: $lg) and (max-width: $xl) {
  .hidden-only-lg {
    display: none !important;
  }
  .show-only-lg {
    display: block !important;
  }
  .flex-only-lg {
    display: flex !important;
  }
  .hidden-down-lg {
    display: none !important;
  }
  .hidden-down-xl {
    display: none !important;
  }
  .hidden-up-sm {
    display: none !important;
  }
  .hidden-up-md {
    display: none !important;
  }
  .hidden-up-lg {
    display: none !important;
  }

  .show-only-lg {
    display: block !important;
  }
  .flex-only-lg {
    display: flex !important;
  }
  .show-up-sm {
    display: block !important;
  }
  .flex-up-sm {
    display: flex !important;
  }
  .show-up-md {
    display: block !important;
  }
  .flex-up-md {
    display: flex !important;
  }
  .show-up-lg {
    display: block !important;
  }
  .flex-up-lg {
    display: flex !important;
  }
}
@media (min-width: $xl) and (max-width: $xxl) {
  .hidden-only-xl {
    display: none !important;
  }
  .show-only-xl {
    display: block !important;
  }
  .flex-only-xl {
    display: flex !important;
  }
  .hidden-down-xl {
    display: none !important;
  }
  .hidden-down-xxl {
    display: none !important;
  }
  .hidden-up-sm {
    display: none !important;
  }
  .hidden-up-md {
    display: none !important;
  }
  .hidden-up-lg {
    display: none !important;
  }
  .hidden-up-xl {
    display: none !important;
  }

  .show-only-xl {
    display: block !important;
  }
  .flex-only-xl {
    display: flex !important;
  }
  .show-up-sm {
    display: block !important;
  }
  .flex-up-sm {
    display: flex !important;
  }
  .show-up-md {
    display: block !important;
  }
  .flex-up-md {
    display: flex !important;
  }
  .show-up-lg {
    display: block !important;
  }
  .flex-up-lg {
    display: flex !important;
  }
  .show-up-xl {
    display: block !important;
  }
  .flex-up-xl {
    display: flex !important;
  }
}
@media (min-width: 1400px) {
  .hidden-only-xxl {
    display: none !important;
  }
  .show-only-xxl {
    display: block !important;
  }
  .flex-only-xxl {
    display: flex !important;
  }
  .hidden-up-sm {
    display: none !important;
  }
  .hidden-up-md {
    display: none !important;
  }
  .hidden-up-lg {
    display: none !important;
  }
  .hidden-up-xl {
    display: none !important;
  }
}
