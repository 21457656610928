.popover-light {
  background: $color-light;
  color: rgba(0, 0, 0, 1);
  padding: 0;
  z-index: 99 !important;

  .popover-arrow:before {
    border-bottom-color: rgba(0, 0, 0, 0.05) !important;
    border-top-color: rgba(0, 0, 0, 0.05) !important;
  }
  .popover-arrow:after {
    border-top-color: $color-ghost !important;
    border-left-color: transparent !important;
    border-bottom-color: $color-light !important;
  }
  .wrapper-popover {
    list-style: none;
    padding: 15px 15px 0 15px;
    margin-bottom: 0;
    min-width: 280px;

    li {
      border-bottom: solid 2px $color-ghost;
      & + li {
        padding-top: 1rem;
      }
      &:last-child {
        border-bottom: solid 0px $color-ghost !important;
      }
    }
    strong {
      font-size: 12px;
      font-weight: 600;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    p {
      font-size: 12px;
      color: #8a9bb4;
      margin-top: 4px;
      margin-bottom: 0;
    }
    .footer-file {
      display: flex;
      justify-content: space-between;
      padding-bottom: 1rem;
      padding-top: 0.5rem;
    }
  }
  .footer-popover {
    padding: 15px;
    background: $color-ghost;
    display: flex;
    align-items: center;
    min-height: 52px;
  }
}
.badge {
  border: 0;
  padding: 0.25rem 0.5rem;
  min-width: 40px;

  &.success {
    background: rgba(143, 199, 120, 0.3);
  }
  &.error {
    background: rgba(229, 103, 118, 0.3);
  }
}
.iconImported {
  width: 10px;
  height: 10px;

  &.second {
    margin-left: -0.25rem;
  }

  &.success {
    color: rgba(143, 199, 120, 1);
  }
  &.warning {
    color: rgba(250, 203, 56, 1);
  }
  &.error {
    color: rgba(229, 103, 118, 1);
  }
}
