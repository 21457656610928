@import '../../../../assets/scss/DesignSystemV2/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0 1rem 0;
  gap: 1.25rem;
  border-radius: 1rem;
  background: $white;
  justify-content: center;
  overflow: hidden;

  .filters-container {
    border-bottom: 1px solid $gray-8;
    padding: 0 1rem 1.25rem 1rem;

    .filters-container-header {
      display: flex;
      justify-content: space-between;

      > div {
        display: flex;
        gap: 1rem;
      }
    }

    .filters-container-fields {
      margin-top: 1rem;
      border-radius: 0.5rem;
      border: 1px solid $blue-cloud;
      background: $blue-cloud-8;
      padding: 1.25rem 1rem;
      grid-gap: 1rem;
      gap: 1rem;
      display: flex;
      flex-direction: column;
      > div:first-child {
        grid-gap: 1rem;
        gap: 1rem;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
      }
    }

    .wrapper-manage-filters {
      list-style: none;
      padding: 0.75rem;
    }

    footer {
      display: flex;
      justify-content: space-between;

      > div,
      .wrapper-save-filter {
        display: flex;
        gap: 1rem;
      }
    }
  }

  .content-container {
    padding: 0 1rem;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      .wrapper-buttons {
        display: flex;
        gap: 1rem;

        input {
          width: 12rem;
        }
      }

      > div:first-child {
        display: flex;
        align-items: center;
        > h1 {
          margin-right: 0.5rem;
        }
      }
      > div:last-child {
        display: flex;
        justify-content: space-between;
        > button:first-child {
          margin-right: 1rem;
        }
      }
    }
  }
}
