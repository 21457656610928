@import '../../../assets/scss/DesignSystem/colors';
.mask-domain {
  .config-url {
    padding: 24px;
    border-radius: 5px;
    background: $color-highlights;

    .tech-list {
      margin-top: 30px;
      li {
        font-size: 13px;
        font-weight: 300;
      }
    }
  }
}
