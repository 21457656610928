@keyframes fade-in {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes entrance-from-top {
  0% {
    top: -10%;
  }
  100% {
    top: 0%;
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-25deg);
  }
  50% {
    transform: rotate(25deg);
  }
  70% {
    transform: rotate(-25deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes background-animation-2 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 200%;
  }
}
@keyframes background-animation-3 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 300%;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(195, 227, 254, 1);
    box-shadow: 0 0 0 0 rgba(195, 227, 254, 1);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(195, 227, 254, 0);
    box-shadow: 0 0 0 10px rgba(195, 227, 254, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(195, 227, 254, 0);
    box-shadow: 0 0 0 0 rgba(195, 227, 254, 0);
  }
}
