.tooltipimage {
  .popover {
    max-width: 450px;
    padding: 16px 12px 0px 12px;
    figure {
      img {
        margin: 0px 0;
        width: 100%;
      }
    }
  }
  @media (max-width: 600px) {
    .popover {
      max-width: 320px;
    }
  }
}
