@import "../../../../../../assets/scss/DesignSystemV2/variables";

.setup_plugin_wrapper {
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .setup_plugin_body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0;
  }
  .setup_plugin_body>div{
    cursor: pointer;
  }
}

.selected{
  outline: 0.125rem solid $blue-cloud-hover;
  border-radius: 0.6rem;
}

.margin-top{
  margin-top: 2rem;
}