@import '../../assets/scss/DesignSystemV2/_variables.scss';

.mobileNav-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 899;

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #fff;
    height: 3.75rem;
    width: 100%;
    position: absolute;
    bottom: 0;
    transition: 0.5s;

    li {
      width: 19.5%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.selected {
        background-color: $primary-8;
        border-top: 2px solid $primary-24;
      }

      &.cta {
        width: 22%;
        svg {
          position: absolute;
          z-index: 0;
          transform: rotate(90deg) translateX(-1.3rem);
          filter: drop-shadow(0px 8px 16px rgba(200, 161, 213, 0.37));
        }
        button > span:nth-child(1) {
          color: #fff;
          z-index: 1;
          transform: translateY(-0.5rem);
        }
      }
    }
  }

  .hidden-sidebar-wrapper {
    z-index: -1;
    padding-bottom: 1.25rem;
    height: 4rem;
    background-color: #fff;
    bottom: -3.25rem;
    position: relative;
    transition: 0.5s;
    filter: blur(2px);

    ul {
      margin-bottom: 1.25rem;
    }

    .collapse-arrow {
      border: 0;
      width: 100%;
      height: 2rem;
      background: url('../../assets/imgs/Arrow.png') center/auto no-repeat;
      transform: scaleY(-1);
      background-color: #fff;
      cursor: pointer;
      transition: 0.25s;
    }

    &.slide-in {
      bottom: 3.75rem;
      height: 7rem;
      box-shadow: 0px -8px 40px rgb(0 0 0 / 8%);
      filter: blur(0px);
      .collapse-arrow {
        transform: scaleY(1);
      }
    }
    &.slide-out {
      filter: blur(2px);
      bottom: -3.25rem;
      .collapse-arrow {
        transform: scaleY(-1);
      }
    }
  }
}
