@import '../../../../../../../assets/scss/DesignSystemV2/variables';

.initial-step-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .input-wrapper {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
  }

  hr {
    margin: 0;
    background-color: $gray-16;
  }
}
