.form-check {
  display: flex;
  align-items: center;
  position: relative;

  &.sm {
    .form-check-input {
      width: 1.75rem;
      height: 1rem;

      &::after {
        width: 0.75rem;
        height: 0.75rem;
      }

      &:checked::after {
        transform: translate(0.5rem, -50%);
      }
    }
  }
  &.md {
    .form-check-input {
      width: 2.5rem;
      height: 1.5rem;
    }
  }
  &.lg {
    .form-check-input {
      width: 2.5rem;
      height: 1.5rem;
    }
  }

  .form-check-input {
    width: 2.5rem;
    height: 1.5rem;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 1.5rem;
    cursor: pointer;
    position: relative;
    appearance: none;
    transition: background-color 0.3s, border-color 0.3s;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 3px;
      transform: translateY(-50%);
      width: 1.1rem;
      height: 1.1rem;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s;
    }

    &:checked::after {
      transform: translate(1rem, -50%);
    }

    &:checked {
      background-color: #0d6efd;
      border-color: #0d6efd;
    }
  }

  &:has(input[disabled]) {
    cursor: auto;

    .form-check-input {
      background-color: #f0f0f0;
      border-color: #ddd;
      cursor: not-allowed;

      &::after {
        background-color: #ccc;
      }
    }
  }

  &.secondary {
    .form-check-input {
      background-color: #fff;
      border-color: #c4c8d4;

      &::after {
        background-color: #7d86a1;
      }

      &:checked {
        background-color: #fff;
        border-color: #64c6f7;

        &::after {
          background-color: #2396fb;
        }
      }
    }
  }
}
