@import '../../../assets/scss/DesignSystemV2/_variables.scss';
@import '../../../assets/scss/DesignSystemV2/animation.scss';

.popover-wrapper {
  position: absolute;
  border-radius: 0.5rem;
  z-index: 901;
  background: #fff;
  box-shadow: $shadow-8;

  &.gray {
    background: $gray-48;
    color: #fff;

    .arrow::before {
      background: $gray-48;
    }

    header {
      background: $gray-48;
      color: #fff;
    }
  }

  &.dark {
    background: $dark-soft;
    color: #fff;
    border: 1px solid $gray-72;

    .arrow::before {
      background: $dark-soft;
    }

    header {
      background: $dark-soft;
      color: #fff;
    }
  }

  > header,
  > footer {
    box-shadow: $shadow-8;
  }
  > header > span {
    text-align: left;
  }
  &.sm {
    width: 16rem;
  }
  &.md {
    width: 20rem;
  }
  &.lg {
    width: 23rem;
  }

  &.fade-in {
    animation: fade-in 0.5s ease;
    visibility: visible;
  }
  &.fade-out {
    animation: fade-out 0.5s ease;
    visibility: hidden;
  }

  footer ~ main,
  header ~ main {
    padding: 0 0.75rem;
    border-radius: 0;
    box-shadow: none;
  }

  > header,
  > footer {
    background: #fff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
  }

  > header {
    padding-bottom: 0.5rem;
    border-radius: 0.5rem 0.5rem 0 0;

    > span {
      font-weight: 600;
      font-size: 0.85rem;
    }
  }
  > main {
    background: inherit;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: $shadow-8;
  }
  > footer {
    padding-top: 0.5rem;
    border-radius: 0 0 0.5rem 0.5rem;
    display: flex;
    justify-content: space-between;
    background: inherit;
  }
}

.arrow,
.arrow::before {
  position: inherit;
  width: 8px;
  height: 8px;
  border-radius: 30%;
}

.arrow::before {
  visibility: inherit;
  content: '';
  transform: rotate(45deg);
  background: #fff;
}

.popover-wrapper[data-popper-placement^='top'] > .arrow {
  bottom: -3px;
}

.popover-wrapper[data-popper-placement^='bottom'] > .arrow {
  top: -4px;
}

.popover-wrapper[data-popper-placement^='left'] > .arrow {
  right: -0.2rem;
}

.popover-wrapper[data-popper-placement^='right'] > .arrow {
  left: -0.3rem;
}
