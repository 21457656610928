@import '../../../assets/scss/DesignSystemV2/_variables';
.wrapper {
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  background: linear-gradient(90deg, $gray-64 0%, $gray-80 100%);
  position: absolute;
  left: 0;
  display: grid;
  place-items: center;
  padding: 1rem;
}

@media (max-width: $xl) {
  .wrapper {
    place-items: flex-start;
  }
}
