@import '../../../../../assets/css/variables';

.news-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem;
  height: 100%;
  place-content: flex-start;

  hr {
    margin: 0.5rem 0;
    color: inherit;
    background-color: $gray-8;
    border: 0;
    opacity: 1;

    &:last-child {
      display: none;
    }
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  .card-news {
    color: unset;
    text-decoration: none;
  }
  .card-news-skeleton,
  .card-news > div {
    display: grid;
    gap: 1rem;
    grid-template-columns: 5.5rem auto;
    padding: 1rem 0;
    height: 100%;
    cursor: pointer;

    &:not(&:last-child) {
      border-bottom: 1px solid $neutral-highlights;
    }

    aside {
      height: 5.5rem;
      width: 5.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    main {
      display: grid;
      gap: 0.5rem;
      min-width: 12rem;

      h3 {
        font-size: 0.85rem;
        font-weight: 600;
        color: $gray-space;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }

      > span {
        font-size: 0.75rem;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }
    }
  }
}
