@import '../../../assets/scss/DesignSystemV2/_variables.scss';

.coupon-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  div:first-child {
    width: 100%;
    display: flex;
    justify-content: space-between;
    span:first-child {
      font-size: 0.85rem;
      color: $gray-space;
      font-weight: 600;
    }
    span:last-child {
      font-size: 0.85rem;
      color: $gray-64;
      font-weight: 400;
    }
  }

  div:last-child {
    width: 100%;
    height: 0.25rem;
    background: $gray-8;
    .progress-bar {
      background: $primary-24;

      &.low-quantity {
        background: $red-block;
      }
    }
  }
}
