@import '../../../../assets/scss/DesignSystemV2/_variables.scss';

.button {
  background: transparent;
  font-weight: 600;
  line-height: 150%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 0;
  min-width: 2rem;
  width: fit-content;
  justify-content: center;
  transition: 0.5s background;
  text-decoration: none;
  height: fit-content;
  text-transform: capitalize;

  &.lg {
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    .loading-icon,
    .content .icon {
      font-size: 1.5rem;
    }
  }
  &.md {
    padding: 0.65rem 0.75rem;
    font-size: 0.85rem;
    border-radius: 0.5rem;
    .loading-icon,
    .content .icon {
      font-size: 1.25rem;
    }
  }
  &.sm {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
    border-radius: 0.375rem;
    .loading-icon,
    .content .icon {
      font-size: 1rem;
    }
  }

  &.xs {
    padding: 0.314rem 0;
    font-size: 0.75rem;
    border-radius: 0.375rem;
    min-width: 1.5rem;
    .loading-icon,
    .content .icon {
      font-size: 0.75rem;
    }
  }

  &.fullwidth,
  &.fullWidth {
    width: 100%;
  }

  .content {
    &.center {
      justify-content: center;
    }
    &.between {
      justify-content: space-between;
    }
    &.start {
      justify-content: flex-start;
    }
    &.end {
      justify-content: flex-end;
    }

    main {
      transition: 0.5s transform;
    }

    > main {
      display: grid;
      place-items: center;
      position: relative;

      &.loading {
        > span:nth-child(1) {
          opacity: 0;
        }
        > span:nth-child(2) {
          opacity: 1;
        }
      }
      > span:nth-child(2) {
        transition: 0.25s;
        opacity: 0;
        position: absolute;
      }
    }
  }

  &.text-dark {
    color: $gray-space !important;
    span {
      color: inherit;
    }
  }

  &.text-gray-40 {
    color: $gray-40 !important;
    span {
      color: inherit;
    }

    &.secondary {
      &.active,
      &:hover > div > span {
        color: $gray-40 !important;
      }
    }
  }

  &.text-gray-48 {
    color: $gray-48 !important;
    &.secondary {
      &.active,
      &:hover > div > span {
        color: $gray-48 !important;
      }
    }
    span {
      color: inherit;
    }
  }
  &.text-light {
    color: #fff !important;
    &.secondary {
      &.active,
      &:hover > div > span {
        color: $gray-space !important;
      }
    }
    span {
      color: inherit;
    }
  }

  &.gray-8 {
    border-color: $gray-8 !important;
  }
  &.rainbow {
    background: linear-gradient(#fff, #fff) padding-box, linear-gradient(270deg, #facb38, #79d8ff) border-box !important;
    border: 1px solid transparent !important;

    &.active,
    &:hover {
      background: linear-gradient($primary-8, $primary-8) padding-box,
        linear-gradient(270deg, #facb38, #79d8ff) border-box !important;
      border: 1px solid transparent !important;
    }
  }

  .loading-icon {
    animation: loading 1s linear infinite;
    opacity: 1;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    transition: 0.5s width;
    white-space: nowrap;
    color: inherit;
    position: relative;

    > div {
      display: flex;
    }
  }
  &.disabled {
    pointer-events: none;
    cursor: not-allowed !important;
  }
  &[type='button'] {
    pointer-events: visible;
  }

  &.primary {
    background-color: $primary-pure;
    color: $white;

    &.active,
    &:hover {
      color: $white;
      background: $primary-hover;
    }

    span {
      color: $white;
    }

    &.disabled {
      background: $gray-16 !important;
      color: $gray-32 !important;

      span {
        color: $gray-32 !important;
      }
    }
  }

  &.secondary {
    border: 1px solid $primary-24;
    color: $gray-space;
    background-color: #fff;

    &.active,
    &:hover {
      color: $primary-hover;
      border: 1px solid;
      border-color: $primary-24;
      background: $primary-8;
    }

    div > span {
      color: $gray-space;
      color: $primary-hover;
    }

    &.disabled {
      background: $gray-8;
      color: $gray-32;

      span {
        color: $gray-32 !important;
      }
    }
  }

  &.tertiary {
    color: $primary-pure;
    padding-left: 0;
    padding-right: 0;

    &.disabled {
      color: $gray-32;

      span {
        color: $gray-32 !important;
      }
    }
  }

  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  &.danger,
  &.red-block {
    background-color: $red-block;
    color: $white;

    &.active,
    &:hover {
      color: $white;
      background: $red-block-hover;
    }

    span {
      color: $white;
    }

    &.disabled {
      background: $gray-16 !important;
      color: $gray-32 !important;

      span {
        color: $gray-32 !important;
      }
    }
  }

  &.red-block-light {
    background-color: $red-block-8;
    span {
      color: $red-block;
    }

    &.active,
    &:hover {
      background: $red-block-hover;
      span {
        color: $white;
      }
    }

    &.disabled {
      background: $gray-16 !important;
      color: $gray-32 !important;

      span {
        color: $gray-32 !important;
      }
    }
  }
}
