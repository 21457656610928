@import 'react-datepicker/dist/react-datepicker.css';

.react-datepicker.blkd-date-picker {
  display: inline-flex;
  &.is-invalid {
    border-color: $color-danger;
    .react-datepicker__time-box {
      border-right: 1px solid $color-danger;
    }
  }

  .react-datepicker__time-box {
    border-right: 1px solid #aeaeae;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    height: calc(200px + (3.7rem / 2));
  }
}
