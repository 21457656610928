@import '../../../../../../assets/scss/DesignSystemV2/_variables';
.progress-steps-wrapper {
  width: 100%;
  height: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 0.5rem;
  padding: 0 8.5rem;
  bottom: 4.25rem;
  position: absolute;

  > div {
    height: 100%;
    border-radius: 0.25rem;
    transition: 1s;
    transition-timing-function: ease;
    background: linear-gradient(90deg, $gray-8 0% 33%, $blue-cloud 33% 66%, $primary-pure 66% 100%);
    background-size: 303% 100%;

    &.current {
      background-position-x: -50%;
    }

    &.previous {
      background-position-x: -100%;
    }
  }
}
