.crop-text-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  line-clamp: 1 !important;
  -webkit-box-orient: vertical;
}
.crop-text-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  line-clamp: 2 !important;
  -webkit-box-orient: vertical;
}
