@import '../../../../../../assets/scss/DesignSystemV2/_variables';
.form-wrapper {
  display: grid;
  place-content: flex-start;
  gap: 1rem;
  grid-template-columns: 1fr;
  padding: 4rem;
  height: 100%;
  place-content: center;

  a {
    text-decoration: none;
  }

  > img {
    margin-bottom: 1rem;
  }
  .texts-wrapper {
    display: grid;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  > form {
    display: grid;
    gap: 1rem;
    margin-bottom: 0.5rem;

    > button {
      margin-top: 0.5rem;
    }

    > section {
      display: flex;
      justify-content: space-between;
    }
  }
  > p {
    text-align: center;
    margin: 0.5rem 0;
  }
  .social-media-login {
    display: flex;
    gap: 0.5rem;
  }
  .sign-text {
    display: flex;
    gap: 0.25rem;
    justify-content: center;
    margin-top: 1.5rem;
    align-items: center;
  }
}

.modal {
  > div:last-child > main {
    padding: 0;
    div {
      padding: 1rem;
      overflow-y: auto;
      max-height: 80vh;
    }
  }
}
@media (max-width: $sm) {
  .form-wrapper {
    padding: 4rem 2rem;
  }
}
