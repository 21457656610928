.modal {
  background: rgba(58, 63, 81, 0.5);
  backdrop-filter: blur(3px);
  padding: 1rem !important;
  .modal-dialog {
    width: 100%;
    margin-top: 0 !important;
    .modal-content {
      border: none;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }
    .modal-header {
      padding: 24px 24px 20px 24px;
      border-bottom: solid 1px $color-highlights;

      @media (max-width: 576px) {
        .modal-breadcrumb {
          font-size: 18px !important;
          &:first-child {
            display: none;
          }
        }
        .icon:nth-child(2) {
          display: none !important;
        }
      }

      .modal-breadcrumb {
        font-size: 20px;
        font-weight: 600;
        color: $color-grey;

        &:nth-last-child(2) {
          color: $color-dark !important;
        }
      }

      .arrow-right {
        color: $color-grey-light;
        padding: 10px 7px;
        display: flex;
        align-items: center;
      }
    }
    .modal-body {
      position: relative;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      padding: 24px 24px 24px 24px;
      text-align: left;
    }
    .modal-footer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      background: #fafbfd;
      border-top: solid 1px $color-ghost;
      border-radius: 0 0 10px 10px;
    }
  }
}
