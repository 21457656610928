@import '../../../../../../../assets/scss/DesignSystemV2/variables';

.form-container {
  border: 1px solid $gray-8;
  background-color: $white;
  border-radius: 0.5rem;
  padding: 1.5rem;

  p:first-child {
    margin-bottom: 1rem;
  }

  .input-container {
    margin-top: 1.5rem;
    display: flex;

    > div {
      width: unset;
      margin-right: 1rem;
    }

    input {
      margin: 0 auto;
      max-width: 15rem;
      width: 100%;
      text-align: center;
    }
  }
}
