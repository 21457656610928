body {
  background: $color-ghost;
  font-size: 14px;
  font-weight: 400;
  color: $color-dark;
}

.header-nav {
  background-color: $color-black;
  width: 100%;
  height: 60px;
  padding-left: 15px;
  position: fixed;
  top: 0;
  z-index: 1000;

  .logo {
    float: left;
    max-width: 135px;
    height: 60px;
    padding-top: 15px;
    padding-left: 5px;
    margin-right: 25px;
    transition: all 0.1s;
  }

  .top-create {
    float: left;
    border-left: solid 1px #484848;
    border-right: solid 1px #484848;

    .btn {
      height: 60px;
      color: #ffffff;
      background-color: #282c33;
      padding: 18px 20px;
      font-size: 13px;
      border-radius: 0;

      &:hover {
        background: #3d4450;
      }
    }
  }

  .top-plan {
    position: relative;
    float: left;
    min-width: 230px;
    height: 60px;
    padding-top: 10px;
    padding-left: 10px;
    &.hibernate {
      .info {
        margin-top: 15px !important;
        display: flex;
        align-items: flex-end;
      }
      .progressBar {
        display: none !important;
      }
    }
    .btn {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      padding: 0;
      border-left: solid 1px #484848;
      border-right: solid 1px #484848;

      .info {
        margin-top: 10px;
        padding-left: 10px;
        text-align: left;

        i {
          float: left;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 5px;

          &.plan-starter {
            background: url('../../imgs/icon-starter.svg') no-repeat center center;
          }

          &.plan-professional {
            background: url('../../imgs/icon-professional.svg') no-repeat center center;
          }

          &.plan-enterprise {
            background: url('../../imgs/icon-enterprise.svg') no-repeat center center;
          }

          &.plan-hibernate {
            background: url('../../imgs/icon-hibernate.svg') no-repeat center center;
          }
        }

        h2 {
          font-size: 12px;
          font-weight: 600;
          color: #ffffff;
        }

        h3 {
          font-size: 10px;
          font-weight: 500;
          color: #c5c5c5;
          margin-top: -5px;
        }
      }

      .progressBar {
        position: absolute;
        width: 100%;
        height: 10px;
        background: #4d5056;
        bottom: 0;
        left: 0;
        display: flex;

        .enterprise {
          background: #54aebb;
        }

        .professional {
          background: #d464af;
        }

        .starter {
          background: #8fc778;
        }

        .hibernate {
          background: #ffffff;
        }
      }
    }

    .btn-upgrade {
      background: $color-warning;
      color: $color-dark;
      animation: pulse 1.2s infinite;
      transition: 0.5s;
      text-decoration: none;
      float: right;
      height: 40px;
      padding: 10px 35px;
      border-radius: 3px;
      font-size: 13px;

      @keyframes pulse {
        0% {
          -moz-box-shadow: 0 0 0 0 rgba(255, 200, 0, 0.4);
          box-shadow: 0 0 0 0 rgba(255, 200, 0, 0.4);
        }

        70% {
          -moz-box-shadow: 0 0 0 10px rgba(255, 200, 0, 0);
          box-shadow: 0 0 0 10px rgba(255, 200, 0, 0);
        }

        100% {
          -moz-box-shadow: 0 0 0 0 rgba(255, 200, 0, 0);
          box-shadow: 0 0 0 0 rgba(255, 200, 0, 0);
        }
      }
    }
  }

  .settings {
    float: left;
    height: 100%;
    margin-left: 0;
    margin-right: 0px;

    .dropdown-toggle {
      color: $color-light;

      &::after {
        margin-top: 15px;
      }
    }

    .profile {
      float: left;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: none;
      border: solid 1px #4d5056;
      margin-right: 5px;
      padding-top: 6px;
      text-align: center;

      i {
        font-family: 'blkd';
        font-size: 20px;
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        margin-left: 0.3em;
        margin-right: 0.5em;
      }
    }

    .dropdown-menu {
      background: $color-light;
      border: solid 1px #f1f1f1;
      padding: 20px;
      width: auto;
      min-width: 175px;
      transform: translate(-80px, 60px);

      h3 {
        font-size: 12px;
        color: $color-black;
      }
      hr {
        background-color: #999;
        margin-top: 5px;
        margin-bottom: 5px;
      }

      li {
        font-size: 12px;
        color: $color-dark;
        font-weight: 600;
        line-height: 30px;
        transition: 0.5s;
        padding-left: 5px;
        width: 100%;
        &:hover {
          background: $color-primary-light-2;
          border-radius: 0.25rem;
        }
      }

      li a {
        font-weight: 400;
        font-size: 12px;
        color: $color-dark;
        text-decoration: none;
        display: block;
      }

      li button {
        border: none;
        background: none;
        &:hover {
          background: $color-primary-light-2;
          border-radius: 0.25rem;
        }
      }
    }
  }
}

.menu,
.mobile-menu {
  min-width: 175px;
  width: 175px;
  height: 100vh;
  background: #ffffff;
  box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.05);
  z-index: 100;
  overflow: auto;
  position: fixed;
  padding-top: 60px;
  @include scrollbar();

  ul {
    padding-left: 0;
    li {
      list-style: none;
      margin-left: 0px;
      transition: all 0.3s;

      &:hover,
      &.active {
        background: $color-primary-light-2;

        i {
          color: $color-dark;
        }
      }

      a {
        text-decoration: none;
        color: $color-dark;
        transition: all ease-out 0.2s;
        width: 100%;
        height: 100%;
        display: block;
        padding-left: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 13px;

        i {
          font-size: 20px;
          color: $color-grey-light;
          z-index: 100;
          margin-left: 0.3em;
          margin-right: 0.5em;
        }

        span + i {
          font-size: 10px;
          color: $color-dark;
          margin-left: 0.5rem;
        }
      }
      ul {
        margin-left: -25px;
        padding-left: 25px;
        background-color: $color-ghost;
        border-top: solid 1px $color-highlights;
        border-bottom: solid 1px $color-highlights;
        li {
          &:hover {
            background: $color-primary-light-2;
          }
        }
        a {
          color: $color-grey-dark;
          padding-top: 10px;
          padding-bottom: 10px;

          &:hover {
            background: $color-primary-light-2;
          }
        }
      }
    }
  }
}

.app-content {
  float: right;
  width: calc(100% - 175px);
  background: $color-ghost;
}

.campaign-bar {
  width: 100%;

  .dropdown-nav-promotion {
    height: auto;
  }

  .title {
    display: flex;
    background-color: #ffffff;
    height: 53px;
    padding-top: 20px;
    padding-left: 15px;

    .btn-back {
      background: $color-highlights;
      color: $color-dark;
      width: 30px;
      height: 30px;
      border-radius: 5px;
      text-align: center;
      padding-top: 5px;
      margin-top: -8px;
      margin-right: 10px;

      &:hover {
        background: $color-grey-light;
        color: $color-dark;
      }
    }

    h2 {
      font-size: 14px;
      font-weight: 500;
    }
  }

  nav {
    height: 50px;
    background: #f9f9f9;
    border-top: solid 1px #e5e8eb;
    border-bottom: solid 1px #e5e8eb;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;

    .button-menu {
      padding: 0;
      height: 48px;

      li {
        list-style: none;
        display: block;
        height: 49px;

        a {
          text-decoration: none;
          display: block;
          height: 48px;
          padding: 15px;
          font-size: 13px;
          color: $color-dark;
          transition: all ease-out 0.2s;

          &:hover,
          &.active {
            color: $color-primary;
            border-bottom: 5px solid $color-primary;
          }

          &.btn-edit {
            background: $color-primary;
            color: $color-light;
            border-radius: 3px;
            margin: 5px;
            margin-left: 25px;
            height: 38px;
            padding: 10px 25px;
          }

          i {
            margin-right: 5px;
          }
        }
      }
    }

    ul:last-child {
      .button-with-border {
        border-left: solid 1px #e5e8eb;
      }

      .btn-launch-control {
        padding-right: 15px !important;
        &:active {
          padding-top: 20px 0 0 0;
        }

        i {
          width: 30px;
          height: 30px;
          margin-top: -9px;
          margin-right: 5px;
          border-radius: 50%;
          color: $color-light;
          padding-right: 2px;
          font-family: 'blkd';
          font-size: 20px;
          color: $color-light;

          /* blkd styles */
          font-style: normal;
          font-weight: normal;
          display: inline-block;
          text-decoration: inherit;
          text-align: center;
          font-variant: normal;
          text-transform: none;

          @media screen and (max-width: 550px) {
            display: none;
          }

          &.online {
            line-height: inherit;
            background: $color-success;
            &::before {
              content: '\e809';
            }
          }

          &.draft {
            line-height: inherit;
            background: $color-warning;
            &::before {
              content: '\e807';
            }
          }

          &.archived {
            line-height: inherit;
            background: $color-grey;
            &::before {
              content: '\e808';
            }
          }
        }
      }
    }
  }
}

.main-content {
  padding: 24px;

  h1 {
    margin-top: 8px;
    margin-bottom: 16px;
  }
  .content-box {
    background: $color-light;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    height: auto;
    border-radius: 1rem;
    padding: 24px;
    margin-top: 0;
    margin-bottom: 24px;
  }

  hr {
    background: $color-grey-dark;
    margin-top: 0px;
    margin-bottom: 25px;
  }
  .boxs {
    margin-bottom: 25px;

    .box {
      border: solid 1px $color-grey-light;
      border-radius: 5px;
      padding: 25px 15px 25px 15px;
      margin-bottom: 15px;
      height: auto;
      min-height: 120px;
    }
  }
}

.msg-error {
  width: 100%;
  padding: 48px 24px !important;
  text-align: center;
  img {
    max-width: 300px;
    margin-bottom: 24px;
  }
}
.msg-alert {
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  &.warning {
    color: $color-warning-dark;
    background: $color-warning-light-2;
    .btn-link {
      color: $color-warning-dark;
      font-size: 20px;
      line-height: 0;
    }
  }
  &.success {
    color: $color-success-dark;
    background: $color-success-light-2;
    .btn-link {
      color: $color-success-dark;
      font-size: 20px;
      line-height: 0;
    }
  }
  &.danger {
    color: $color-danger-dark;
    background: $color-danger-light-2;
    .btn-link {
      color: $color-danger-dark;
      font-size: 20px;
      line-height: 0;
    }
  }
  &.neutral {
    color: $color-grey-dark;
    background: $color-grey-light;
    .btn-link {
      color: $color-grey-dark;
      font-size: 20px;
      line-height: 0;
    }
  }
  &.primary {
    color: $color-primary-dark;
    background: $color-primary-light-2;
    .btn-link {
      color: $color-primary;
      font-size: 20px;
      line-height: 0;
    }
  }

  p {
    margin: 0;
  }
}

/////////// RESPONSIVE VERSION ///////////

#menu {
  position: absolute;
  top: 0px;
  padding: 0px;
  transform-origin: 0% 0%;
  transform: translate(-190px, 0px);
  transition: transform 0.1s;
  background-color: none;
}

.mobile {
  position: fixed;
  width: 175px;
  background: $color-light;
  box-shadow: 1px 0px 8px 0px rgba(0, 0, 0, 0.1);
  z-index: 10;
  top: 60px;
  left: 0;
}

#menuToggle input:checked ~ ul {
  transform: none;
}

.back-toggle {
  width: 60px;
  height: 61px;
  background: #ffffff;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
}

#menuToggle {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1010;
  user-select: none;
  height: 60px;
  width: 60px;
  border-right: solid 1px #484848;
  padding-top: 20px;
  padding-left: 15px;
  margin-right: 10px;

  a {
    text-decoration: none;
    color: #232323;
    transition: color 0.3s ease;
  }

  input {
    display: block;
    width: 70px;
    height: 70px;
    position: absolute;
    top: -7px;
    left: -5px;
    cursor: pointer;
    opacity: 0;
    z-index: 20;
  }

  > span {
    display: block;
    width: 23px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #fff;
    border-radius: 3px;
    margin-left: 5px;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;

    &:first-child {
      transform-origin: 0% 0%;
    }

    &:nth-last-child(2) {
      transform-origin: 0% 0%;
    }
  }

  input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(0px, 0px);
    background: #ffffff;
    z-index: 100;
  }

  input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, 0px);
  }
}

@media (min-width: 992px) {
  #menuToggle {
    display: none;
  }
}

@media (max-width: 1200px) {
  .campaign-bar {
    nav ul li a {
      font-size: 12px !important;

      .btn-upgrade {
        font-size: 12px !important;
      }
    }
    .btn-launch-control {
      padding-right: 0 !important;
    }
  }
}

@media (max-width: 991px) {
  .logo {
    margin-left: 55px;
  }
  .campaign-bar {
    nav {
      .button-menu {
        a {
          i {
            display: none;
          }
        }
      }
    }
  }

  .app-content {
    width: 100%;
    height: calc(100vh - 60px);
  }

  .content .menu {
    display: none;
  }
  .menu {
    padding-top: 0;
  }
  .campaigns-list {
    padding-left: 0;
    display: block;

    .table {
      .col-1 {
        flex-basis: 20%;
      }
      .col-2 {
        flex-basis: 10%;
        text-align: center;
      }
      .col-3 {
        flex-basis: 15%;
        text-align: center;
      }
      .col-4 {
        flex-basis: 15%;
        text-align: center;
      }
      .col-5 {
        flex-basis: 10%;
        text-align: center;
      }
      .col-6 {
        flex-basis: 15%;
        text-align: right;
      }
    }
  }
}
