.blkd-icon {
  font-family: 'blkd';
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
}

.icon-campaign:before {
  content: '\e803';
}
.icon-audience:before {
  content: '\e802';
}
.icon-addon:before {
  content: '\e801';
}
.icon-account:before {
  content: '\e800';
}
.icon-subscription:before {
  content: '\e805';
}
.icon-support:before {
  content: '\e806';
}
.icon-account-settings:before {
  content: '\e804';
}
.icon-zapier:before {
  content: '\e810';
}
.icon-code:before {
  content: '\f121';
}
.icon_gift:before,
.icon-gift:before {
  content: '\e80a';
}
.icon_gift_open:before,
.icon-gift-open:before {
  content: '\e80b';
}

.award:before {
  content: '\e80c';
}
