@import '../../../assets/scss/DesignSystemV2/_variables.scss';

.carousel-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .media {
    height: 12rem;
    border-radius: 0.5rem;

    display: flex;
    overflow: hidden;
    position: relative;

    .child {
      position: absolute;
      width: 100%;
      height: 100%;
      transition: 0.5s;
      opacity: 0;
      overflow: hidden;
      border-radius: inherit;
      display: none;

      &.active {
        opacity: 1;
        display: block;
      }
      .thumbnail {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        z-index: 1;
        justify-content: center;
        transition: opacity 0.25s, 0.5s visibility;

        &.desactive {
          opacity: 0;
          visibility: hidden;
        }

        button {
          width: 3rem;
          height: 3rem;
          border: 0;
          border-radius: 50%;
          position: absolute;
          z-index: 2;

          margin: 0;
          padding: 0;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: #fff;
            font-size: 4.5rem;
            background-color: $yellow-bee;
          }

          &::before {
            content: '';
            position: absolute;
            width: 3.75rem;
            height: 3.75rem;
            background-color: $yellow-bee;
            z-index: -1;
            border-radius: 50%;
          }
        }
      }
      .thumbnail,
      img,
      video {
        width: 100%;
        border-radius: 0.5rem;
        min-height: 12rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  footer {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    align-items: center;

    .description-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.5rem;

      h3 {
        font-size: 0.85rem;
        font-weight: 600;
        color: $gray-space;
        width: 100%;
        margin: 0;
        padding-top: 0.25rem;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }
      span {
        font-size: 0.75rem;
        font-weight: 400;
        color: $gray-64;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }

      a {
        text-decoration: none;
        color: inherit;
      }

      a:hover,
      a:active {
        color: inherit;
      }
    }
    .arrows-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      gap: 0.5rem;
      height: 100%;

      button {
        border-radius: 1rem;
        border: 0;
        background-color: #e5f0ffb2;
        display: flex;
        align-items: center;
        padding: 0;
      }
    }
  }
}
