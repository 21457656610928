.btn {
  padding: 10px 20px 8px 20px;
  border-radius: 3px;
  color: $color-dark;
  font-size: 14px;
  display: inline-block;
  &:hover {
    color: $color-dark;
  }

  &.link {
    padding-left: 0;
    padding-right: 0;
  }

  &.btn-primary {
    color: $color-light;
    background-color: $color-primary;
    border-color: $color-primary;

    &:hover {
      background-color: $color-primary-dark;
      border-color: $color-primary-dark;
    }

    &.btn-primary ~ :not(:disabled):not(.disabled).active {
      color: $color-light;
      background-color: $color-primary-dark;
      border-color: $color-primary-dark;
    }

    &.btn-primary ~ .disabled,
    &.btn-primary:disabled {
      color: $color-light;
      background-color: $color-primary-light;
      border-color: $color-primary-light;
    }
  }

  &.btn-secondary {
    color: $color-dark;
    background-color: $color-light;
    border-color: $color-highlights;

    &:hover {
      background-color: $color-ghost;
      border-color: $color-grey-light;
    }

    &.btn-secondary ~ :not(:disabled):not(.disabled).active {
      color: $color-dark;
      background-color: $color-highlights;
      border-color: $color-grey-light;
    }

    &.btn-secondary ~ .disabled,
    &.btn-secondary:disabled {
      color: $color-grey-light;
      background-color: $color-ghost;
      border-color: $color-grey-light;
    }
  }

  i {
    font-size: 13px;
    margin-left: 2px;
    margin-right: 2px;
  }

  &.btn-danger {
    color: $color-danger;
    background-color: $color-light;
    border-color: $color-danger;

    &.btn-danger-solid {
      color: $color-light !important;
      background-color: $color-danger !important;
    }
  }

  &.btn-xs {
    padding: 4px 8px 6px 8px;
    height: 30px;
    transition: 0.2s;

    &:hover {
      background-color: #8a9bb4;
      color: #ffffff;
    }
  }

  &.btn-sm {
    font-size: 12px;
    padding: 5px 10px;
    &.link {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.btn-outline-white {
    color: $color-light;
    background-color: transparent;
    border-color: $color-light;

    &:hover {
      background-color: $color-light;
      color: $color-dark;
    }
  }

  &.btn-outline-secondary:hover {
    color: $color-light;
  }
}
