@import '../../../../assets/scss/DesignSystemV2/variables';

.sender-form {
  position: relative;

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 1.5rem;

    @media (max-width: $lg) {
      grid-template-columns: 1fr;
    }

    .column {
      padding: 1rem;
      border-radius: 0.5rem;
      border: 1px solid $gray-8;
      background: $white;

      &:nth-child(1) {
        grid-column: 1 / 2;

        @media (max-width: $lg) {
          grid-column: 1;
        }
      }

      &:nth-child(2) {
        grid-column: 2 / 3;

        @media (max-width: $lg) {
          grid-column: 1;
        }
      }

      .column-container {
        display: grid;
        grid-gap: 1rem;
        height: 100%;
        grid-template-rows: auto 1fr auto;

        &.preview-row {
          grid-template-rows: auto auto 1fr auto;
        }

        .preview-title {
          display: flex;
          align-items: center;
          p {
            flex: 1;
          }
        }

        .preview-header {
          border-radius: 0.5rem;
          border: 1px solid $gray-8;
          background: $gray-4;
          padding: 1rem;
          display: grid;
          gap: 1rem;
        }

        .content {
          border-radius: 0.5rem;
          background: $gray-8;
          padding: 1.5rem 1rem;
          font-family: Arial;

          .centralize {
            display: flex;
            justify-content: center;

            .blank {
              border-radius: 0.25rem;
              background: $white;
              padding: 2rem 1.5rem;
              display: grid;
              gap: 1.75rem;
              width: 37.5rem;

              .greeting {
                display: flex;
                span {
                  font-weight: 600;
                }
              }

              .enter-button-container {
                padding: 2.5rem 0;
                display: grid;
                gap: 1.5rem;
                place-content: center;

                > div {
                  display: flex;
                  justify-content: center;

                  .enter-button {
                    background: #1a3a51;
                    display: flex;
                    padding: 0.75rem 1.25rem;
                    justify-content: center;
                    align-items: center;
                    color: #f9fafb;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 800;
                    border-radius: 0.5rem;
                  }
                }
                .invite-link {
                  text-align: center;
                  color: #2396fb;
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 600;
                }
              }
            }
          }

          .footer {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            text-align: center;
          }
        }

        .send-email-test {
          display: flex;

          > div:first-child {
            flex: 1;
            margin-right: 1rem;
          }
          > div:nth-child(2) {
            padding-top: 1.4rem;
          }
        }
      }

      hr {
        margin: 2rem 0;
      }

      .email-sending-options {
        display: flex;
        gap: 1rem;
        flex-direction: column;

        .header-description {
          p:first-child {
            margin-bottom: 0.75rem;
          }
        }

        .settings {
          .content {
            background-color: $gray-4;
            border-radius: 0.5rem;
            padding: 1rem;
            display: flex;
            gap: 0.75rem;
            flex-direction: column;

            .input-color-size {
              width: 2.25rem;
            }
          }
        }

        .accordion {
          .accordion-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 2.25rem;
            margin-bottom: 0.5rem;
            position: relative;
            cursor: pointer;
          }

          .accordion-body {
            background-color: $gray-4;
            border-radius: 0.5rem;
            padding: 1rem;

            &.accordion-closed {
              display: none;
            }

            .input-setting {
              display: flex;
              margin-top: 0.75rem;
              > div:nth-child(1) {
                flex-grow: 1;
                margin-right: 1rem;
              }
              > div:nth-child(2) {
                width: 2.25rem;
              }
              > div:nth-child(3) {
                margin-left: 1rem;
                width: 2.25rem;
              }
            }
          }
        }
      }
    }

    &.one-column {
      grid-template-columns: 1fr;
    }

    .email-successfully-sent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 0.5rem;
      border: 1px solid $gray-8;
      background: $white;
      padding: 4rem 1rem;

      .container-icon {
        display: grid;
        gap: 1rem;
        place-items: center;

        p {
          text-align: center;
        }
      }
    }
  }

  .overlay {
    pointer-events: none;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $gray-8;
    height: 100%;
    width: 100%;
    z-index: 890;
  }

  .setup-email {
    position: absolute;
    z-index: 891;
    background: $white;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    > h1,
    > p {
      text-align: center;
      margin-bottom: 0.375rem;
    }
    > div {
      display: flex;
      justify-content: center;
    }
  }
}

.loading {
  position: relative;
  > div {
    padding: 2rem 0;
    position: relative;
  }
}

.background-line {
  display: flex;
  flex-direction: row;

  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid $gray-8;
  align-items: center;
  padding: 0.5rem 0;

  > div {
    width: 4rem;
  }
  .input-options {
    display: flex;
    justify-content: space-between;
    width: unset;
    gap: 1rem;
    > div {
      width: 4rem;
    }
  }
}
