@import '../../../../assets/scss/DesignSystemV2/variables';

.input-date {
  width: 100%;

  > div,
  input {
    width: inherit;
    height: 100%;
  }
  input {
    border-radius: 0.5rem;
    border: 1px solid $gray-8;
    color: $gray-40;
    font-weight: 400;
    font-family: inherit;
    padding: 0.65rem 0.75rem !important;
    font-size: 1rem;

    &::placeholder {
      color: inherit;
      font-family: inherit;
      font-weight: inherit;
      font-size: inherit;
    }
  }

  > div > div > span {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    color: $gray-40;
  }

  .calendar-wrapper {
    border: 1px solid $border-primary;
    padding: 1rem;
    box-shadow: 0px 16px 32px -12px rgba(14, 18, 27, 0.1);
    border-radius: 0.5rem;

    button {
      top: 2.125rem;
      background: #fff;
      height: 1.25rem;
      width: 1.25rem;
      padding: 0.125rem;
      border-radius: 0.25rem;

      &:nth-child(4) {
        right: 1.5rem;

        > span {
          left: 0.125rem;
        }
      }
      &:nth-child(3) {
        left: 1.5rem;
        > span {
          left: -0.125rem;
        }
      }
      > span {
        font-size: 0.65rem;
        &:before {
          border-color: $content-primary;
          border-width: 0.125rem 0.125rem 0 0;
          width: 0.5rem;
          height: 0.5rem;
        }
      }
    }

    > div:last-child {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      > div:first-child {
        background: transparent !important;
        border: 0 !important;
        > div:first-child {
          background-color: $background-secondary;
          padding: 0.5rem;
          width: 100%;
          border-radius: 0.5rem;
        }
        > div:nth-child(2) {
          width: 45%;
          margin: -1.75rem auto 2.5rem;
          justify-content: flex-end;
          display: flex;
          > div > div:not([style*='visibility: visible;']) {
            border: 0;
            padding: 1rem;
            background-color: #fff;
            font-family: 'Inter';
            font-size: 0.85rem !important;
            line-height: 20px;
            font-weight: 600;
            box-shadow: 0px 16px 32px -12px rgba(14, 18, 27, 0.1);
            position: absolute;
            margin-top: 0.5rem;
            z-index: 2;
            & > div {
              padding: 0.25rem;
              transition: 0.25s;
              &:hover {
                color: $action-active !important;
                background-color: $brand-light;
                border-radius: 0;
              }
            }
            & > div:last-child {
              display: none;
            }
          }

          span:first-child {
            border: 0;
            transform: unset;
            width: 1.5rem;
            height: 1.5rem;
            &:before {
              content: '\e313';
              font-family: 'Material Symbols Rounded';
              font-size: 1.5rem;
              position: absolute;
              left: 0.5rem;
              top: 0.125rem;
              line-height: 0.5;
            }
          }

          span:last-child {
            display: none;
          }
        }
        > div:last-child {
          display: flex;
          gap: 0.5rem;
          margin-top: 0.5rem;

          > div {
            margin: 0;
            min-width: 2.5rem;
            text-transform: uppercase;
            font-weight: 600;
            color: $content-primary;
          }
        }
      }

      > div:last-child {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin: 0;
        > div {
          display: flex;
          gap: 0.5rem;
        }
      }
    }

    .day {
      font-weight: 600;
      color: $content-primary !important;
      font-family: 'Inter';
      font-size: 0.85rem !important;
      line-height: 20px;
      font-weight: 600;
      color: #0e121b !important;
      font-family: 'Inter';
      font-size: 0.85rem !important;
      line-height: 20px;
      min-width: 2.5rem;
      min-height: 2.5rem;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      margin: 0;
      position: relative;
      transition: 0.5s background;

      &.disabled {
        pointer-events: none;
        color: #ccc !important;
      }

      &.is-on-range {
        color: $action-active !important;
        background-color: $brand-light;
        border-radius: 0.25rem;

        &::before {
          content: ' ';
          position: absolute;
          width: 160%;
          height: 100%;
          background: $brand-light;
          z-index: 0;
          mix-blend-mode: darken;
        }
      }
      &.selected {
        color: #fff !important;
        background-color: $action-active;
        border-radius: 0.25rem;
        z-index: 1;
      }

      &.is-today:not(:has(.is-on-range)) {
        background-color: #fff;
        &:after {
          background-color: $action-active;
          content: '';
          position: absolute;
          top: 80%;
          left: 50%;
          width: 0.25rem;
          height: 0.25rem;
          border-radius: 50%;
          z-index: 9;
          transform: translate(-50%, -50%);
        }
      }

      &:hover {
        background: $background-secondary;
        color: $content-primary !important;
      }
    }
  }
}
