.skeleton {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background-color: #eee !important;

  background: linear-gradient(90deg, #ddd 40%, #eee, #ddd 70%);
  background-size: 200%;

  animation: waves 2s infinite;
}

@keyframes waves {
  0% {
    background-position-x: 200%;
  }
  100% {
    background-position-x: 0;
  }
}
