@import '../../../../../assets/css/variables';

.invites-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem;

  main {
    display: flex;
    gap: 0.75rem;
    flex-direction: column;
    justify-content: center;

    h3 {
      font-size: 0.85rem;
      font-weight: 600;
      color: #3a3f51;
    }
    > span {
      font-size: 0.85rem;
      font-weight: 600;
      color: $gray-space;
    }
    > small {
      font-size: 0.75rem;
      font-weight: 400;
      color: $gray-48;
    }
  }
  aside {
    min-height: 9rem;
    // background: url('../../assets/images/invites-image.png') center / contain
    //   no-repeat;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
      width: 100%;
      max-width: 600px;
      height: 100%;
      object-fit: contain;
    }

    .image-wrapper {
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      background: linear-gradient(90deg, #314b72 0%, #0f2443 100%);
      img {
        min-width: 7.5rem;
        width: 100%;
      }
      div {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        & > header {
          font-size: 1rem;
          font-weight: 800;
          color: #fff;
        }
      }
    }
  }

  @media (min-width: $md) {
    gap: 2rem;
  }

  @media (max-width: $md) {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}
