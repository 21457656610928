@import '../../../../../../assets/scss/DesignSystemV2/variables';
.promotion-wrapper {
  display: grid;
  grid-template-columns: minmax(100px, 57%) minmax(70px, 13%) minmax(130px, 19%) minmax(70px, 11%);
  padding: 1.2rem 1rem;
  border-top: 1px solid $gray-8;
  color: $gray-space;
  position: relative;
  transition: 0.5s;

  > div:not(> :first-child) {
    display: flex;
    align-items: center;
  }

  .promotion-name-wrapper {
    display: inline-grid;
    gap: 0.25rem;
    h2 {
      font-size: 0.85rem;
      font-weight: 600;
      margin-bottom: 0;
    }
    span {
      font-size: 0.75rem;
      font-weight: 400;
    }
  }
  .promotion-type-wrapper,
  .promotion-liveon-wrapper,
  .promotion-entries-wrapper {
    font-size: 0.85rem;
    font-weight: 600;
    transition: 0.5s;
  }
  .promotion-actions-wrapper {
    display: grid;
  }

  &:hover {
    background-color: #fff !important;
    box-shadow: $shadow-6;
    z-index: 1;

    .promotion-type-wrapper,
    .promotion-liveon-wrapper,
    .promotion-entries-wrapper {
      opacity: 0;
    }

    .buttons-options {
      opacity: 1;
      z-index: 1;
    }
  }
  .buttons-options {
    opacity: 0;
    z-index: -1;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    height: 100%;
    background: #fff;
    padding: 0 0.5rem;
    transition-delay: 0.2s;
    transition-property: opacity;
    > a,
    > button {
      display: grid;
      place-items: center;
      cursor: pointer;
      padding: 0.5rem 0.75rem;
      transition: 0.5s;
      border-radius: 0.375rem;
      color: inherit;
      text-decoration: none;
      border: 0;
      background-color: transparent;
      font-weight: 600;

      &:hover {
        background-color: $primary-8;
      }
      > span {
        font-size: 1rem;
      }

      &.loading {
        > span {
          transition: 10s;
          transform: rotate(1800deg);
        }
      }
    }

    .delete-arrow {
      right: 0;
    }
  }

  @media (min-width: $md) {
    .promotion-type-wrapper,
    .promotion-liveon-wrapper,
    .promotion-entries-wrapper {
      display: flex;
      align-items: center;
    }
    .promotion-actions-wrapper {
      display: none;
    }
  }

  @media (max-width: $md) {
    grid-template-columns: 70% 30%;
    .promotion-type-wrapper,
    .promotion-liveon-wrapper,
    .promotion-entries-wrapper {
      display: none;
    }
    .promotion-actions-wrapper {
      display: flex;
      justify-content: flex-end;
    }
    .buttons-options {
      display: none !important;
    }
  }
}

.no-promotion-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
