.card-with-icon {
  .box-wrap {
    position: relative;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    height: auto;
    border-radius: 5px;
    border: none;
    padding: 30px 24px;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background: $color-ghost;
    }
    .title {
      text-align: center;
      h2 {
        min-height: auto;
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 55px;
        height: 55px;
        margin: 0 auto;
        border: solid 2px #d0dbe9;
        border-radius: 50%;
        margin-bottom: 10px;
        i {
          font-size: 30px;
          color: #8a9bb4;
        }
      }
    }
    p {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }
  }
}
