@import '../../../assets/scss/DesignSystemV2/variables';

.custom {
  font-family: 'beeliked';
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variation-settings: 'FILL' 0;
}

.fill {
  font-variation-settings: 'FILL' 1;
}

.icon {
  &.lg {
    font-size: 1.25rem !important;
  }
  &.md {
    font-size: 1rem !important;
  }
  &.sm {
    font-size: 0.75rem !important;
  }
  &.red-block {
    color: $red-block !important;
  }
  &.green-status-8 {
    color: $green-status-8 !important;
  }
  &.green-status {
    color: $green-status !important;
  }
  &.green-status-hover {
    color: $green-status-hover !important;
  }
  &.gray-space {
    color: $gray-space !important;
  }
  &.gray-40 {
    color: $gray-40 !important;
  }
  &.gray-56 {
    color: $gray-56 !important;
  }
  &.primary {
    color: $primary-pure !important;
  }
  &.gray-space {
    color: $gray-space !important;
  }
  &.primary-hover {
    color: $primary-hover !important;
  }
}
