.content-box {
  min-height: 25rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-direction: column;

  .wrapper-connect {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.wrapper-integration {
  position: relative;
  min-height: 10rem !important;
  > div {
    padding: 0 !important;
  }
}
