@import './../../../assets/scss/DesignSystemV2/variables';

.carousel_card {
    height: 20.625rem;
    width: 48%;
    border: 1px solid $white-smoke;
    background-color: $white;
    border-radius: 1.25rem;
    padding: 1rem;
    position: relative;
    }
    
    .expires_tab{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      margin-top:-0.5rem;
      width: fit-content;
      height: fit-content;
      z-index: 2;
      color:  $gray-92;
      font-size:  0.625rem;
      font-style: normal;
      font-weight: 600;
      line-height:  0.75rem;
      border-radius: 2rem;
      background:  $white;
      box-shadow: 0px 1px 2px 0px #17171705;
     }
    
     .timer_element{
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: fit-content;
      height: fit-content;
      border-radius: 0.5rem;
      border: 1px solid #ffffff29;
      background: linear-gradient(180deg, #ffffff3d 0%, #ffffff00 100%);
      padding: 1rem 0.75rem 0.25rem 0.75rem;
      gap: 0.125rem;
    }
    
    .timer_section{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .time_section{
      display: flex;
      justify-content: center;
      gap: 0.125rem;
    }
    .timer{
      background: #FFFFFF33;
      width: 1.25rem;
      height: 1.5rem;
      padding: 0.1875rem 0.125rem;
      gap: 0rem;
      text-align: center;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.25rem;
      color: $white;
    }
    .day_element{
      font-size: 0.625rem;
      line-height: 0.75rem;
      font-weight: 600;
      color: $white;
     }
    
     .button_plugin {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 5%;
      width: 90%;
      padding: 0.375rem 0rem;
      outline-offset: 0.5px;
      border-radius: 0.5rem;
      border: 1px solid #ffffff29;
      background: linear-gradient(180deg, #ffffff3d 0%, #ffffff00 100%);
      border: 1px solid #ffffff29;
      color:$white;
     }

.color-white{
  color: $white;
}

.game-type-text{
  line-height: 1rem;
  font-weight: normal;
  font-size: 0.75rem;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.hidden{
  visibility: hidden;
}

.display-none{
  display: none;
}

.timer_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 17.25rem;
  margin-top: -23px;
  z-index: 2;
}