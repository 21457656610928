.fade-in {
  animation: fadeIn 0.2s linear 1 !important;
  opacity: 1 !important;
  visibility: visible !important;
}

.fade-out {
  animation: fadeOut 0.2s linear 1 !important;
  visibility: hidden !important;
  opacity: 0 !important;
}

@keyframes fadeIn {
  0% {
    visibility: hidden !important;
    opacity: 0 !important;
  }
  15% {
    visibility: visible !important;
    opacity: 0 !important;
  }
  100% {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

@keyframes fadeOut {
  0% {
    visibility: visible !important;
    opacity: 1 !important;
  }
  85% {
    visibility: visible !important;
    opacity: 0 !important;
  }
  100% {
    visibility: hidden !important;
    opacity: 0 !important;
  }
}
