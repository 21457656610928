@import '../../../../../assets/scss/DesignSystemV2/variables';
.item-app-card {
  > div {
    display: flex;
    border-radius: 0.5rem;
    padding: 1.25rem;
    background-color: #fff;
    transition: 0.2s;
    border: 1px solid $gray-8;
    position: relative;

    &:hover {
      color: #0582ef;
      border: 1px solid;
      border-color: #c3e3fe;
      background: #eef7ff;
      cursor: pointer;

      div {
        mix-blend-mode: darken;
      }
    }

    .name {
      display: flex;
      flex-direction: column;
      align-self: center;
      padding-left: 0.5rem;
    }
  }
}

.search-wrapper {
  button {
    border: 1px solid #cdcdcd !important;
    border-right-width: 0px !important;
  }
  input {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.wrapper-apps {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  min-height: 10rem;
  header {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;

    > div {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      div,
      > a {
        max-width: 20rem;
      }
    }
  }
  footer {
    min-height: 6.375rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    div {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
    }
  }
  > section {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    main {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
}

@media (max-width: $md) {
  .wrapper-apps {
    header {
      > div {
        flex-direction: column;
        div,
        > a {
          max-width: unset;
        }
      }
    }
  }
}
