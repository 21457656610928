@import './../../../assets/scss/DesignSystemV2/variables';

.filter_section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.25rem;
    }
    
    .ready_to_play_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 0.75rem;
    gap: 0.25rem;
    border-radius: 0.5rem 0rem 0rem 0.5rem;
    height: 1.875rem;
    width: 10rem;
    }
    
    .not_played_counter {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-weight: bold;
    border-radius: 0.25rem;
    width: 1.5rem;
    height: 1.375rem;
    }
    
.already_played_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $white-smoke;
    font-weight: bold;
    font-size: 0.75rem;
    background-color: $white;
    color: $gray-92;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
    height: 1.875rem;
    width: 8.875rem;
    }
    
.sort_select {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid $white-smoke;
    font-weight: bold;
    font-size: 0.75rem;
    background-color: white;
    color: $gray-92;
    border-radius: 0.5rem;
    height: 1.875rem;
    width: 8.875rem;
    }
    
.view_mode_table {
    display: flex;
    padding-top: 0.094rem;
    justify-content: center;
    align-items: center;
    border: 1px solid $white-smoke;
    background-color: white;
    border-radius: 0.5rem;
    width: 1.875rem;
    height: 1.875rem;
    }
    
.view_mode_carousel {
    display: flex;
    padding-top: 0.094rem;
    justify-content: center;
    align-items: center;
    border: 2px solid $white-smoke;
    background-color: $white-smoke-2;
    border-radius: 0.5rem;
    width: 1.875rem;
    height: 1.875rem;
    }

.flex{
    display: flex;
    }

.text-align-center{
    text-align: center;
    }

.filters-right-side-wrapper{
    display: flex;
    gap: 0.75rem; 
    }

.icon-carousel{
    font-size: 1.4rem;
    }

.icon-table{
    font-size: 1.275rem;
    }

.arrow-down-icon{
    font-size: 1.275rem;
    }