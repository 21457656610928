@import '../../../assets/scss/DesignSystem/colors';

.spinner-all-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .box {
    font-size: 12px;
    width: min-content;
    height: min-content;
    text-align: center;
    background: $color-light;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 16px;
    margin: 0 auto;
  }

  .spinner-box {
    font-size: 12px;
    width: min-content;
    height: min-content;
    text-align: center;
    background: $color-light;
    border-radius: 5px;
    padding: 16px;
    margin: 0 auto;

    object {
      width: 36px;
      height: 36px;
      margin-right: 5px;
    }
  }
}
