@import '../../../assets/scss/DesignSystemV2/_variables.scss';

.breadcrumb {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;

  > div {
    display: inline-flex;
    gap: 0.5rem;

    > a {
      padding-right: 0.5rem !important;
    }

    > ul {
      list-style: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;
      padding: 0;
      margin: 0;

      li {
        color: $gray-48;
        font-size: 1.25rem;
        font-weight: 800;

        &:last-child {
          color: $gray-space;
        }

        &:not(:last-child)::after {
          content: '/';
          color: $gray-16;
          font-size: 1rem;
          font-weight: 400;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
