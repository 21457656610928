@import '../../../assets/scss/DesignSystemV2/_variables.scss';
@import '../../../assets/scss/DesignSystemV2/animation.scss';

.dropdown-wrapper {
  position: absolute;
  border-radius: 0.5rem;
  z-index: 901;
  background: #fff;
  box-shadow: $shadow-8;
  z-index: 901;

  &.xs {
    width: 12.5rem;
  }
  &.sm {
    width: 16rem;
  }
  &.lg {
    width: 23rem;
  }

  &.fade-in {
    animation: fade-in 0.5s ease;
    visibility: visible;
  }
  &.fade-out {
    animation: fade-out 0.5s ease;
    visibility: hidden;
  }

  > header,
  > footer {
    border: 1px solid $gray-8;
    background: #fff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  > header {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    padding: 1rem;

    > span {
      font-weight: 800;
    }
  }

  .options-wrapper {
    padding: 0;
    margin: 0;
    list-style: none;
    height: 10rem;
    overflow: auto;
    padding: 0 0.75rem;
    width: 100%;

    li {
      padding: 0.5rem 0;
      border-bottom: 1px solid $gray-8;
      color: $gray-space;
      font-weight: 600;
      min-width: 3.5rem;
      text-transform: capitalize;
      min-width: 10rem;
    }
  }

  > footer {
    border-radius: 0 0 1rem 1rem;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
  }
}
