@import "./../../../../../assets/scss/DesignSystemV2/variables";

.button-show-hide {
    width: 2.625rem;
    height: 2.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    cursor: pointer;
    border-radius: 0.375rem;
    border: 1px solid $primary-24;
  }

  .eye-icon{
    font-size: 1.4rem;
    padding: 6px;
  }