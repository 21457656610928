@import '../../../assets/scss/DesignSystemV2/variables';
@import '../../../assets/scss/DesignSystemV2/texts';
.wrapper {
  display: flex;
  display: flex;
  gap: 0.5rem;

  &:not(.disabled-hover) {
    cursor: pointer;
  }

  &.no-gap {
    gap: 0;
  }

  .tags {
    ul {
      margin: 0;
      padding-left: 1rem;
    }
  }

  &.tags,
  &.pills {
    span,
    p {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-line-clamp: 1;
      line-clamp: 1 !important;
      -webkit-box-orient: vertical;
      text-transform: capitalize;
    }
    > div:has(> span:first-child:not(:empty)) {
      &:has(> span:nth-child(2):empty) {
        gap: unset;
      }
    }
  }
}
.popover-header {
  padding: 0.75rem 0.75rem 0.25rem !important;
}
.hide-data {
  div {
    width: 90%;
    height: 0.75rem;
    border-radius: 0.75rem;
    background-color: $gray-16;
  }
}
.cell-text {
  p {
    text-align: center;
    line-break: anywhere;
  }
}

.cell-name {
  display: flex;
  gap: 0.5rem;
  p {
    text-transform: capitalize;
  }
}

.qr-code-cell {
  display: flex;
  align-items: center;
  > div:first-child {
    margin-right: 0.5rem;
  }
}
