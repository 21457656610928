@import '../../../assets/scss/DesignSystemV2/_variables.scss';

.card {
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 19rem;

  &.sm {
    min-height: 15rem;
  }
  &.md {
    min-height: 25.5rem;
  }
  &.lg {
    min-height: 27rem;
  }

  &.promotions {
    grid-area: promotion;
  }
  &.coupons {
    grid-area: coupon;
  }
  &.readymades {
    grid-area: readymade;
  }
  &.invites {
    grid-area: invite;
  }
  &.news {
    grid-area: news;
  }

  &.slide-from-top {
    animation: slide-from-top 1s ease;
  }

  &.slide-from-right {
    animation: slide-from-right 1s ease;
  }

  &.slide-from-bottom {
    animation: slide-from-bottom 1s ease;
  }

  &.border {
    border: 1px solid $gray-8;
  }

  > header {
    border-bottom: 1px solid $gray-8;
    display: flex;

    > h5 {
      display: inline-block;
      padding: 1rem;
      font-size: 1.25rem;
      font-weight: 800;
      color: $gray-space;
      align-items: center;
      display: flex;
      margin: 0;

      &.flex-1 {
        flex: 1;
      }
    }

    .right-button {
      display: flex;
      align-items: center;
      padding-right: 1rem;
      margin-left: auto;
    }
  }
  > main {
    background-color: $blue-cloud-8;
    height: 100%;

    &.rounded {
      border-radius: inherit;
    }
  }
  > footer {
    padding: 0.75rem 1rem;
    border-top: 1px solid $gray-8;
    display: flex;
    justify-content: space-between;
    margin-top: auto;

    &.direction-column {
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  @media (max-width: $sm) {
    > header {
      flex-direction: column;
    }
  }
}

@keyframes slide-from-top {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  25% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-from-right {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  25% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  25% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
