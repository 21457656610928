@import '../../../assets/css/variables';
@import '../../../assets/css/animation';

.card-wrapper {
  background: $primary-8;
  width: 100%;
  min-height: calc(100vh - 4rem);

  display: grid;
  gap: 1.5rem;

  @media (min-width: $xxl) {
    grid-template-areas: 'promotions promotions videos' 'coupons customer-stories news' 'invites invites news';
    grid-template-columns: 1fr 1fr 23rem;
  }
  @media (min-width: $md) and (max-width: $xxl) {
    grid-template-areas: 'promotions promotions' 'coupons customer-stories' 'invites invites' 'videos videos' 'news news';
  }
  @media (max-width: $md) {
    grid-template-areas: 'promotions' 'coupons' 'customer-stories' 'invites' 'videos' 'news';
  }
}
