@import '../../../../../../assets/scss/DesignSystemV2/variables';
.promotions-header {
  display: grid;
  grid-template-columns: minmax(100px, 57%) minmax(70px, 13%) minmax(130px, 19%) minmax(70px, 11%);
  padding: 1rem;
  background-color: $gray-4;
  span {
    font-size: 0.75rem;
    font-weight: 600;
    color: $gray-48;
  }
}

@media (min-width: $md) {
  .promotions-header {
    span:last-child {
      display: none;
    }
  }
}
@media (max-width: $md) {
  .promotions-header {
    grid-template-columns: 70% 30%;
    div:last-child,
    span:last-child {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
    }
    div:not(div:first-child, div:last-child),
    span:not(span:first-child, span:last-child) {
      display: none;
    }
  }
}
