@import '../../../assets/scss/DesignSystemV2/_variables.scss';

.pills {
  font-size: 0.65rem;
  width: fit-content;
  height: fit-content;
  border-radius: 1rem;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  transition: 0.25s;
  line-height: 1;
  position: relative;

  &:after {
    border: 1px solid transparent;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    border-radius: inherit;
    transition: inherit;
  }
  &.sm {
    border-radius: 0.5rem;
    padding: 0.125rem 0.5rem;
    span {
      line-height: 1;
      font-size: 0.65rem;
      font-weight: 400;
      width: fit-content;
    }
  }
  &.lg {
    padding: 0.25rem 0.75rem;
    span {
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 18px;
      width: fit-content;
    }
  }

  &.primary {
    background-color: $primary-8;
    color: $primary-pure;

    &:hover:after:not(.disabled-hover) {
      border-color: $primary-hover;
    }
  }
  &.primary-dark {
    background-color: $primary-pure;
    color: #fff;

    &:hover:after:not(.disabled-hover) {
      background-color: $primary-hover;
      color: #fff;
    }
  }

  &.green {
    color: $green-status;
    background-color: $green-status-8;

    &:hover:after:not(.disabled-hover) {
      border-color: $green-status;
      color: $green-status;
    }
  }

  &.green-dark {
    color: #fff;
    background-color: $green-status;

    &:hover:after:not(.disabled-hover) {
      background-color: $green-status-hover;
      color: #ffffff;
    }
  }

  &.yellow {
    color: $yellow-bee;
    background-color: $yellow-bee-8;

    &:hover:after:not(.disabled-hover) {
      color: $yellow-bee;
      border-color: $yellow-bee;
    }
  }
  &.yellow-dark {
    background-color: $yellow-bee;
    color: #fff;

    &:hover:after:not(.disabled-hover) {
      background-color: $yellow-bee-hover;
      color: #fff;
    }
  }

  &.gray {
    color: $gray-48;
    background-color: $gray-8;

    &:hover:after:not(.disabled-hover) {
      color: $gray-48;
      border-color: $gray-48;
    }
  }
  &.gray-dark {
    background-color: $gray-48;
    color: #fff;

    &:hover:after:not(.disabled-hover) {
      background-color: $gray-56;
      color: #fff;
    }
  }

  &.red {
    color: $red-block;
    background-color: $red-block-8;

    &:hover:after:not(.disabled-hover) {
      color: $red-block;
      border-color: $red-block;
    }
  }
  &.red-dark {
    background-color: $red-block;
    color: #fff;

    &:hover:after:not(.disabled-hover) {
      background-color: $red-block-hover;
      color: #fff;
    }
  }
  &.rainbow {
    background: linear-gradient(#fff, #fff) padding-box, linear-gradient(270deg, #facb38, #79d8ff) border-box;
    border: 2px solid transparent;
    color: #454c5f;
  }
  &.rainbow-dark {
    background: linear-gradient(90deg, #ffc933 0%, #b5d670 48.96%, #6ec9f7 100%);
    border: 0px;
    color: #fff;
  }
}

.custom {
  font-family: 'beeliked';
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variation-settings: 'FILL' 0;
}
