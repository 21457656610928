@import '../../../../assets/scss/DesignSystemV2/variables';
.color-wrapper {
  position: relative;

  > div {
    position: absolute;
    right: 0;
    z-index: 999;
    width: 15rem !important;
    margin-top: 0.5rem;
    border-radius: 0.5rem !important;
  }

  > button {
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    border: 1px solid $white-space;
  }
}
