@import '../../../../assets/scss/DesignSystemV2/_variables';
.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 1rem;
  overflow: hidden;
  width: 100%;
  max-width: 1170px;
  min-height: 840px;

  .left-side {
    background: #fff;
    position: relative;
    display: flex;
    overflow: hidden;

    &::before {
      content: '';
      height: 100%;
      width: 4rem;
      position: absolute;
      background: linear-gradient(90deg, white, transparent);
      z-index: 99;
    }
    &::after {
      content: '';
      height: 100%;
      width: 4rem;
      position: absolute;
      background: linear-gradient(-90deg, white, transparent);
      z-index: 99;
      right: 0;
    }

    > div {
      transition: 1s transform;
      position: absolute;
      width: 100%;
    }
  }
  > div {
    min-height: 20rem;
  }

  .slide-wrapper {
    background: #fff;
    position: relative;
    display: flex;
    min-height: 50rem;
  }
}

.left {
  transform: translateX(-100%);
  transition-timing-function: ease;
}
.center {
  transform: translateX(0%);
  transition-timing-function: ease;
}
.right {
  transform: translateX(100%);
  transition-timing-function: ease;
}

@media (max-width: $xl) {
  .wrapper {
    grid-template-columns: 1fr;

    .left-side {
      min-height: 50rem;
    }
  }
}
@media (max-width: $lg) {
  .wrapper {
    .left-side {
      min-height: 53rem;
    }
  }
}

@media (max-width: $sm) {
  .wrapper {
    .left-side {
      &::after,
      &::before {
        width: 2rem;
      }
    }
  }
}
