.badge-button {
  .left-badge {
    display: flex;
    height: 20px !important;
    text-transform: uppercase;
    margin: 0 !important;
    align-items: center;
    border-radius: 0.25rem 0 0 0.25rem !important;
  }
  .right-badge {
    height: 20px !important;
    text-transform: uppercase;
    border-radius: 0 0.25rem 0.25rem 0 !important;
  }
}
