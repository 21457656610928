.no-content {
  position: relative;
  padding: 2rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  text-align: center;
  background-color: $color-light;

  font-size: 16px;
  color: $color-grey-dark;

  > p {
    font-size: 16px;
    color: $color-grey-dark;
    margin-bottom: 0;
  }

  &::before {
    content: '';
    display: block;
    width: 300px;
    height: 300px;
    margin: 0 auto;
    background: url(../../imgs/img-no-content.svg) center;
    background-size: cover;
  }
}
