@import '../../../../../../assets/scss/DesignSystemV2/_variables';
.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  grid-template-columns: 1fr;
  padding: 4rem;
  height: 100%;
  place-content: center;

  > * {
    width: 100%;
  }

  h3 {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  .wrapper-paragraph {
    display: grid;
    place-content: center;
    place-items: center;
    gap: 0.25rem;
  }
  > form {
    display: grid;
    gap: 1rem;
    margin-bottom: 0.5rem;

    > button {
      margin-top: 0.5rem;
    }

    > section {
      display: flex;
      justify-content: space-between;
    }
  }
  .social-media-login {
    display: flex;
    gap: 0.5rem;
  }
  .sign-text {
    display: flex;
    gap: 0.25rem;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
  }
}

.modal {
  > div:last-child > main {
    padding: 0;
    div {
      padding: 1rem;
      overflow-y: auto;
      max-height: 80vh;
    }
  }
}
@media (max-width: $sm) {
  .form-wrapper {
    padding: 4rem 2rem;
  }
}
