@import '../../../../../../assets/scss/DesignSystemV2/_variables';
@import '../../../../../../assets/scss/DesignSystemV2/Mixins/scrollbar';
.form-wrapper {
  display: grid;
  place-content: center;
  place-items: center;
  gap: 1rem;
  grid-template-columns: 1fr;
  padding: 4rem;
  height: 100%;

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    text-align: center;
  }

  form {
    width: 100%;
    display: grid;
    gap: 1rem;
    margin-top: 1rem;
    border-top: 1px solid $gray-8;
    margin-top: 1rem;
    padding-top: 2rem;
    position: relative;

    .terms {
      max-height: 20rem;
      overflow-y: scroll;
      position: relative;
      padding-bottom: 2rem;
      @include scrollbar();
    }
    .gradient {
      z-index: 1;
      margin-top: -5rem;
      width: 100%;
      height: 4rem;
      background: linear-gradient(0, white, transparent);
      bottom: 0;
      pointer-events: none;
    }

    > p {
      text-align: center;
    }

    .wrapper-buttons {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .scroll-message {
        display: flex;
        width: 100%;
        justify-content: center;
        position: relative;

        > p {
          padding: 1rem;
          max-width: 70%;
          background: $dark-soft;
          color: #fff;
          border-radius: 0.5rem;
          box-shadow: $shadow-8;
          margin-bottom: 0;
          position: relative;
          text-align: center;

          &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            border: 0.4rem solid transparent;
            border-top-color: $dark-soft;
          }
        }
      }
    }
  }
}
