@import '../../../../../../assets/scss/DesignSystemV2/_variables';
.form-wrapper {
  display: grid;
  place-content: center;
  gap: 1rem;
  grid-template-columns: 1fr;
  padding: 4rem;
  height: 100%;

  > img {
    margin-bottom: 1rem;
  }

  .texts-wrapper {
    h6 {
      margin: 1rem 0;
    }
    h1:last-child {
      margin: 1rem 0;
    }
  }

  form {
    width: 100%;
    display: grid;
    gap: 1rem;
    .password-rules-wrapper {
      list-style: none;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        > span {
          font-size: 1rem;
          color: $gray-48;
        }

        > p {
          color: $gray-48;
        }

        &.approved {
          > span {
            color: $green-status;
          }
        }
      }
    }
    .wrapper-buttons {
      display: grid;
      gap: 0.5rem;
      margin-bottom: 1.625rem;
    }
  }
}
