@import '../../../../../assets/scss/DesignSystem/colors';
@import '../../../../../assets/scss/DesignSystemV2/variables';

.wrapper-templates {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  min-height: 10rem;

  > section {
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  }

  .wrapper-template {
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    background: #fff;
    border-radius: 0.5rem;
    border: 1px solid $gray-8;
    padding: 1rem;

    .align-logos-integrations {
      background: linear-gradient(90deg, #64c6f7 52.6%, #2396fb 100%);
      padding: 1rem;
      justify-content: center;
      gap: 1.5rem;
      display: flex;
      border-radius: inherit;
      > div {
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: center;
        display: flex;
        background-color: white;
        border-radius: 5px;
      }
    }

    footer {
      padding: 1.5rem 0;
      align-items: center;
      display: flex;
      .content-hover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(80, 100, 128, 0.95);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: all 0.3s !important;
        opacity: 0;

        .description {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
        &:hover {
          display: flex;
          opacity: 1;
        }
      }
    }
  }
}

@media (max-width: $xl) {
  .wrapper-skeletons {
    div:nth-child(n + 5) {
      display: none;
    }
  }
}
@media (max-width: $md) {
  .wrapper-skeletons {
    div:nth-child(n + 3) {
      display: none;
    }
  }
}
