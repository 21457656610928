@import '../../../../../../assets/scss/DesignSystemV2/variables';

.wrapper {
  > p:first-child {
    margin-bottom: 0.5rem;
  }
  > a {
    margin: 1rem 0;
  }

  .create-new-widget-form {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    div:first-child {
      margin-right: 1rem;
      flex: 1;
    }

    .btn-margin-bottom {
      margin-bottom: 1.3rem;
    }
  }
}

.widget-table {
  width: 100%;
  border-collapse: collapse;

  .widget-name {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  th,
  td {
    font-size: 0.75rem;
    padding: 1rem;
    border: none;
  }

  thead {
    th {
      background-color: $gray-4;
      color: $gray-56;
      text-align: left;
      border-bottom: 1px solid $gray-8;

      &.th-team-users {
        width: 10%;
      }

      &.th-promotion {
        width: 30%;
      }

      &.th-button-actions {
        width: 10%;
      }
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: $gray-4;
    }

    tr:nth-child(odd) {
      background-color: white;
    }

    .align-buttons {
      display: flex;
      justify-content: between;
      gap: 0.5rem;
    }
  }

  .align-right {
    text-align: right;
  }

  .align-center {
    text-align: center;
  }
}

.align-promotion-name {
  display: flex;
  justify-content: flex-end;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
