@import '../../../../../../../assets/scss/DesignSystemV2/_variables.scss';
.promotion-dropdown {
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 0.5rem;
  overflow: hidden;

  > li {
    padding: 0.15rem 1rem;

    &:not(:last-child) {
      border-bottom: 1px solid $gray-8;
    }
  }
}
