@import '../../../assets/scss/DesignSystemV2/variables';

.drawer {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
  width: 45rem;
  background-color: #fff;
  padding: 2rem 1rem 2rem 1.75rem;
  height: 100vh;
  transition: 0.5s transform;
  transform: translateX(100%);
  box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.06);
  max-width: 100%;
  opacity: 0;

  &.open {
    transform: translateX(0%);
    opacity: 1;
  }

  &:before {
    content: '';
    position: absolute;
    background-color: $gray-16;
    height: 100%;
    left: 0;
    top: 0;
    width: 0.75rem;
  }
  > header {
    padding: 1rem;
    &.header-design {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $gray-8;
      border-radius: 1rem 1rem 0 0;
    }

    .wrapper-icon {
      display: grid;
      place-content: center;
      padding: 0.5rem;
      line-height: 1;
      color: $primary-pure;
      background-color: $primary-8;
      border-radius: 50%;

      &.green-status-hover {
        background: $green-status-8;

        span {
          color: $green-status-hover;
        }
      }
    }
    > div {
      gap: 0.5rem;
      align-items: center;
      display: flex;
    }
    > button {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      margin-left: auto;
    }
  }

  > main {
    padding: 1rem;
    height: calc(100% - 10rem);
    overflow-y: auto;
    &.main-design {
      background-color: $blue-cloud-8;
      border-style: solid;
      border-width: 0px 1px;
      border-color: $gray-8;
    }
  }

  > footer {
    display: flex;
    justify-content: space-between;
    border: 1px solid $gray-8;
    padding: 1rem;
    border-radius: 0 0 1rem 1rem;
  }
}
