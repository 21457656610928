@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

/////////// TYPOGRAPHY //////////
body {
  font-family: 'Plus Jakarta Sans', sans-serif;
}
h1 {
  font-size: 26px;
  font-weight: 600;
}

h2 {
  font-size: 20px;
  font-weight: 600;
}

h3 {
  font-size: 18px;
  font-weight: 600;
}

h4 {
  font-size: 16px;
  font-weight: 500;
}

p {
  font-size: 14px;
  font-weight: 400;
}

a {
  font-size: 14px;
  font-weight: 400;
  color: $color-primary;
}

.text-small {
  font-size: 12px;
}
