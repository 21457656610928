@import '../../../assets/scss/DesignSystemV2/_variables.scss';

.tab-wrapper {
  width: 100%;
  border-bottom: 1px solid $gray-16;
  display: flex;

  div {
    a,
    button {
      margin-bottom: -1px;
      border-bottom: 2px solid transparent;
      transition: 0.5s;
      border-radius: 0 !important;
      padding: 0.75rem 1.25rem !important;
    }
    &.disabled {
      margin-left: 1rem;
    }
    &:not(.disabled) {
      a,
      button {
        &:hover,
        &.active {
          border-bottom-color: $primary-pure;
        }
      }
    }
  }
}
