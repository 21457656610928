@import '../../../../assets/scss/DesignSystemV2/variables';
.wrapper-checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  input {
    appearance: none;
    border-radius: 0.25rem;
    border: 1px solid #eaecf0;
    display: grid;
    place-content: center;
    font-size: 1rem;
    overflow: hidden;

    &:checked:after {
      content: '\e5ca';
      color: #fff;
      background: $primary-pure;
      z-index: 0;
    }
  }

  &.md {
    > input[type='checkbox'] {
      height: 1rem;
      width: 1rem;
    }
  }
}
