.testimonial-wrapper {
  position: relative;
  height: 100%;
  display: grid;
  place-items: center;
  place-content: flex-end;
  position: absolute;
  transition: 0.5s;
  padding: 4rem;
  .background {
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .image {
    position: relative;
    max-height: 25rem;
    height: 100%;
    object-fit: cover;
    width: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
  }
  aside {
    padding-top: 2.25rem;
    z-index: 1;
    position: relative;
    color: #fff;
    gap: 0.5rem;
    display: grid;

    > div:last-child {
      display: grid;
      gap: 0.25rem;
      margin-bottom: 4rem;
    }
  }
}
