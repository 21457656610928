@import '../../assets/scss/DesignSystemV2/_variables.scss';

.plan-type-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  min-width: 17.25rem;
  transition: 0.5s;
  background: linear-gradient(90deg, #fff, #fff);
  cursor: pointer;

  > aside {
    width: 100%;
  }
  &.freebee,
  &.standard,
  &.essentials,
  &.premium,
  &.enterprise {
    > aside {
      width: 2.25rem;
      height: 2.25rem;
      display: grid;
      place-items: center;
      position: relative;
      border-radius: 50%;
      overflow: hidden;

      span {
        transition: 0.5s;
        position: absolute;
        &:first-child {
          transform: translateY(0);
          opacity: 1;
        }
        &:last-child {
          transform: translateY(2rem);
          opacity: 0;
        }
      }

      &.hover {
        span {
          &:first-child {
            transform: translateY(-2rem);
            opacity: 0;
          }
          &:last-child {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }
  }

  > main {
    flex: 1;
    position: relative;
    height: 100%;

    .plan-content {
      transition: 0.5s;
      transform: translateY(0rem);

      &.hover {
        transform: translateY(-3rem);
        opacity: 0;
      }
    }
    .plan-hover-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      opacity: 0;
      transition: 0.5s;
      position: absolute;
      transform: translateY(3rem);

      &.hover {
        opacity: 1;
        transform: translateY(0);
      }

      > span {
        color: #fff;
        font-size: 1rem;
        border-radius: 50%;
      }
    }
  }

  &.freebee {
    background: linear-gradient(90deg, #fff, #fff 50%, #6ec9f7 50%, #b5d670 82.6%, #ffc933 100%);
    background-size: 200% 100%;
    transition: 0.25s;
    > aside {
      background-color: $gray-8;
      color: $gray-48;
    }
    &:hover {
      background-position-x: 100%;
      > aside {
        background-color: #fff;
        mix-blend-mode: hard-light;
      }
      > main {
        color: #fff;
      }
    }
  }
  &.essentials {
    transition: 0.25s;
    > aside {
      background-color: $green-status-8;
      color: $green-ocean;
    }
    &:hover {
      background: $green-status-8;
      > aside {
        background-color: #fff;
        color: $green-ocean;
      }
    }
    .plan-hover-content {
      > span {
        background-color: $green-ocean;
      }
    }
  }
  &.standard {
    transition: 0.25s;
    > aside {
      background-color: $primary-8;
      color: $primary-pure;
    }

    &:hover {
      background: $primary-16;
      > aside {
        background-color: #fff;
        color: $primary-pure;
      }
      .plan-hover-content {
        > span {
          background-color: $primary-pure;
        }
      }
    }
  }
  &.premium {
    transition: 0.25s;
    > aside {
      background-color: $yellow-bee-8;
      color: $yellow-bee;
    }

    &:hover {
      background: $yellow-bee-8;
      > aside {
        background-color: #fff;
        color: $yellow-bee-hover;
      }
      .plan-hover-content {
        > span {
          background-color: $yellow-bee-hover;
        }
      }
    }
  }
  &.enterprise {
    transition: 0.25s;

    > aside {
      background-color: $purple-4;
      color: $purple-8;
    }

    &:hover {
      background: $purple-4;
      > aside {
        background-color: $white;
        color: $purple-8;
      }
      .plan-hover-content {
        > span {
          background-color: $purple-8;
        }
      }
    }
  }
  > main {
    h2 {
      font-size: 0.75rem;
      font-weight: 900;
      margin: 0;
      text-transform: capitalize;
    }
    span {
      font-size: 0.75rem;
    }
  }
  .expand-wrapper {
    transition: 0.5s;
  }
}
