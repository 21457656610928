@import '../../../../../../../assets/scss/DesignSystemV2/variables';

.modal-wrapper {
  > main {
    border-radius: 1rem;
    background: $blue-cloud-8;
    > div {
      background-color: $blue-cloud-16;
      border: 1px solid $primary-8;
      border-radius: 1rem;
      overflow: hidden;
      display: flex;
      gap: 1rem;
      > aside {
        max-width: 23rem;
        max-height: 24.75rem;
        overflow-y: auto;
        > section {
          cursor: pointer;
          width: 100%;
          background: #fff;
          padding: 1rem;
          border: 1px solid $primary-8;
          display: flex;
          flex-direction: column;
          transition: 0.5s;
          gap: 1rem;
          &.active {
            background-color: $blue-cloud-16;
            border-right-color: transparent;

            > header {
              > .step-number {
                background-color: $primary-pure;
                color: #fff;
              }
            }
          }
          &:nth-child(2) {
            > header {
              align-items: flex-start;
            }
          }
          > header {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            width: 100%;

            > p {
              flex: 1;
              text-align: left;
            }

            > .step-number {
              border-radius: 50%;
              padding: 0.5rem;
              background: $primary-8;
              color: $primary-pure;
            }
          }
          .code-highlight {
            text-align: left;
            border-radius: 0.5rem;
            border: 1px solid $gray-8;
            background-color: #fff;
            word-break: break-all;
            overflow-y: auto;
            padding: 0.5rem 0.75rem;
            width: 100%;

            &.xl {
              height: 5.5rem;
            }
          }
        }
      }
      > main {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        max-height: 24.75rem;
        > div {
          width: 100%;
          height: 100%;
          > svg {
            background-color: #fff;
            width: 100%;
            border-radius: 0.25rem;
          }
        }
      }
    }
  }
}
