@import '../../assets/scss/DesignSystemV2/_variables.scss';

.reward-plugin-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: all 0.5s ease-out;

  &:hover {
    .giftAnimation,
    .fadeIn,
    .glow,
    .backgroundAnimation {
      animation-play-state: paused;
    }
  }

  &.glow {
    animation: glow 6s ease-out infinite;
  }

  &.hide {
    opacity: 0;
  }

  .gift {
    opacity: 0;
    position: absolute;
    z-index: 9;
  }
  .hexagon {
    transform: scale(0);
    filter: drop-shadow(0px 0px 8px #ffae0b);
  }

  .slideDown {
    animation: slide-down 1s ease-out;
  }
  .giftAnimation {
    opacity: 1;
    animation: gift-fade-in 6s ease-out infinite;
  }
  .fadeIn {
    animation: fade-in 1s ease-out;
    opacity: 1 !important;
  }
  .backgroundAnimation {
    transform: scale(1);
    animation: scale-up 6s ease-out infinite;
  }

  .count-notify {
    position: absolute;
    top: -0.18rem;
    left: 1.93rem;
    background-color: $red-block;
    border-radius: 0.75rem;
    padding: 0.18rem 0.5rem;
    font-size: 0.625rem;
    font-weight: bold;
    min-width: 1.3rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    opacity: 0;
  }

  &:hover {
    filter: brightness(1.2);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes gift-fade-in {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scale-up {
  0% {
    transform: scale(0) rotate(-180deg);
  }
  10% {
    transform: scale(1) rotate(0);
  }
  90% {
    transform: scale(1) rotate(0);
  }
  100% {
    transform: scale(0) rotate(-180deg);
  }
}
@keyframes glow {
  0% {
    filter: brightness(1);
  }
  20% {
    filter: brightness(1);
  }
  30% {
    filter: brightness(1.2);
  }
  40% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(1.2);
  }
  60% {
    filter: brightness(1);
  }
  70% {
    filter: brightness(1.2);
  }
  80% {
    filter: brightness(1);
  }
  100% {
    filter: brightness(1);
  }
}
