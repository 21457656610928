.toast {
  position: relative;
  display: block;
  border-radius: 3px;
  border-left: 5px solid;
  background-color: #ffffff;
  width: 100%;
  max-width: 600px;
  padding: 10px 30px 10px 10px;

  &.primary {
    border-left-color: $color-primary;

    .icon-left {
      color: $color-primary;
    }
  }

  &.success {
    border-left-color: $color-success;

    .icon-left {
      color: $color-success;
    }
  }

  &.warning {
    border-left-color: $color-warning;

    .icon-left {
      color: $color-warning;
    }
  }

  &.error,
  &.danger {
    border-left-color: $color-danger;

    .icon-left {
      color: $color-danger;
    }
  }

  &.animate-enter {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown;
    -webkit-animation-duration: 200ms;
    animation-duration: 200ms;
  }

  @-webkit-keyframes bounceInDown {
    from {
      transform: translate3d(0, -100%, 0);
      -webkit-transform: translate3d(0, -100%, 0);
      visibility: visible;
    }

    to {
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
    }
  }

  @keyframes bounceInDown {
    from {
      transform: translate3d(0, -100%, 0);
      -webkit-transform: translate3d(0, -100%, 0);
      visibility: visible;
    }

    to {
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
    }
  }

  &.animate-leave {
    -webkit-animation-name: bounceOutUp;
    animation-name: bounceOutUp;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }

  @-webkit-keyframes bounceOutUp {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      opacity: 0;
      -webkit-transform: translate3d(0, -2000px, 0);
      transform: translate3d(0, -2000px, 0);
    }
  }

  @keyframes bounceOutUp {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      opacity: 0;
      -webkit-transform: translate3d(0, -2000px, 0);
      transform: translate3d(0, -2000px, 0);
    }
  }
  .btn-close {
    position: absolute;
    font-size: 20px;
    top: 22px;
    right: 20px;
    padding: 0;
  }
}
