@import '../../../../assets/scss/DesignSystem/colors';
@import '../../../../assets/scss/DesignSystemV2/variables';

.wrapper-template {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  background: #fff;
  border-radius: 0.5rem;
  border: 1px solid $gray-8;
  padding: 1rem;

  .align-logos-integrations {
    background: linear-gradient(90deg, #64c6f7 52.6%, #2396fb 100%);
    padding: 1rem;
    justify-content: center;
    gap: 1.5rem;
    display: flex;
    border-radius: inherit;
    > div {
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      display: flex;
      background-color: white;
      border-radius: 5px;
    }
  }

  footer {
    padding: 1.5rem 0;
    align-items: center;
    display: flex;
    .content-hover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(80, 100, 128, 0.95);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      transition: all 0.3s !important;
      opacity: 0;

      .description {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
      &:hover {
        display: flex;
        opacity: 1;
      }
    }
  }
}

.wrapper-my-zap {
  > div {
    position: relative;
  }
  header {
    display: flex;
    justify-content: space-between;
    main {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    aside {
      display: flex;
    }
  }
  > section {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > section {
      gap: 1rem;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    }
  }
  .wrapper-inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    > div {
      display: none;
    }
    div,
    > a {
      max-width: 20rem;
    }
  }
}

@media (max-width: $md) {
  .wrapper-inputs {
    flex-direction: column;
    div,
    > a {
      max-width: unset !important;
    }
  }
}

.no-content {
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
