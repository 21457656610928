@import '../../../../../../../assets/scss/DesignSystemV2/variables';

.invitelinks-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .container-icon-wrapper {
    display: grid;
    gap: 1rem;
    place-items: center;

    > p {
      margin-bottom: 0;
      span {
        color: $primary-pure;
      }
    }
  }
}
