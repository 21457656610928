@import '../../../../../assets/scss/DesignSystemV2/variables';

.wrapper-no-promotions {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  padding: 1rem;

  main {
    display: grid;
    place-content: center;
    gap: 0.75rem;
    place-items: center;

    > div {
      margin-top: 0.25rem;
      display: grid;
      width: fit-content;
      width: fit-content;
      text-align: center;

      h3 {
        font-size: 0.85rem;
        font-weight: 600;
        color: $gray-space;
        margin: 0;
      }
      > span {
        font-size: 0.75rem;
        color: $gray-72;
      }
    }
  }
  aside > img {
    min-height: 16rem;
    width: 100%;
  }

  @media (max-width: $sm) {
    flex-direction: column;
  }
}

.promotion-header {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  padding-right: 1rem;
  height: 100%;
  min-width: 20rem;
  align-items: center;

  button {
    padding: 1rem;
    color: $gray-40;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 0;
    background-color: transparent;
    font-size: 0.75rem;
    font-weight: 600;
    height: 100%;

    span {
      padding: 0.025rem 0.25rem;
      font-size: 0.65rem;
      color: #fff;
      border-radius: 0.5rem;
    }

    &.live {
      span {
        background-color: $green-status;
      }

      &.selected {
        color: $green-status;
        border-bottom: 2px solid $green-status;
      }
    }

    &.draft {
      span {
        background-color: $yellow-bee;
      }

      &.selected {
        font-weight: 600;
        color: $yellow-bee;
        border-bottom: 2px solid $yellow-bee;
      }
    }

    &.ended {
      span {
        background-color: $gray-40;
      }

      &.selected {
        font-weight: 600;
        color: $gray-40;
        border-bottom: 2px solid $gray-40;
      }
    }
  }
}

.promotions-wrapper {
  padding: 1rem;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .promotions-grid {
    padding: 0;
    margin: 0;
    height: 100%;
    > li:nth-child(odd) {
      background-color: #fff;
    }
    > li:nth-child(even) {
      background-color: $gray-4;
    }
  }
}
