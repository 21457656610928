@import './_variables.scss';

.m-1 {
  margin: 0.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-3 {
  margin: 0.75rem;
}
.m-4 {
  margin: 1rem;
}
.m-5 {
  margin: 1.25rem;
}
.m-6 {
  margin: 1.5rem;
}
.m-7 {
  margin: 1.75rem;
}
.m-8 {
  margin: 2rem;
}
.m-9 {
  margin: 2.25rem;
}
.m-10 {
  margin: 2.5rem;
}
.m-11 {
  margin: 2.75rem;
}
.m-12 {
  margin: 3rem;
}

.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-7 {
  margin-top: 1.75rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-9 {
  margin-top: 2.25rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-11 {
  margin-top: 2.75rem;
}
.mt-12 {
  margin-top: 3rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-7 {
  margin-bottom: 1.75rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mb-9 {
  margin-bottom: 2.25rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.mb-11 {
  margin-bottom: 2.75rem;
}
.mb-12 {
  margin-bottom: 3rem;
}

.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-5 {
  margin-left: 1.25rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.ml-7 {
  margin-left: 1.75rem;
}
.ml-8 {
  margin-left: 2rem;
}
.ml-9 {
  margin-left: 2.25rem;
}
.ml-10 {
  margin-left: 2.5rem;
}
.ml-11 {
  margin-left: 2.75rem;
}
.ml-12 {
  margin-left: 3rem;
}

.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-5 {
  margin-right: 1.25rem;
}
.mr-6 {
  margin-right: 1.5rem;
}
.mr-7 {
  margin-right: 1.75rem;
}
.mr-8 {
  margin-right: 2rem;
}
.mr-9 {
  margin-right: 2.25rem;
}
.mr-10 {
  margin-right: 2.5rem;
}
.mr-11 {
  margin-right: 2.75rem;
}
.mr-12 {
  margin-right: 3rem;
}

.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-4 {
  padding: 1rem;
}
.p-5 {
  padding: 1.25rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-7 {
  padding: 1.75rem;
}
.p-8 {
  padding: 2rem;
}
.p-9 {
  padding: 2.25rem;
}
.p-10 {
  padding: 2.5rem;
}
.p-11 {
  padding: 2.75rem;
}
.p-12 {
  padding: 3rem;
}

.pt-1 {
  padding-top: 0.25rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pt-5 {
  padding-top: 1.25rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pt-7 {
  padding-top: 1.75rem;
}
.pt-8 {
  padding-top: 2rem;
}
.pt-9 {
  padding-top: 2.25rem;
}
.pt-10 {
  padding-top: 2.5rem;
}
.pt-11 {
  padding-top: 2.75rem;
}
.pt-12 {
  padding-top: 3rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.pb-6 {
  padding-bottom: 1.5rem;
}
.pb-7 {
  padding-bottom: 1.75rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pb-9 {
  padding-bottom: 2.25rem;
}
.pb-10 {
  padding-bottom: 2.5rem;
}
.pb-11 {
  padding-bottom: 2.75rem;
}
.pb-12 {
  padding-bottom: 3rem;
}

.pl-1 {
  padding-left: 0.25rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pl-5 {
  padding-left: 1.25rem;
}
.pl-6 {
  padding-left: 1.5rem;
}
.pl-7 {
  padding-left: 1.75rem;
}
.pl-8 {
  padding-left: 2rem;
}
.pl-9 {
  padding-left: 2.25rem;
}
.pl-10 {
  padding-left: 2.5rem;
}
.pl-11 {
  padding-left: 2.75rem;
}
.pl-12 {
  padding-left: 3rem;
}

.pr-1 {
  padding-right: 0.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-3 {
  padding-right: 0.75rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pr-5 {
  padding-right: 1.25rem;
}
.pr-6 {
  padding-right: 1.5rem;
}
.pr-7 {
  padding-right: 1.75rem;
}
.pr-8 {
  padding-right: 2rem;
}
.pr-9 {
  padding-right: 2.25rem;
}
.pr-10 {
  padding-right: 2.5rem;
}
.pr-11 {
  padding-right: 2.75rem;
}
.pr-12 {
  padding-right: 3rem;
}

.justify-content-end {
  justify-content: flex-end;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}

.hidden {
  display: none !important;
}
.show {
  display: block;
}
.flex {
  display: flex;
}
@media (min-width: $xs) and (max-width: $sm) {
  .hidden-only-xs {
    display: none !important;
  }
  .show-only-xs {
    display: block !important;
  }
  .flex-only-xs {
    display: flex !important;
  }
  .hidden-down-sm {
    display: none !important;
  }
  .hidden-down-md {
    display: none !important;
  }
  .hidden-down-lg {
    display: none !important;
  }
  .hidden-down-xl {
    display: none !important;
  }
}
@media (min-width: $sm) and (max-width: $md) {
  .hidden-only-sm {
    display: none !important;
  }
  .hidden-down-sm {
    display: none !important;
  }
  .hidden-down-md {
    display: none !important;
  }
  .hidden-down-lg {
    display: none !important;
  }
  .hidden-down-xl {
    display: none !important;
  }
  .hidden-up-sm {
    display: none !important;
  }

  .show-only-sm {
    display: block !important;
  }
  .flex-only-sm {
    display: flex !important;
  }
  .show-up-sm {
    display: block !important;
  }
  .flex-up-sm {
    display: flex !important;
  }
}
@media (min-width: $md) and (max-width: $lg) {
  .hidden-only-md {
    display: none !important;
  }
  .show-only-md {
    display: block !important;
  }
  .flex-only-md {
    display: flex !important;
  }
  .hidden-down-md {
    display: none !important;
  }
  .hidden-down-lg {
    display: none !important;
  }
  .hidden-down-xl {
    display: none !important;
  }
  .hidden-up-sm {
    display: none !important;
  }
  .hidden-up-md {
    display: none !important;
  }

  .show-only-md {
    display: block !important;
  }
  .flex-only-md {
    display: flex !important;
  }
  .show-up-sm {
    display: block !important;
  }
  .flex-up-sm {
    display: flex !important;
  }
  .show-up-md {
    display: block !important;
  }
  .flex-up-md {
    display: flex !important;
  }
}
@media (min-width: $lg) and (max-width: $xl) {
  .hidden-only-lg {
    display: none !important;
  }
  .show-only-lg {
    display: block !important;
  }
  .flex-only-lg {
    display: flex !important;
  }
  .hidden-down-lg {
    display: none !important;
  }
  .hidden-down-xl {
    display: none !important;
  }
  .hidden-up-sm {
    display: none !important;
  }
  .hidden-up-md {
    display: none !important;
  }
  .hidden-up-lg {
    display: none !important;
  }

  .show-only-lg {
    display: block !important;
  }
  .flex-only-lg {
    display: flex !important;
  }
  .show-up-sm {
    display: block !important;
  }
  .flex-up-sm {
    display: flex !important;
  }
  .show-up-md {
    display: block !important;
  }
  .flex-up-md {
    display: flex !important;
  }
  .show-up-lg {
    display: block !important;
  }
  .flex-up-lg {
    display: flex !important;
  }
}
@media (min-width: $xl) and (max-width: $xxl) {
  .hidden-only-xl {
    display: none !important;
  }
  .show-only-xl {
    display: block !important;
  }
  .flex-only-xl {
    display: flex !important;
  }
  .hidden-down-xl {
    display: none !important;
  }
  .hidden-down-xxl {
    display: none !important;
  }
  .hidden-up-sm {
    display: none !important;
  }
  .hidden-up-md {
    display: none !important;
  }
  .hidden-up-lg {
    display: none !important;
  }
  .hidden-up-xl {
    display: none !important;
  }

  .show-only-xl {
    display: block !important;
  }
  .flex-only-xl {
    display: flex !important;
  }
  .show-up-sm {
    display: block !important;
  }
  .flex-up-sm {
    display: flex !important;
  }
  .show-up-md {
    display: block !important;
  }
  .flex-up-md {
    display: flex !important;
  }
  .show-up-lg {
    display: block !important;
  }
  .flex-up-lg {
    display: flex !important;
  }
  .show-up-xl {
    display: block !important;
  }
  .flex-up-xl {
    display: flex !important;
  }
}
@media (min-width: 1400px) {
  .hidden-only-xxl {
    display: none !important;
  }
  .show-only-xxl {
    display: block !important;
  }
  .flex-only-xxl {
    display: flex !important;
  }
  .hidden-up-sm {
    display: none !important;
  }
  .hidden-up-md {
    display: none !important;
  }
  .hidden-up-lg {
    display: none !important;
  }
  .hidden-up-xl {
    display: none !important;
  }
}
