@import '../../../../../../../assets/scss/DesignSystemV2/variables';

.content-new-field {
  margin-bottom: 1rem;
  .select-content {
    display: flex;
    flex-direction: row;

    label {
      font-weight: 600;
    }

    div:first-child {
      flex: 1;
    }
    .wrapper-buttons {
      display: flex;
      padding-top: 1.38rem;

      > button {
        margin-left: 0.5rem;
        height: 2.25rem;
      }
    }

    .wrapper-new-buttons {
      display: flex;
      padding-top: 1.55rem;

      > button {
        margin-left: 0.5rem;
        height: 2.25rem;
      }
    }
  }

  .uid-field {
    margin-top: 0.5rem;
  }
}

.button-content {
  display: flex;
  justify-content: center;
}

.wrapper-single-link {
  p:nth-of-type(2) {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .spinner {
    > div {
      position: relative;
    }
  }

  .header-content {
    display: flex;
    justify-content: space-between;
  }
}
