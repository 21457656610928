.testimonial-wrapper {
  position: relative;
  height: 100%;
  display: grid;
  align-items: center;
  place-content: flex-end;
  position: absolute;
  transition: 0.5s;
  padding: 4rem;
  img {
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  aside {
    padding-top: 2.25rem;
    z-index: 1;
    position: relative;
    color: #fff;

    > p {
      margin-bottom: 1.25rem;
    }

    > div {
      display: grid;
      gap: 0.25rem;
      margin-bottom: 1rem;
    }
  }
}
