.table {
  border-color: $color-grey-light;
  thead {
    background-color: $color-highlights;
    border: none;

    th {
      border-top: none;
      border-bottom: none;
      font-size: 12px;
      font-weight: 500;
      color: $color-grey;
    }
  }

  th.top,
  td.top {
    vertical-align: top;
  }

  th.middle,
  td.middle {
    vertical-align: middle;
  }

  th.bottom,
  td.bottom {
    vertical-align: bottom;
  }

  th.left,
  td.left {
    text-align: left;
  }

  th.center,
  td.center {
    text-align: center;
  }

  th.right,
  td.right {
    text-align: right;
    justify-content: flex-end;
  }
}

.table-mobile {
  tbody {
    display: grid;

    td {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }
    .first-tr {
      background: $color-highlights;
      td {
        padding-right: 8px !important;
      }
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }
    tr {
      border-color: $color-grey-light;
      border-bottom: 1px solid $color-grey-light;

      td:first-child {
        font-weight: 600;
        min-width: 110px !important;
        width: 10%;
      }
      td:last-child {
        min-width: 110px;
        text-align: right;
      }
    }
  }
}
