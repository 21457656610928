@import "./../../../../../../../assets/scss/DesignSystemV2/variables";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.html_page {
position: relative;
display: flex;
flex-direction: column;
align-items: center;
background-color: $black-transparent;
height: 84.5rem;
border-radius: 0rem 0rem 0.5rem 0.5rem;
}

.fullscreen-overlay {
position: absolute;
z-index: 3;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
background-color: #ececec1e;
}

.plugin_container {
position: absolute;
z-index: 1;
font-family: "Inter", sans-serif;
border: 1px solid $white-smoke;
width: 42rem;
top: 25%;
border-radius: 1.5rem;
}



.filters_carousel_wrapper {
display: flex;
flex-direction: column;
justify-content: center;
gap: 0.313rem;
padding: 1rem;
}

.carousel_section {
display: flex;
justify-content: space-between;
align-items: center;
height: 21.5rem;
position: relative;
}

.carousel_arrow_left {
position: absolute;
z-index: 1;
top: 50%;
left: -2%;
display: flex;
justify-content: center;
align-items: center;
border-radius: 100%;
width: 2.25rem;
height: 2.25rem;
color: white;
border: 1px solid #ffffff29;
background: linear-gradient(180deg, #ffffff3d 0%, #ffffff00 100%);
border: 1px solid rgba(255, 255, 255, 0.16);
outline-offset: 0.5px;
}

.carousel_arrow_right {
position: absolute;
top: 50%;
z-index: 1;
right: -2%;
display: flex;
padding-left: 0.125rem;
justify-content: center;
align-items: center;
border-radius: 100%;
width: 2.25rem;
height: 2.25rem;
color: white;
border: 1px solid #ffffff29;
background: linear-gradient(180deg, #ffffff3d 0%, #ffffff00 100%);
border: 1px solid #ffffff29;
outline-offset: 0.5px;
}

.divider-footer{
margin: 0rem 1.25rem;
background-color: $gray-17;
height: 1px;
}