@import '../../../assets/scss/DesignSystem/colors';

.spinner-box {
  font-size: 12px;
  width: min-content;
  height: min-content;
  text-align: center;
  background: $color-light;
  border-radius: 5px;
  padding: 16px;
  margin: 0 auto;

  object {
    width: 36px;
    height: 36px;
    margin-right: 5px;
  }
}
