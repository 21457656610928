@mixin scrollbar($color: rgba(0, 0, 0, 0.2)) {
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  &::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-clip: padding-box;
    background-color: $color;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-button {
    display: none;
    width: 0;
    height: 0;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
