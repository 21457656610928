@import '../../../assets/scss/DesignSystemV2/_variables.scss';

.carousel-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .media {
    height: 14.25rem;
    border-radius: 1rem;

    display: flex;
    overflow: hidden;
    position: relative;

    .playerWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .child {
      position: absolute;
      width: 100%;
      transition: 0.5s;
      opacity: 0;
      overflow: hidden;
      height: inherit;
      border-radius: 1rem;

      &.active {
        opacity: 1;
        z-index: 9;
      }
      .thumbnail {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        z-index: 1;
        justify-content: center;
        transition: opacity 0.25s, 0.5s visibility;

        &.desactive {
          opacity: 0;
          visibility: hidden;
        }

        button {
          width: 3rem;
          height: 3rem;
          border: 0;
          border-radius: 50%;
          position: absolute;
          z-index: 2;

          margin: 0;
          padding: 0;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: #fff;
            font-size: 4.5rem;
            background-color: $yellow-bee;
          }

          &::before {
            content: '';
            position: absolute;
            width: 3.75rem;
            height: 3.75rem;
            background-color: $yellow-bee;
            z-index: -1;
            border-radius: 50%;
          }
        }
      }
      .thumbnail,
      img,
      video {
        width: 100%;
        border-radius: 0.5rem;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  footer {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    align-items: flex-start;
    min-height: 2.55rem;

    .description-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-top: 0.25rem;

      h3 {
        font-size: 0.85rem;
        font-weight: 600;
        color: $gray-space;
        width: 100%;
      }
      > span {
        font-size: 0.75rem;
        font-weight: 400;
        color: $gray-64;
        width: 100%;
      }
    }
    .arrows-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      gap: 0.5rem;

      button {
        border-radius: 1rem;
        border: 0;
        background-color: #e5f0ffb2;
        display: flex;
        align-items: center;
        padding: 0;
      }
    }
  }
}
