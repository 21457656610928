@import '../../../../../../assets/scss/DesignSystemV2/variables';

.wrapper {
  > p {
    margin-bottom: 1.5rem;
  }

  .button-create-center {
    padding: 2.5rem 0;
    display: flex;
    justify-content: center;
  }

  .button-create-left {
    margin-bottom: 1rem;
  }

  .grid-container-form {
    display: grid;
    grid-template-rows: 1fr auto;
    gap: 1rem;
    border: 1px solid $blue-cloud;
    background-color: $blue-cloud-16;
    border-radius: 0.5rem;
    padding: 1.25rem 1rem;
    margin-bottom: 1rem;

    .content-row {
      display: grid;
      grid-template-columns: 2fr 2fr;
      gap: 1rem;
    }

    .content-button-row {
      display: flex;
      justify-content: space-between;
    }
  }

  .staff-created {
    border-radius: 0.5rem;
    border: 1px solid #f3f4f6;
    background: #fff;
    padding: 1.25rem;
    position: relative;
    margin-bottom: 1rem;

    .icon-content {
      display: flex;
      justify-content: center;
    }

    p {
      text-align: center;
      > span {
        font-weight: 600;
      }
    }

    .group-inputs {
      margin-top: 1rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }

    .btn-close {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
  }

  .spinner-container {
    position: relative;
    > div {
      position: relative;
    }
  }

  > footer > div {
    margin-top: 1rem;
    justify-content: center;
  }
}

.stafflinks-table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    font-size: 0.75rem;
    padding: 1rem;
    border: none;
  }

  thead {
    th {
      background-color: $gray-4;
      color: $gray-56;
      text-align: left;
      border-bottom: 1px solid $gray-8;
      width: 20%;
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: $gray-4;
    }

    tr:nth-child(odd) {
      background-color: $white;
    }
  }

  .align-right {
    text-align: right;
  }

  .align-center {
    text-align: center;
  }
}
