$white: #fff;
$white-smoke:#F5F5F5;

$gray-space: #454c5f;
$gray-8: #f3f4f6;
$gray-16: #dcdee5;
$gray-24: #c4c8d4;
$gray-32: #acb2c3;
$gray-40: #949cb2;
$gray-48: #7d86a1;
$gray-56: #67718e;
$gray-64: #565d76;
$gray-72: #444c5f;
$gray-80: #333947;

$primary-pure: #2396fb;
$primary-hover: #0582ef;
$primary-4: #f6fbff;
$primary-8: #eef7ff;
$primary-24: #c3e3fe;

$neutral-highlights: #f2f6fe;
$blue-cloud: #a8dffa;
$blue-cloud-16: #dceefe;
$red-block: #f43454;
$red-block-8: #feeff2;

$yellow-bee: #ffc933;
$yellow-bee-8: #fff8e6;

$green-status: #18dc81;
$green-status-8: #edfdf7;

$shadow-4: 0px 8px 24px rgba(0, 0, 0, 0.04);
$shadow-5: 0px 10px 28px rgba(0, 0, 0, 0.05);
$shadow-6: 0px 12px 32px rgba(0, 0, 0, 0.06);
$shadow-7: 0px 14px 36px rgba(0, 0, 0, 0.07);
$shadow-8: 0px 16px 40px rgba(0, 0, 0, 0.08);

$xs: 0px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$fullHD: 1920px;
